import { UserManager } from "oidc-client";
import authConfig from "configs/authConfig";

class AuthService {
  constructor() {
    const settings = {
      authority: authConfig.AUTHORITY,
      client_id: authConfig.CLIENT_ID,
      redirect_uri: authConfig.REDIRECT_URI,
      silent_redirect_uri: authConfig.SILENT_RENEW_URI,
      post_logout_redirect_uri: authConfig.POST_LOGOUT_REDIRECT_URI,
      response_type: "token id_token",
      scope: authConfig.SCOPE,
      revokeAccessTokenOnSignout: true,
      automaticSilentRenew: true,
      clockSkew: 600,
    };
    this.userManager = new UserManager(settings);
  }

  callback = () => this.userManager.signinRedirectCallback();

  silentRenewCallback = () => this.userManager.signinSilentCallback();

  getUser = () => this.userManager.getUser();

  login = () => this.userManager.signinRedirect();

  logout = () => {
    sessionStorage.removeItem("isLoggedIn");
    this.userManager.signoutRedirect().then(() => this.userManager.removeUser());
  };
}

export default new AuthService();
