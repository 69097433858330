import { LOCATION_CHANGE } from "connected-react-router";
import { ACTION_TYPES } from "./actions";

export const initialState = null;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.ERROR:
      return action.error;
    case LOCATION_CHANGE:
      return null;
    case ACTION_TYPES.ERROR_RESET:
      return null;
    default:
      return state;
  }
};

export default reducer;
