import get from "lodash.get";
import { ACTION_TYPES } from "./actions";

export const initialState = {};

const updateConsent = (state, consentId, newProps) => {
  return {
    ...state,
    consents: {
      ...state.consents,
      [consentId]: {
        ...state.consents[consentId],
        ...newProps,
      },
    },
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_CONSENTS:
      return {
        ...initialState,
      };
    case ACTION_TYPES.FETCH_CONSENTS_SUCCESS: {
      const { consents, primaryContactMechanisms } = action.payload.customer;

      const consentState = Object.keys(consents)
        .map(key => ({
          id: key,
          value: consents[key],
          loading: false,
        }))
        .reduce((result, consent) => {
          const newResult = { ...result };
          newResult[consent.id] = consent;
          return newResult;
        }, {});

      return {
        ...state,
        consents: consentState,
        primaryContactMechanisms,
      };
    }
    case ACTION_TYPES.UPDATE_CONSENT: {
      const { consentId } = action;
      return updateConsent(state, consentId, {
        error: null,
        loading: true,
      });
    }
    case ACTION_TYPES.UPDATE_CONSENT_SUCCESS: {
      const { consentId, value } = action;
      const newState = updateConsent(state, consentId, {
        loading: false,
        value,
      });

      if (consentId === "importantUpdatesByEmail") {
        return updateConsent(newState, "accountCorrespondenceByEmail", {
          loading: false,
          value,
          error: null,
        });
      }

      return newState;
    }
    case ACTION_TYPES.UPDATE_CONSENT_ERROR: {
      const errorMessage = get(action, "error.response.data.description");
      let error = "GENERIC";
      if (errorMessage && errorMessage.indexOf("Duplicate Contact Mechanism found") > -1) {
        error = "DUPLICATE_EMAIL";
      }
      const { consentId } = action;
      return updateConsent(state, consentId, {
        error,
        loading: false,
      });
    }
    case ACTION_TYPES.FETCH_USER_NETWORKS: {
      return {
        ...state,
        userNetworksReloading: true,
      };
    }
    case ACTION_TYPES.FETCH_USER_NETWORKS_SUCCESS: {
      const { networks } = action.payload;
      return {
        ...state,
        userNetworks: networks,
        userNetworksReloading: false,
      };
    }
    case ACTION_TYPES.FETCH_USER_NETWORKS_ERROR: {
      return {
        ...state,
        userNetworksReloading: false,
      };
    }
    case ACTION_TYPES.UPDATE_NETWORKS: {
      return {
        ...state,
        isNetworksSubmitting: true,
      };
    }
    case ACTION_TYPES.UPDATE_NETWORKS_SUCCESS: {
      return {
        ...state,
        isNetworksSubmitting: false,
      };
    }
    case ACTION_TYPES.UPDATE_NETWORKS_ERROR: {
      return {
        ...state,
        isNetworksSubmitting: false,
      };
    }
    default:
      return state;
  }
};

export default reducer;
