import STEPS from "constants/applyForMembershipSteps";
import { getCountryOfResidence, getIsAfterExamSuppressionPeriod } from "state/profile/selectors";
import {
  getHasCompletedEthics,
  getHasCompletedExams,
  getAppliedForMembership,
  getMembershipApplicationComplete,
  getMembershipApplicationOnHold,
} from "state/qualifications/selectors";

export const getHasConvictions = state => state.applyForMembership.hasConvictions;
export const getPublicPractice = state => state.applyForMembership.publicPractice;
export const getPublicPracticeConfirmed = state => state.applyForMembership.publicPracticeConfirmed;
export const getConfirmedPermission = state => state.applyForMembership.confirmedPermission;
export const getIncludeInDirectoryOfMembers = state =>
  state.applyForMembership.includeInDirectoryOfMembers;
export const getShowNetworkQuestions = state => {
  const countryOfResidence = getCountryOfResidence(state);
  return ["United Kingdom", "Ireland"].includes(countryOfResidence);
};

export const getEmploymentNetworks = state => state.applyForMembership.employmentNetworks;
export const getRegionalNetwork = state => state.applyForMembership.regionalNetwork;
export const getIsDeclarationAccepted = state => state.applyForMembership.declarationAccepted;

export const getCurrentStep = state => {
  const alreadyApplied = getAppliedForMembership(state);
  const applicationOnhold = getMembershipApplicationOnHold(state);
  const applicationComplete = getMembershipApplicationComplete(state);

  if (alreadyApplied) {
    return STEPS.ALREADY_APPLIED;
  }
  if (applicationOnhold) {
    return STEPS.APPLICATION_ONHOLD;
  }
  if (applicationComplete) {
    return STEPS.APPLICATION_COMPLETE;
  }
  return state.applyForMembership.step;
};

export const getStepNumber = state => {
  const currentStep = getCurrentStep(state);
  switch (currentStep) {
    case STEPS.CONVICTIONS:
      return 1;
    case STEPS.EMPLOYMENT:
      return 2;
    case STEPS.CONTACT:
      return 3;
    case STEPS.DECLARATION:
      return 4;
    default:
      return null;
  }
};

export const getNextStep = state => {
  const currentStep = getCurrentStep(state);
  switch (currentStep) {
    case STEPS.LANDING:
      return STEPS.CONVICTIONS;
    case STEPS.CONVICTIONS:
      return STEPS.EMPLOYMENT;
    case STEPS.EMPLOYMENT:
      return STEPS.CONTACT;
    case STEPS.CONTACT:
      return STEPS.DECLARATION;
    case STEPS.DECLARATION:
      return STEPS.SUBMITTING;
    default:
      return null;
  }
};

export const getPrevStep = state => {
  const currentStep = getCurrentStep(state);
  switch (currentStep) {
    case STEPS.CONVICTIONS:
      return STEPS.LANDING;
    case STEPS.EMPLOYMENT:
      return STEPS.CONVICTIONS;
    case STEPS.CONTACT:
      return STEPS.EMPLOYMENT;
    case STEPS.DECLARATION:
      return STEPS.CONTACT;
    default:
      return null;
  }
};

export const getIsStepValid = state => {
  const currentStep = getCurrentStep(state);
  const {
    publicPractice,
    publicPracticeConfirmed,
    hasConvictions,
    confirmedPermission,
    employmentCategory,
    businessCategory,
  } = state.applyForMembership;

  switch (currentStep) {
    case STEPS.LANDING:
      return (
        getIsAfterExamSuppressionPeriod(state) ||
        (getHasCompletedEthics(state) && getHasCompletedExams(state))
      );
    case STEPS.EMPLOYMENT: {
      const isUnemployed = !!employmentCategory && employmentCategory.value === "NE";
      const hasEmploymentDetails = !!businessCategory && !!employmentCategory;
      return hasEmploymentDetails || isUnemployed;
    }
    case STEPS.CONVICTIONS:
      if (publicPractice === null || hasConvictions === null) {
        return false;
      }
      if (hasConvictions && !confirmedPermission) {
        return false;
      }
      if (publicPractice && !publicPracticeConfirmed) {
        return false;
      }
      return true;
    case STEPS.CONTACT:
      return getIncludeInDirectoryOfMembers(state) !== null;
    case STEPS.DECLARATION:
      return getIsDeclarationAccepted(state);
    default:
      return true;
  }
};

export const getSubmitRequestBody = state => {
  const {
    employmentCategory = "",
    businessCategory = {},
    professionalQualifications = [],
    regionalNetwork = {},
    employmentNetworks = [],
    includeInDirectoryOfMembers: optInMemberDirectory,
    companyName = "",
    jobTitle = "",
    organisationSize = {},
  } = state.applyForMembership;

  return {
    employmentCategory: employmentCategory.value,
    businessCategory: businessCategory.value || "",
    professionalQualifications: professionalQualifications.map(i => i.value),
    regionalNetwork: regionalNetwork.value || "",
    employmentNetworks: employmentNetworks.map(i => i.value),
    optInMemberDirectory,
    jobTitle,
    companyName,
    organisationSize: organisationSize.value || "",
  };
};

export const getEmploymentCategory = state => state.applyForMembership.employmentCategory;
export const getBusinessCategory = state => state.applyForMembership.businessCategory;
export const getProfessionalQualifications = state =>
  state.applyForMembership.professionalQualifications;

export const getShowAdditionalEmploymentFields = state =>
  state.applyForMembership.employmentCategory &&
  state.applyForMembership.employmentCategory.value !== "NE";

export const getCompanyName = state => state.applyForMembership.companyName;
export const getJobTitle = state => state.applyForMembership.jobTitle;
export const getOrganisationSize = state => state.applyForMembership.organisationSize;
