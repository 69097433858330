import React from "react";
import HandoffPage from "components/HandoffPage/HandoffPage";

const PracticingCertificateRenewalHandoffPage = () => (
  <HandoffPage
    message="Practicing Certification Renewal handoff page"
    outgoingUrl={process.env.PRACTISING_CERTIFICATE_RENEWAL_OUTGOING_URL}
  />
);

export default PracticingCertificateRenewalHandoffPage;
