import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Card, Button, Alert } from "acca-design-system";

import PreloaderPanel from "components/PreloaderPanel/PreloaderPanel";

import "./download-card.scss";

export const DownloadCard = ({ heading, message, icon, isLoading, hasError, fetchDownload }) => {
  const classes = classnames("download-card u-margin-bottom-2", {
    "download-card--is-loading": isLoading,
  });

  return (
    <>
      {isLoading ? (
        <PreloaderPanel className={classes} heading="Preparing for download" />
      ) : (
        <Card
          icon={icon}
          iconSize="large"
          className={classes}
          onClick={fetchDownload}
          title={`Download ${heading}`}
        >
          <h3 className="h700 u-margin-bottom-1">{heading}</h3>
          <p>{message}</p>

          {hasError && !isLoading && (
            <Alert type="warning" className="u-margin-top-1 u-content-container-medium">
              <p>
                Something went wrong with the download{" "}
                <Button text="Please try again" variant="link" onClick={fetchDownload} />
              </p>
            </Alert>
          )}
        </Card>
      )}
    </>
  );
};

DownloadCard.propTypes = {
  icon: PropTypes.func,
  heading: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  hasError: PropTypes.bool,
  fetchDownload: PropTypes.func.isRequired,
};

export default DownloadCard;
