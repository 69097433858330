import React from "react";
import { formatDate } from "utilities/dateUtils";

const DetailListField = ({ label, value, type }) => {
  if (!value) {
    return null;
  }

  return label ? (
    <>
      <dt>{label}</dt>
      <dd>{type === "date" ? formatDate(value) : value}</dd>
    </>
  ) : null;
};

export default DetailListField;
