import React from "react";
import PropTypes from "prop-types";

import { LinkList, LinkListItem, Card, Button } from "acca-design-system";

import IconMap from "components/IconMap/IconMap";

import "./qualifications-card.scss";

const QualificationsCard = ({
  title,
  sectionTitle,
  children,
  doughnut,
  links,
  additionalContent,
}) => {
  return (
    <section className="section">
      <h3 className="section-title">{sectionTitle}</h3>
      <Card className="qualification-card">
        <div className="grid-container full">
          <div className="grid-x">
            <div className="cell qualification-card__body">
              <h3 className="h500 u-font-heading u-margin-bottom-1">{title}</h3>
              <div className="qualification-card__body-inner">
                {doughnut}
                <div className="qualification-card__body-text">{children}</div>
              </div>
            </div>
            <div className="cell qualification-card__footer">
              <LinkList>
                {links &&
                  links.map(({ text, href, icon }, i) => {
                    const linkIcon = IconMap({ name: icon });
                    if (i === 0) {
                      return (
                        <li key={i}>
                          <Button
                            className="u-margin-bottom-1"
                            text={text}
                            to={href}
                            icon={linkIcon}
                            iconPosition="right"
                          />
                        </li>
                      );
                    }
                    return (
                      <LinkListItem
                        key={i}
                        to={href}
                        text={text}
                        icon={linkIcon}
                        iconPosition="right"
                      />
                    );
                  })}
              </LinkList>
            </div>
          </div>
        </div>
        {additionalContent}
      </Card>
    </section>
  );
};

QualificationsCard.propTypes = {
  sectionTitle: PropTypes.string,
  doughnut: PropTypes.node,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      href: PropTypes.string,
      icon: PropTypes.string,
    })
  ),
  additionalContent: PropTypes.node,
};

export default QualificationsCard;
