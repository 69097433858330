import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { VerticalNav } from "acca-design-system";

import {
  getCurrentUrl,
  getCurrentMenu,
  getMainNavigation,
} from "../../state/main-navigation/selectors";

const mapStateToProps = state => ({
  currentMenu: getCurrentMenu(state).menuId,
  currentLevel: getCurrentMenu(state).level,
  currentUrl: getCurrentUrl(state),
  mainNavigation: getMainNavigation(state),
});

const MainNavigation = ({
  currentMenu,
  currentLevel,
  currentUrl,
  mainNavigation,
  setMenuDrawOpen,
}) => (
  <VerticalNav
    currentMenu={currentMenu}
    currentLevel={currentLevel}
    currentUrl={currentUrl}
    onItemClick={(_to, hasSubMenu) => {
      if (!hasSubMenu) {
        setMenuDrawOpen(false);
      }
    }}
    onBackBtnClick={() => {}}
    navigation={mainNavigation}
  />
);

MainNavigation.propTypes = {
  currentMenu: PropTypes.string.isRequired,
  currentLevel: PropTypes.number.isRequired,
  currentUrl: PropTypes.string.isRequired,
  mainNavigation: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  ).isRequired,
  setMenuDrawOpen: PropTypes.func,
};

export default withRouter(connect(mapStateToProps)(MainNavigation));
