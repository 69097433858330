export const ACTION_TYPES = {
  FETCH_CONSENTS: "COMMPREF/FETCH_CONSENTS",
  FETCH_CONSENTS_SUCCESS: "COMMPREF/FETCH_CONSENTS_SUCCESS",
  UPDATE_CONSENT: "COMMPREF/UPDATE_CONSENT",
  UPDATE_CONSENT_SUCCESS: "COMMPREF/UPDATE_CONSENT_SUCCESS",
  UPDATE_CONSENT_ERROR: "COMMPREF/UPDATE_CONSENT_ERROR",

  FETCH_USER_NETWORKS: "COMMPREF/FETCH_USER_NETWORKS",
  FETCH_USER_NETWORKS_ERROR: "COMMPREF/FETCH_USER_NETWORKS_ERROR",
  FETCH_USER_NETWORKS_SUCCESS: "COMMPREF/FETCH_USER_NETWORKS_SUCCESS",

  UPDATE_NETWORKS: "COMMPREF/UPDATE_NETWORKS",
  UPDATE_NETWORKS_SUCCESS: "COMMPREF/UPDATE_NETWORKS_SUCCESS",
  UPDATE_NETWORKS_ERROR: "COMMPREF/UPDATE_NETWORKS_ERROR",
};

export const fetchConsents = () => ({
  type: ACTION_TYPES.FETCH_CONSENTS,
});

export const updateConsent = (consentId, value) => ({
  type: ACTION_TYPES.UPDATE_CONSENT,
  consentId,
  value,
});

export const fetchUserNetworks = () => ({
  type: ACTION_TYPES.FETCH_USER_NETWORKS,
});

export const updateNetworks = payload => ({
  type: ACTION_TYPES.UPDATE_NETWORKS,
  payload,
});
