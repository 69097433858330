import React, { Fragment } from "react";
import PropTypes from "prop-types";

import withForm from "containers/withForm";

const Form = ({ children }) => {
  return <Fragment>{children}</Fragment>;
};

Form.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default withForm(Form);
