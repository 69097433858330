import { put, call, select } from "redux-saga/effects";
import rollbar from "error-logger";
import { getAccaId } from "state/profile/selectors";

export default (api, url, type, params = {}, passThrough = {}) => {
  return function* fetchSaga() {
    const accaId = yield select(getAccaId);
    try {
      const response = yield call(api, url, params);
      return yield put({
        type: `${type}_SUCCESS`,
        payload: response.data,
        ...passThrough,
      });
    } catch (error) {
      const correlationId =
        error.response && error.response.headers && error.response.headers["x-correlation-id"];

      rollbar
        .configure({
          payload: {
            fingerprint: `${type}_ERROR`,
            correlationId: correlationId || "not specified",
            accaId,
            url,
          },
        })
        .error(error);

      return yield put({
        type: `${type}_ERROR`,
        error:
          error.code === "ECONNABORTED"
            ? {
                ...error,
                response: { ...error.response, status: "timeout" },
              }
            : error,
        ...passThrough,
      });
    }
  };
};
