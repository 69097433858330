import parse from "date-fns/parse";
import format from "date-fns/format";
import isWithinInterval from "date-fns/isWithinInterval";
import DATE_FORMATS from "constants/dates";

export const isMorning = date => {
  const lower = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 5);
  const upper = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 11, 59, 59, 999);
  return isWithinInterval(date, { start: lower, end: upper });
};

export const isAfternoon = date => {
  const lower = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 12);
  const upper = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 17, 59, 59, 999);
  return isWithinInterval(date, { start: lower, end: upper });
};

export const isEvening = date => !isMorning(date) && !isAfternoon(date);

export const formatDate = (date, dateFormat = DATE_FORMATS.FORMAT_DAY_MONTH_YEAR) => {
  if (!date) {
    return null;
  }

  return format(parse(date, "yyyy-MM-dd", new Date()), dateFormat);
};
