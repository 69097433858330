import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, FormSection, SelectField } from "components/Form";
import { connect } from "react-redux";
import {
  getUserRegionalNetwork,
  getUserEmploymentNetworks,
  getIsNetworksSubmitting,
  getIsUserNetworksReloading,
} from "state/communicationPreferences/selectors";
import { updateNetworks } from "state/communicationPreferences/actions";
import { LoadingDots } from "acca-design-system";
import { getAllEmploymentNetworks, getAllRegionalNetworks } from "state/lookup/selectors";
import { networksToSelectOptions } from "utilities/Helpers";
import content from "../../CommunicationPreferencesPage.content";

const mapStateToProps = state => ({
  employmentNetworks: getAllEmploymentNetworks(state),
  regionalNetworks: getAllRegionalNetworks(state),
  userEmploymentNetworks: getUserEmploymentNetworks(state),
  userRegionalNetwork: getUserRegionalNetwork(state),
  isSubmitting: getIsNetworksSubmitting(state),
  isNetworksReloading: getIsUserNetworksReloading(state),
});

const mapDispatchToProps = dispatch => ({
  submit: formState => dispatch(updateNetworks(formState)),
});

const MemberNetworksSection = ({
  employmentNetworks,
  regionalNetworks,
  userRegionalNetwork,
  userEmploymentNetworks,
  isSubmitting,
  submit,
  isNetworksReloading,
}) => {
  const { memberNetworks } = content;
  const [isEmploymentReadonly, setIsEmploymentReadonly] = useState(true);
  const [isRegionalReadonly, setIsRegionalReadonly] = useState(true);

  const handleSubmit = formState => {
    const { employmentNetworksField, regionalNetworksField } = formState;
    const request = {
      employment: employmentNetworksField
        ? employmentNetworksField.map(selected => selected.value)
        : null,
      regional: regionalNetworksField ? regionalNetworksField.value : null,
    };
    submit(request);
  };

  useEffect(() => {
    if (isSubmitting === false) {
      setIsEmploymentReadonly(true);
      setIsRegionalReadonly(true);
    }
  }, [isSubmitting]);

  const renderEmploymentNetworksSection = () => {
    const { heading, description } = memberNetworks.sectorNetworks;

    return (
      <FormSection
        isReadOnly={isEmploymentReadonly}
        content={{ heading, message: description }}
        handleOnClick={() => setIsEmploymentReadonly(!isEmploymentReadonly)}
        readOnly={
          <dl>
            <dt>Selected network:</dt>
            <dd>{userEmploymentNetworks.map(x => x.name).join(", ")}</dd>
          </dl>
        }
        formFields={
          <SelectField
            id="employmentNetworksField"
            label="Selected networks"
            options={networksToSelectOptions(employmentNetworks)}
            placeholder="Select..."
            name="employmentNetworksField"
            value={networksToSelectOptions(userEmploymentNetworks)}
            multiple
          />
        }
      />
    );
  };

  const renderRegionalNetworksSection = () => {
    const { heading, description } = memberNetworks.regionalNetworks;

    return (
      <FormSection
        className="u-margin-top-2"
        isReadOnly={isRegionalReadonly}
        content={{ heading, message: description }}
        handleOnClick={() => setIsRegionalReadonly(!isRegionalReadonly)}
        readOnly={
          <dl>
            <dt>Selected network:</dt>
            <dd>{userRegionalNetwork ? userRegionalNetwork.name : null}</dd>
          </dl>
        }
        formFields={
          <SelectField
            id="regionalNetworksField"
            label="Selected network"
            options={networksToSelectOptions(regionalNetworks)}
            placeholder="Select..."
            name="regionalNetworksField"
            value={
              userRegionalNetwork
                ? { label: userRegionalNetwork.name, value: userRegionalNetwork.code }
                : null
            }
          />
        }
      />
    );
  };

  if (isNetworksReloading) {
    return <LoadingDots className="u-margin-top-2 u-margin-bottom-4" />;
  }

  return (
    <section className="section">
      <Form
        shouldShowSaveBar={!isRegionalReadonly || !isEmploymentReadonly}
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      >
        <h3 className="section-title">{memberNetworks.heading}</h3>
        {renderEmploymentNetworksSection()}
        {renderRegionalNetworksSection()}
      </Form>
    </section>
  );
};

MemberNetworksSection.propTypes = {
  regionalNetworks: PropTypes.arrayOf(PropTypes.shape({})),
  employmentNetworks: PropTypes.arrayOf(PropTypes.shape({})),
  userRegionalNetwork: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string,
  }),
  userEmploymentNetworks: PropTypes.arrayOf(PropTypes.shape({})),
  isSubmitting: PropTypes.bool,
  submit: PropTypes.func.isRequired,
  isNetworksReloading: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberNetworksSection);
