import { ACTION_TYPES } from "./actions";

export const initialState = {
  loaded: false,
  hasError: false,
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_QUALIFICATIONS:
      return { ...state, loading: true, hasError: false };
    case ACTION_TYPES.FETCH_QUALIFICATIONS_SUCCESS:
      return {
        qualificationProgress: action.payload.qualificationProgress,
        loaded: true,
        hasError: false,
        loading: false,
      };
    case ACTION_TYPES.FETCH_QUALIFICATIONS_ERROR:
      return {
        ...state,
        hasError: true,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
