import STEPS from "constants/cpdWaiverRequestSteps";
import { ACTION_TYPES } from "./actions";

export const initialState = {
  showForm: false,
  step: STEPS.LANDING,
  cpdDeclarationYear: null,
  form: {
    year: null,
    startDate: null,
    endDate: null,
    reasonCode: null,
    reasonLabel: null,
    adjustmentType: "waiver",
  },
  status: null,
  verifiableUnits: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.TOGGLE_FORM:
      return {
        ...state,
        showForm: action.showForm,
        form: action.showForm ? { ...state.form, ...action.payload } : initialState.form,
        error: null,
        status: null,
        step: STEPS.LANDING,
      };
    case ACTION_TYPES.SUBMIT_WAIVER:
      return {
        ...state,
        form: { ...state.form, ...action.payload },
      };
    case ACTION_TYPES.SUBMIT_WAIVER_ERROR:
      return {
        ...state,
        error: action.error.response.data.description,
        step: STEPS.LANDING,
      };
    case ACTION_TYPES.SET_STEP:
      return {
        ...state,
        step: action.step,
      };
    case ACTION_TYPES.SUBMIT_WAIVER_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        verifiableUnits: action.payload.verifiableUnits,
        step: STEPS.COMPLETE,
      };
    default:
      return state;
  }
};

export default reducer;
