const CPD_DECLARATION_STATUS = {
  OPENED: "opened",
  PREVIOUS_INCOMPLETE: "with incomplete",
  NOT_YET_OPENED: "not yet opened",

  UP_TO_DATE: "upToDate",
  READY_TO_START: "readyToStart",
  DUE: "due",
  OVERDUE: "overdue",

  COMPLETED_SUBMISSION: "COMPLETE",
  INCOMPLETE_SUBMISSION: "INCOMPLETE",
  NOT_MET_REQUIREMENTS_SUBMISSION: "NOT_MET_REQUIREMENTS",

  CODES: {
    NOT_REQUIRED: "NOT_REQUIRED",
    UP_TO_DATE: "UPTODATE",
    OVERDUE: "OVERDUE",
  },
};

export default CPD_DECLARATION_STATUS;
