import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DashboardCard, PageHeader, Button, IconOpenInANewWindow16 } from "acca-design-system";
import Container from "components/Container/Container";

import PageContentLoader from "components/PageContentLoader/PageContentLoader";
import { getProfessionalDevelopmentContent } from "state/content/selectors";
import { fetchProfessionalDevelopmentContent } from "state/content/actions";
import { fetchCpdDeclarationOverview } from "state/profile/actions";
import BrowserTitle from "components/BrowserTitle/BrowserTitle";
import {
  getIsCpdDeclarationOverviewLoaded,
  getCpdDeclarationOverview,
  getIsRetired,
} from "state/profile/selectors";
import AdditionalLinks from "components/AdditionalLinks/AdditionalLinks";
import staticContent from "./ProfessionalDevelopmentPage.content";
import AnnualDeclarationsNotification from "./components/AnnualDeclarationsNotification/AnnualDeclarationsNotification";
import TileGridSection from "../../components/TileGridSection/TileGridSection";
import { getIsAccountDetailsLoaded, getLMSUrl } from "../../state/accountDetails/selectors";
import { fetchAccountDetails } from "../../state/accountDetails/actions";

const mapStateToProps = state => ({
  isCpdDeclarationOverviewLoaded: getIsCpdDeclarationOverviewLoaded(state),
  isAccountDetailsLoaded: getIsAccountDetailsLoaded(state),
  cpdDeclarationOverview: getCpdDeclarationOverview(state),
  content: getProfessionalDevelopmentContent(state),
  isRetired: getIsRetired(state),
  lmsUrl: getLMSUrl(state),
});

const mapDispatchToProps = dispatch => ({
  loadCpdDeclarationOverview: () => dispatch(fetchCpdDeclarationOverview()),
  loadAccountDetails: () => dispatch(fetchAccountDetails()),
  loadContent: () => dispatch(fetchProfessionalDevelopmentContent()),
});

const ProfessionalDevelopmentPage = ({
  isCpdDeclarationOverviewLoaded,
  isAccountDetailsLoaded,
  loadCpdDeclarationOverview,
  loadAccountDetails,
  cpdDeclarationOverview,
  content,
  loadContent,
  isRetired,
  lmsUrl,
}) => {
  useEffect(() => {
    if (!isCpdDeclarationOverviewLoaded) {
      loadCpdDeclarationOverview();
    }

    if (!isAccountDetailsLoaded) {
      loadAccountDetails();
    }

    if (!content) {
      loadContent();
    }
  }, []);

  const pageReady = !!isCpdDeclarationOverviewLoaded && !!isAccountDetailsLoaded && !!content;

  if (!pageReady) {
    return <PageContentLoader />;
  }

  const { heading, quote, cpdGuide, footer, links, cpdDeclarationNotification } = content;

  return (
    <Fragment>
      <BrowserTitle content={content} />
      <PageHeader className="u-margin-bottom-2" pageTitle={heading} quote={quote} />
      {cpdDeclarationOverview.status !== "upToDate" && !isRetired && (
        <AnnualDeclarationsNotification content={cpdDeclarationNotification} />
      )}
      {cpdGuide && <TileGridSection content={cpdGuide} />}

      <Container className="section">
        <div className="cell">
          <DashboardCard
            heading={staticContent.lmsCardText}
            button={
              <Button
                variant="primary"
                text={staticContent.lmsCardCTAText}
                iconPosition="right"
                type="button"
                to={lmsUrl?.href}
                icon={IconOpenInANewWindow16}
              />
            }
          />
        </div>
      </Container>

      {links && <AdditionalLinks heading={footer} links={links} />}
    </Fragment>
  );
};

ProfessionalDevelopmentPage.propTypes = {
  isCpdDeclarationOverviewLoaded: PropTypes.bool,
  isAccountDetailsLoaded: PropTypes.bool,
  loadCpdDeclarationOverview: PropTypes.func.isRequired,
  loadAccountDetails: PropTypes.func.isRequired,
  cpdDeclarationOverview: PropTypes.shape({
    status: PropTypes.string.isRequired,
  }),
  content: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    quote: PropTypes.string,
    footer: PropTypes.string,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired,
      })
    ).isRequired,
    bannerImages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    cpdGuide: TileGridSection.propTypes.isRequired,
    cpdDeclarationNotification: PropTypes.shape({}).isRequired,
  }),
  loadContent: PropTypes.func.isRequired,
  isRetired: PropTypes.bool,
  lmsUrl: PropTypes.shape({
    id: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfessionalDevelopmentPage);
