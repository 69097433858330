import { createSelector } from "reselect";
import get from "lodash.get";

import LABELS from "constants/contactDetailsFormFields";
import { getContactDetailsContent } from "state/content/selectors";
import EXPERIENCES from "constants/portalExperiences";

export const getLabel = (fields, keyName) => {
  const label = fields.find(c => c.key === keyName);
  return label ? label.text : "";
};

export const getValue = value => (value === null ? "" : value);

export const getIsContactDetailsLoaded = state => !!state.contactDetails.isLoaded;
export const getContactDetails = state => state.contactDetails.form;
export const getIsSubmitting = state => state.contactDetails.isSubmitting;

export const getIsCountriesLoaded = state => !!state.contactDetails.countries;

export const getCountries = state => get(state.contactDetails, "countries", []);
export const getIsPhoneValid = state =>
  state.profile.portalExperience === EXPERIENCES.STUDENT
    ? !!state.contactDetails.form.primaryPhoneNumber.number
    : true;
export const getIsEmailValid = state => !!state.contactDetails.form.email.address;

const setAddressCountry = (countries, selectedCountry) => {
  if (!selectedCountry) {
    return {};
  }

  const country = countries.find(({ name }) => name === selectedCountry);

  if (country) {
    return {
      label: country.name,
      value: country.iso2,
      icon: `Flag${country.iso3}`,
      asciiLanguageCode: country.asciiLanguageCode,
      validationLevel: country.validationLevel,
    };
  }

  return {
    label: selectedCountry,
    value: selectedCountry,
    icon: "FlagPlaceholder",
    asciiLanguageCode: null,
    validationLevel: "",
  };
};

export const getMailingAddressCountryValue = createSelector(
  getCountries,
  state => get(state.contactDetails, "form.mailingAddress.country", null),
  setAddressCountry
);

export const getSecondaryAddressCountryValue = createSelector(
  getCountries,
  state => get(state.contactDetails, "form.secondaryAddress.country", null),
  setAddressCountry
);

export const getDefaultCountry = state => {
  const countries = getCountries(state);
  const defaultCountry = countries.find(({ name }) => name === state.contactDetails.defaultCountry);
  return defaultCountry || {};
};

export const getCountryAreaCodes = createSelector(
  getCountries,
  getDefaultCountry,
  (countries, defaultCountry) => {
    const displayAsOptionGroup = defaultCountry && defaultCountry.name;

    const formatOption = phonePrefix => ({
      label: `+${phonePrefix}`,
      value: `+${phonePrefix}`,
      submitValue: phonePrefix,
    });

    const options = countries
      .filter(country => country.phonePrefix)
      .map(({ phonePrefix }) => phonePrefix)
      .filter((phonePrefix, idx, arr) => arr.indexOf(phonePrefix) === idx)
      .sort((a, b) => (parseFloat(a) < parseFloat(b) ? -1 : 1))
      .map(formatOption);

    if (!displayAsOptionGroup) {
      return options;
    }

    return [
      {
        label: "",
        id: "jumplist-default-area-code",
        options: [formatOption(defaultCountry.phonePrefix)],
      },
      {
        label: "",
        id: "area-codes",
        options,
      },
    ];
  }
);

export const getCountryOptions = createSelector(
  getCountries,
  getDefaultCountry,
  (countries, defaultCountry) => {
    const displayAsOptionGroup = defaultCountry && defaultCountry.name;

    const formatOption = country => ({
      label: country.name,
      value: country.iso2,
      icon: `Flag${country.iso3}`,
      asciiLanguageCode: country.asciiLanguageCode,
      validationLevel: country.validationLevel,
    });

    const options = countries.map(country => formatOption(country));

    if (!displayAsOptionGroup) {
      return options;
    }

    return [
      {
        label: "",
        id: "jumplist-default-country",
        options: [formatOption(defaultCountry)],
      },
      {
        label: "",
        id: "country-list",
        options,
      },
    ];
  }
);

export const getEmailFormSection = createSelector(
  getContactDetails,
  getContactDetailsContent,
  (contactDetails, contactDetailsContent) => {
    const fields = get(contactDetailsContent, "pageContent.email.fields", []);
    return {
      id: "email.address",
      value: get(contactDetails, "email.address", ""),
      label: getLabel(fields, LABELS.EMAIL),
    };
  }
);

export const getPhoneFormSection = createSelector(
  getContactDetails,
  getContactDetailsContent,
  getDefaultCountry,
  (contactDetails, contactDetailsContent, defaultCountry) => {
    const fields = get(contactDetailsContent, "pageContent.phone.fields", []);
    const phonePrefix = get(
      contactDetails,
      `primaryPhoneNumber.countryCode`,
      defaultCountry.phonePrefix
    );
    const areaCode = phonePrefix ? `+${phonePrefix}` : "";
    return {
      primaryPhoneNumber: {
        id: "primaryPhoneNumber",
        value: {
          number: get(contactDetails, `primaryPhoneNumber.number`, ""),
          countryCode: {
            value: areaCode,
            label: areaCode,
            submitValue: phonePrefix || "",
          },
        },
        label: getLabel(fields, LABELS.PRIMARY_NUMBER),
      },
    };
  }
);

const buildAddressSection = (contactDetails, fieldLabels, country, keyName) => {
  const result = {
    country: {
      id: `${keyName}.country`,
      value: country,
      label: getLabel(fieldLabels, LABELS.COUNTRY),
    },
    addressLine1: {
      id: `${keyName}.addressLine1`,
      value: get(contactDetails, `${keyName}.addressLine1`, ""),
      label: getLabel(fieldLabels, LABELS.ADDRESS),
    },
    addressLine2: {
      id: `${keyName}.addressLine2`,
      value: get(contactDetails, `${keyName}.addressLine2`, ""),
    },
    addressLine3: {
      id: `${keyName}.addressLine3`,
      value: get(contactDetails, `${keyName}.addressLine3`, ""),
    },
    addressLine4: {
      id: `${keyName}.addressLine4`,
      value: get(contactDetails, `${keyName}.addressLine4`, ""),
    },
    addressee: {
      id: `${keyName}.addressee`,
      value: get(contactDetails, `${keyName}.addressee`, ""),
      label: "Addressee",
    },
    city: {
      id: `${keyName}.city`,
      value: get(contactDetails, `${keyName}.city`, ""),
      label: getLabel(fieldLabels, LABELS.CITY),
    },
    state: {
      id: `${keyName}.state`,
      value: get(contactDetails, `${keyName}.state`, ""),
      label: getLabel(fieldLabels, LABELS.COUNTY),
    },
    province: {
      id: `${keyName}.province`,
      value: get(contactDetails, `${keyName}.province`, ""),
      label: getLabel(fieldLabels, LABELS.PROVINCE),
    },
    postcode: {
      id: `${keyName}.postcode`,
      value: get(contactDetails, `${keyName}.postcode`, ""),
      label: getLabel(fieldLabels, LABELS.ZIP),
    },
  };
  const hasRequiredFields =
    (result.country.value !== "" ||
      (result.country.value === "United Kingdom" && result.postcode.value !== "")) &&
    result.city !== "" &&
    result.addressLine1.value !== "";
  return { ...result, hasRequiredFields };
};

export const getMailingAddressFormSection = createSelector(
  getContactDetails,
  getContactDetailsContent,
  getMailingAddressCountryValue,
  (contactDetails, content, country) => {
    const fieldLabels = get(content, "pageContent.mailingAddress.fields", []);
    const isBusinessAddress = get(contactDetails, "mailingAddress.purpose", []).includes(
      "BUSINESS"
    );
    return {
      addressType: {
        id: "mailingAddress.addressType",
        value: isBusinessAddress ? "BUSINESS" : "HOME",
        displayValue: isBusinessAddress ? "Business address" : "Home address",
        options: [
          {
            label: "Business address",
            value: "BUSINESS",
            id: `mailingAddress.addressType.work`,
          },
          {
            label: "Home address",
            value: "HOME",
            id: "mailingAddress.addressType.home",
          },
        ],
      },
      ...buildAddressSection(contactDetails, fieldLabels, country, "mailingAddress"),
    };
  }
);

export const getSecondaryAddressFormSection = createSelector(
  getContactDetails,
  getContactDetailsContent,
  getSecondaryAddressCountryValue,
  (contactDetails, content, country) => {
    const fieldLabels = get(content, "pageContent.secondaryAddress.fields", []);
    const addressSection = buildAddressSection(
      contactDetails,
      fieldLabels,
      country,
      "secondaryAddress"
    );

    let isBusinessAddress = get(contactDetails, "secondaryAddress.purpose", []).includes(
      "BUSINESS"
    );
    let addressTypeValue = isBusinessAddress ? "Business address" : "Home address";

    if (!addressSection.hasRequiredFields) {
      addressTypeValue = "-";
      isBusinessAddress = !get(contactDetails, "mailingAddress.purpose", []).includes("BUSINESS");
    }

    return {
      addressType: {
        id: "secondaryAddress.addressType",
        value: isBusinessAddress ? "BUSINESS" : "HOME",
        displayValue: addressTypeValue,
        options: [
          {
            label: "Business address",
            value: "BUSINESS",
            id: "secondaryAddress.addressType.work",
          },
          {
            label: "Home address",
            value: "HOME",
            id: "secondaryAddress.addressType.home",
          },
        ],
      },
      ...addressSection,
    };
  }
);

export const getFormError = state => state.contactDetails.error;

export const getAddressLookupFailed = state => state.contactDetails.addressLookupFailed;

export const getEmailFormSectionisReadOnly = state =>
  state.contactDetails.sections.email.isReadOnly;
export const getPhoneFormSectionisReadOnly = state =>
  state.contactDetails.sections.phone.isReadOnly;
export const getMailingAddressFormSectionisReadOnly = state =>
  state.contactDetails.sections.mailingAddress.isReadOnly;
export const getSecondaryFormSectionisReadOnly = state =>
  state.contactDetails.sections.secondaryAddress.isReadOnly;

export const getShouldShowSaveBar = createSelector(
  getEmailFormSectionisReadOnly,
  getPhoneFormSectionisReadOnly,
  getMailingAddressFormSectionisReadOnly,
  getSecondaryFormSectionisReadOnly,
  (emailReadOnly, phoneReadOnly, mailingAddressReadOnly, secondaryAddressReadOnly) =>
    !emailReadOnly || !phoneReadOnly || !mailingAddressReadOnly || !secondaryAddressReadOnly
);
