import { takeLeading, call, select } from "redux-saga/effects";
import ApiService from "services/ApiService";
import apiConfig from "configs/apiConfig";
import fetchSaga from "utilities/fetchSaga";
import { getUserType } from "state/profile/selectors";
import { ACTION_TYPES } from "./actions";

function* handleFetchContent(type, url) {
  const userType = yield select(getUserType);
  const parsedUrl = new URL(url);
  if (userType && !parsedUrl.searchParams.get("type")) {
    parsedUrl.searchParams.set("type", userType);
  }
  yield call(fetchSaga(ApiService.callApi, parsedUrl.toString(), type));
}

const contentMap = {
  [ACTION_TYPES.FETCH_LANDING_PAGE_CONTENT]: apiConfig.endpoints.templates.landingPage,
  [ACTION_TYPES.FETCH_PAGE_FRAMEWORK_CONTENT]: apiConfig.endpoints.templates.pageFramework,
  [ACTION_TYPES.FETCH_PAGE_NOT_FOUND_CONTENT]: apiConfig.endpoints.templates.pageNotFound,
  [ACTION_TYPES.FETCH_CPD_CONTENT]: apiConfig.endpoints.templates.cpd,
  [ACTION_TYPES.FETCH_PROFESSIONAL_DEVELOPMENT_CONTENT]:
    apiConfig.endpoints.templates.professionalDevelopment,
  [ACTION_TYPES.FETCH_MY_DETAILS_CONTENT]: apiConfig.endpoints.templates.myDetails,
  [ACTION_TYPES.FETCH_ACCOUNT_DETAILS_CONTENT]: apiConfig.endpoints.templates.accountDetails,
  [ACTION_TYPES.FETCH_QUALIFICATIONS_CONTENT]: apiConfig.endpoints.templates.qualifications,
  [ACTION_TYPES.FETCH_FEES_CONTENT]: apiConfig.endpoints.templates.fees,
  [ACTION_TYPES.FETCH_DOCS_CONTENT]: apiConfig.endpoints.templates.docs,
  [ACTION_TYPES.FETCH_HELP_INFORMATION_CONTENT]: apiConfig.endpoints.templates.helpInformation,
  [ACTION_TYPES.FETCH_EMPLOYMENT_CONTENT]: apiConfig.endpoints.templates.employment,
  [ACTION_TYPES.FETCH_CONTACT_DETAILS_CONTENT]: apiConfig.endpoints.templates.contactDetails,
  [ACTION_TYPES.FETCH_ONBOARDING_CONTENT]: apiConfig.endpoints.templates.onboarding,
  [ACTION_TYPES.FETCH_SUSPENSIONS_CONTENT]: apiConfig.endpoints.templates.suspensions,
  [ACTION_TYPES.FETCH_LOGIN_DETAILS_CONTENT]: apiConfig.endpoints.templates.loginDetails,
};

export default function* contentSagas() {
  const keys = Object.keys(contentMap);
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    yield takeLeading(key, ({ type }) => handleFetchContent(type, contentMap[key]));
  }
}
