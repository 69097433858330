const baseUrl = process.env.API_BASE_URL;

export default {
  clientId: process.env.API_CLIENT_ID,
  endpoints: {
    templates: {
      pageFramework: `${baseUrl}/contents/templates/page-framework`,
      landingPage: `${baseUrl}/contents/templates/landing-page`,
      pageNotFound: `${baseUrl}/contents/templates/page-not-found?type=system`,
      cpd: `${baseUrl}/contents/templates/annual-declarations`,
      professionalDevelopment: `${baseUrl}/contents/templates/professional-development`,
      contactDetails: `${baseUrl}/contents/templates/contact-details`,
      myDetails: `${baseUrl}/contents/templates/my-details`,
      loginDetails: `${baseUrl}/contents/templates/login-details`,
      accountDetails: `${baseUrl}/contents/templates/account-details`,
      qualifications: `${baseUrl}/contents/templates/my-qualifications`,
      fees: `${baseUrl}/contents/templates/fees-and-transactions`,
      docs: `${baseUrl}/contents/templates/documents-and-downloads`,
      helpInformation: `${baseUrl}/contents/templates/help-information`,
      employment: `${baseUrl}/contents/templates/employment-details`,
      onboarding: `${baseUrl}/contents/templates/onboarding`,
      suspensions: `${baseUrl}/contents/templates/suspension?type=system`,
    },
    user: {
      profile: accaId => `${baseUrl}/customers/${accaId}/profile`,
      cpdsOverview: accaId => `${baseUrl}/customers/${accaId}/cpds/overview`,
      cpdHistory: accaId => `${baseUrl}/customers/${accaId}/cpds`,
      accountBalance: accaId => `${baseUrl}/customers/${accaId}/balance`,
      accountDetails: (accaId, customerType) =>
        `${baseUrl}/customers/${accaId}/details?customerType=${customerType}`,
      submitAccountDetails: (accaId, customerType) =>
        `${baseUrl}/customers/${accaId}/details?customerType=${customerType}`,
      notifications: accaId => `${baseUrl}/customers/${accaId}/notifications`,
      submitCpd: accaId => `${baseUrl}/customers/${accaId}/cpds/submissions`,
      submitCpdWaiverRequest: accaId => `${baseUrl}/customers/${accaId}/cpds/adjustments`,
      personalDetails: accaId => `${baseUrl}/customers/${accaId}/personal-details`,
      communicationPreferences: accaId =>
        `${baseUrl}/customers/${accaId}?expand=consents,primaryContactMechanisms`,
      submitConsentUpdate: accaId => `${baseUrl}/customers/${accaId}/consents`,
      deleteCpdWaiver: (accaId, waiverId) =>
        `${baseUrl}/customers/${accaId}/cpds/adjustments/${waiverId}`,
      exams: accaId => `${baseUrl}/customers/${accaId}/exams`,
      employment: (accaId, customerType) =>
        `${baseUrl}/customers/${accaId}/employer?customerType=${customerType}`,
      submitEmployment: (accaId, customerType) =>
        `${baseUrl}/customers/${accaId}/employer?customerType=${customerType}`,
      docs: (accaId, customerType) =>
        `${baseUrl}/customers/${accaId}/documents?customerType=${customerType}`,
      contactDetails: accaId => `${baseUrl}/customers/${accaId}/contactMechanisms`,
      networks: accaId => `${baseUrl}/customers/${accaId}/networks`,
      submitRegionalNetwork: accaId => `${baseUrl}/customers/${accaId}/networks/regional`,
      submitEmploymentNetworks: accaId => `${baseUrl}/customers/${accaId}/networks/employment`,
      session: accaId => `${baseUrl}/customers/${accaId}/sessions`,
      qualifications: accaId => `${baseUrl}/customers/${accaId}/progress`,
      submitApplyForMembership: accaId => `${baseUrl}/customers/${accaId}/membership/application`,
      encryptedUserId: accaId => `${baseUrl}/customers/${accaId}/sessions/encrypt`,
      generateCertificate: (accaId, certificateNumber) =>
        `${baseUrl}/customers/${accaId}/certificates/${certificateNumber}`,
      submitRetirementApplication: accaId => `${baseUrl}/customers/${accaId}/membership/retirement`,
    },
    lookup: {
      employment: customerType =>
        `${baseUrl}/lookups/employmentDetails?customerType=${customerType}`,
      networks: `${baseUrl}/lookups/networks`,
    },
    validations: {
      addresses: `${baseUrl}/validations/addresses`,
      addressInfo: `${baseUrl}/validations/addresses/info`,
      countries: `${baseUrl}/validations/countries`,
    },
  },
};
