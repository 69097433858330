const VALIDATION_MESSAGES = {
  MESSAGES: {
    email: "Please enter a valid email address",
    countryCode: "Please select an area code",
    telephone: "Please enter a valid contact number",
    isAscii: "Non-ascii characters are not supported. Please enter ascii characters only",
    required: "This field is required",
  },
  ALERT_LABELS: {
    valid: "",
    invalid: "Error",
    submitted: "Success!",
  },
};
export default VALIDATION_MESSAGES;
