import { createHashHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import analyticsMiddleware from "analytics/analyticsMiddleware";
import rollbar from "error-logger";
import createRootReducer from "./reducers";
import rootSaga from "./rootSaga";

import { loginSuccess } from "./login/actions";

export const history = createHashHistory();

const sagaMiddleware = createSagaMiddleware({
  onError: error => {
    // uncaught error happended
    // log to error tracking service...
    // eslint-disable-next-line no-console
    rollbar.error("Unhandled redux error", error);
  },
});

const persistedState = sessionStorage.getItem("isLoggedIn");

const configureStore = preloadedState => {
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancer(
      applyMiddleware(routerMiddleware(history), sagaMiddleware, analyticsMiddleware)
    ),
    persistedState
  );

  if (persistedState) {
    store.dispatch(loginSuccess());
  }

  sagaMiddleware.run(rootSaga);

  return store;
};

export default configureStore();
