import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import Parser from "html-react-parser";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Alert, Card, PageHeader, BodyCopy, IconMembership32 } from "acca-design-system";

import { getAccountDetailsContent } from "state/content/selectors";
import { fetchAccountDetailsContent } from "state/content/actions";
import { handleError } from "state/errors/actions";
import { fetchAccountDetails } from "state/accountDetails/actions";
import {
  getAccaId,
  getIsExamSuppressionPeriod,
  getIsUserSuppressed,
  getIsNotificationsLoaded,
  getNotificationsHasError,
  getIsRetired,
} from "state/profile/selectors";
import { getIsAccountDetailsLoaded, getAccountDetails } from "state/accountDetails/selectors";
import { getIsFellow } from "state/registerOfRetiredMembers/selectors";

import PageContentLoader from "components/PageContentLoader/PageContentLoader";
import BrowserTitle from "components/BrowserTitle/BrowserTitle";
import illustrationSrc from "assets/images/illustrations/account-lock.svg";
import Container from "components/Container/Container";
import AppMessage from "components/AppMessage/AppMessage";
import { getUrlParameter } from "utilities/Helpers";
import AccountDetailsFieldList from "./components/AccountDetailsFieldList/AccountDetailsFieldList";
import LearningRecordDisplay from "./components/LearningRecordDisplay/LearningRecordDisplay";
import RegisterOfRetiredMembersCard from "./components/RegisterOfRetiredMembersCard/RegisterOfRetiredMembersCard";
import AccountDetailsForm from "./components/AccountDetailsForm/AccountDetailsForm";

const mapStateToProps = state => ({
  isAccountDetailsLoaded: getIsAccountDetailsLoaded(state),
  details: getAccountDetails(state),
  content: getAccountDetailsContent(state),
  accaId: getAccaId(state),
  isExamSuppressionPeriod: getIsExamSuppressionPeriod(state),
  isUserSuppressed: getIsUserSuppressed(state),
  isNotificationsLoaded: getIsNotificationsLoaded(state),
  notificationsHasError: getNotificationsHasError(state),
  isFellow: getIsFellow(state),
  isRetired: getIsRetired(state),
});

const mapDispatchToProps = dispatch => ({
  loadAccountDetails: () => dispatch(fetchAccountDetails()),
  loadContent: () => dispatch(fetchAccountDetailsContent()),
  setAppError: error => dispatch(handleError(error)),
});

const AccountDetailsPage = ({
  isAccountDetailsLoaded,
  loadAccountDetails,
  details,
  content,
  loadContent,
  accaId,
  isExamSuppressionPeriod,
  isUserSuppressed,
  isNotificationsLoaded,
  setAppError,
  notificationsHasError,
  isFellow,
  location,
  isRetired,
}) => {
  useEffect(() => {
    if (!content) {
      loadContent();
    }
  }, []);

  useEffect(() => {
    if (
      !isAccountDetailsLoaded &&
      isNotificationsLoaded &&
      (!isUserSuppressed || !isExamSuppressionPeriod)
    ) {
      loadAccountDetails();
    }
  }, [isNotificationsLoaded]);

  useEffect(() => {
    if (notificationsHasError) {
      setAppError({ response: { status: "notificationsError" } });
    }
  }, [notificationsHasError]);

  const pageReady =
    (!!isAccountDetailsLoaded || (isUserSuppressed && isExamSuppressionPeriod)) &&
    !!isNotificationsLoaded &&
    !!content;

  if (!pageReady) {
    return <PageContentLoader />;
  }

  const {
    heading,
    quote,
    header,
    footer,
    fields,
    lrs,
    examSuppressionPeriod,
    registerOfRetiredMembers,
    ineligibleRetirementMessage,
  } = content;

  if (isExamSuppressionPeriod && isUserSuppressed) {
    return (
      <Fragment>
        <BrowserTitle content={content} />
        <AppMessage
          preTitle={examSuppressionPeriod.heading}
          message={examSuppressionPeriod.message}
          illustrationSrc={illustrationSrc}
        />
      </Fragment>
    );
  }

  const retirementRegisterInboundLink = getUrlParameter(location, "retirementRegister");
  const showIneligibleForRetirementMessage =
    (isRetired || isFellow) && !!retirementRegisterInboundLink && !!ineligibleRetirementMessage;
  const showRegisterOfRetiredMembersCard = isFellow || isRetired;
  const registerOfRetiredMembersCardContent = isRetired
    ? registerOfRetiredMembers?.retired
    : registerOfRetiredMembers?.standard;

  return (
    <Fragment>
      <BrowserTitle content={content} />
      <PageHeader className="u-margin-bottom-2" pageTitle={heading} quote={quote} />

      {details && (
        <Container className="section">
          <div className="cell">
            {showIneligibleForRetirementMessage && (
              <Alert type="info" className="u-margin-bottom-1">
                <BodyCopy>
                  <p>{ineligibleRetirementMessage}</p>
                </BodyCopy>
              </Alert>
            )}
            <Card icon={IconMembership32} iconSize="large">
              <h2 className="h700 u-font-bold u-margin-bottom-1">{header.title}</h2>
              <BodyCopy className="u-margin-bottom-2">{Parser(header.text)}</BodyCopy>
              <AccountDetailsFieldList content={fields} data={details} accaId={accaId} />
              <h2 className="h700 u-font-bold u-margin-bottom-1 u-margin-top-2">{footer.header}</h2>
              <BodyCopy>{Parser(footer.text)}</BodyCopy>
            </Card>
          </div>

          {lrs && details.uln && (
            <div className="cell">
              <LearningRecordDisplay content={lrs} data={details.uln} />
            </div>
          )}

          {showRegisterOfRetiredMembersCard && (
            <div className="cell">
              <RegisterOfRetiredMembersCard content={registerOfRetiredMembersCardContent} />
            </div>
          )}

          <AccountDetailsForm />
        </Container>
      )}
    </Fragment>
  );
};

AccountDetailsPage.propTypes = {
  isAccountDetailsLoaded: PropTypes.bool,
  loadAccountDetails: PropTypes.func.isRequired,
  isExamSuppressionPeriod: PropTypes.bool.isRequired,
  isUserSuppressed: PropTypes.bool.isRequired,
  isNotificationsLoaded: PropTypes.bool.isRequired,
  details: PropTypes.shape({
    uln: PropTypes.string,
  }),
  accaId: PropTypes.string.isRequired,
  content: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    quote: PropTypes.string,
    header: PropTypes.shape({
      text: PropTypes.string,
      title: PropTypes.string,
      icon: PropTypes.string,
    }),
    footer: PropTypes.shape({ text: PropTypes.string, header: PropTypes.string }),
    fields: PropTypes.arrayOf(PropTypes.shape({})),
    lrs: PropTypes.shape({}),
    examSuppressionPeriod: PropTypes.shape({
      heading: PropTypes.string,
      message: PropTypes.string,
    }),
    registerOfRetiredMembers: PropTypes.shape({
      standard: PropTypes.shape({}),
      retired: PropTypes.shape({}),
    }),
    ineligibleRetirementMessage: PropTypes.string,
  }),
  loadContent: PropTypes.func.isRequired,
  setAppError: PropTypes.func,
  notificationsHasError: PropTypes.bool,
  isFellow: PropTypes.bool.isRequired,
  location: PropTypes.shape({}),
  isRetired: PropTypes.bool.isRequired,
};

export const AccountDetailsPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountDetailsPage);

export default withRouter(AccountDetailsPageContainer);
