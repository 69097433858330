import CPD_DECLARATION_STEPS from "constants/cpdDeclarationSteps";
import { ACTION_TYPES } from "./actions";

export const initialState = {
  showForm: false,
  step: CPD_DECLARATION_STEPS.LANDING,
  isRequirementsMet: null,
  isEngagementPartner: null,
  route: null,
  isConfirmed: false,
  year: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    case ACTION_TYPES.SHOW_FORM:
      return {
        ...initialState,
        showForm: true,
        year: action.year,
      };
    case ACTION_TYPES.CLOSE_FORM:
      return { ...initialState };
    case ACTION_TYPES.SET_STEP:
      return {
        ...state,
        step: action.step,
      };
    case ACTION_TYPES.SET_REQUIREMENTS_MET:
      return {
        ...state,
        isRequirementsMet: action.isRequirementsMet,
      };
    case ACTION_TYPES.SET_ROUTE:
      return {
        ...state,
        route: action.route,
      };
    case ACTION_TYPES.SET_ENGAGEMENT_PARTNER:
      return {
        ...state,
        isEngagementPartner: action.isEngagementPartner,
      };
    case ACTION_TYPES.SET_CONFIRMED:
      return {
        ...state,
        isConfirmed: action.isConfirmed,
      };
    case ACTION_TYPES.SUBMIT_SUCCESS:
      return {
        ...state,
        step: CPD_DECLARATION_STEPS.COMPLETE,
      };
    case ACTION_TYPES.SUBMIT_ERROR:
      return {
        ...state,
        step: CPD_DECLARATION_STEPS.ERROR,
      };
    default:
      return state;
  }
};

export default reducer;
