import { takeLeading, call, select } from "redux-saga/effects";
import b64toBlob from "b64-to-blob";
import ApiService from "services/ApiService";
import apiConfig from "configs/apiConfig";
import fetchSaga from "utilities/fetchSaga";
import { getAccaId, getUserType } from "state/profile/selectors";
import { ACTION_TYPES } from "./actions";

const triggerDownload = (fileName, body) => {
  const blob = b64toBlob(body, "application/pdf");
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export function* handleFetchDocs({ type }) {
  const accaId = yield select(getAccaId);
  const customerType = yield select(getUserType);
  yield call(
    fetchSaga(ApiService.callApi, apiConfig.endpoints.user.docs(accaId, customerType), type)
  );
}

export function* handleFetchMembershipCertificate({ type, certificateNumber, certificateType }) {
  const accaId = yield select(getAccaId);
  const { payload } = yield call(
    fetchSaga(
      ApiService.callApi,
      apiConfig.endpoints.user.generateCertificate(accaId, certificateNumber),
      type,
      {
        params: {
          certificateType,
        },
      }
    )
  );

  if (payload?.body) {
    yield call(
      triggerDownload,
      `ACCA-Membership-Certificate-${certificateNumber}-${accaId}-${certificateType}.pdf`,
      payload.body
    );
  }
}

export function* handleFetchPractisingCertificate({ type, certificateNumber, certificateType }) {
  const accaId = yield select(getAccaId);

  const { payload } = yield call(
    fetchSaga(
      ApiService.callApi,
      apiConfig.endpoints.user.generateCertificate(accaId, certificateNumber),
      type,
      {
        params: {
          certificateType,
        },
      },
      {
        certificateNumber,
      }
    )
  );

  if (payload?.body) {
    yield call(
      triggerDownload,
      `ACCA-Practising-Certificate-${certificateNumber}-${accaId}-${certificateType}.pdf`,
      payload.body
    );
  }
}

export default function* docDownloadsSagas() {
  yield takeLeading(ACTION_TYPES.FETCH_DOCS, handleFetchDocs);
  yield takeLeading(
    ACTION_TYPES.FETCH_PRACTISING_CERTIFICATE_DOWNLOAD,
    handleFetchPractisingCertificate
  );
  yield takeLeading(
    ACTION_TYPES.FETCH_MEMBERSHIP_CERTIFICATE_DOWNLOAD,
    handleFetchMembershipCertificate
  );
}
