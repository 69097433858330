import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Parser from "html-react-parser";
import {
  Alert,
  BodyCopy,
  Button,
  DashboardCard,
  IconOpenInANewWindow16,
  PageHeader,
} from "acca-design-system";

import { getQualificationsContent } from "state/content/selectors";
import { fetchQualificationsContent } from "state/content/actions";
import { fetchQualifications } from "state/qualifications/actions";

import {
  getIsQualificationsLoaded,
  getEligibleForMembership,
  getHasCompletedPER,
} from "state/qualifications/selectors";
import {
  getPortalExperience,
  getIsAfterExamSuppressionPeriod,
  getIsNotificationsLoaded,
  getNotificationsHasError,
  getIsResidentOfMalta,
  getIsJesResident,
} from "state/profile/selectors";
import EXPERIENCES from "constants/portalExperiences";

import PageContentLoader from "components/PageContentLoader/PageContentLoader";
import BrowserTitle from "components/BrowserTitle/BrowserTitle";
import Container from "components/Container/Container";
import TileGridSection from "components/TileGridSection/TileGridSection";
import PromotionCard from "components/PromotionCard/PromotionCard";
import { handleError } from "state/errors/actions";
import AdditionalLinks from "components/AdditionalLinks/AdditionalLinks";
import staticContent from "./QualificationsPage.content";

import ExamsLinkCard from "./components/ExamsLinkCard/ExamsLinkCard";
import ProgressCards from "./components/ProgressCards/ProgressCards";
import MembershipApplicationProgressCard from "./components/MembershipApplicationProgressCard/MembershipApplicationProgressCard";
import { getIsAccountDetailsLoaded, getLMSUrl } from "../../state/accountDetails/selectors";
import { fetchAccountDetails } from "../../state/accountDetails/actions";

const mapStateToProps = state => ({
  content: getQualificationsContent(state),
  isQualificationsLoaded: getIsQualificationsLoaded(state),
  isNotificationsLoaded: getIsNotificationsLoaded(state),
  portalExperience: getPortalExperience(state),
  isAfterExamSuppressionPeriod: getIsAfterExamSuppressionPeriod(state),
  eligibleForMembership: getEligibleForMembership(state),
  hasCompletedPER: getHasCompletedPER(state),
  notificationsHasError: getNotificationsHasError(state),
  lmsUrl: getLMSUrl(state),
  isAccountDetailsLoaded: getIsAccountDetailsLoaded(state),
  isJesResident: getIsJesResident(state),
  isJesMaltaResident: getIsResidentOfMalta(state),
});

const mapDispatchToProps = dispatch => ({
  loadContent: () => dispatch(fetchQualificationsContent()),
  loadQualifications: () => dispatch(fetchQualifications()),
  setAppError: error => dispatch(handleError(error)),
  loadAccountDetails: () => dispatch(fetchAccountDetails()),
});

export const canLoadQualificationsData = portalExperience => {
  return portalExperience !== EXPERIENCES.MEMBER;
};

const QualificationsPage = ({
  content,
  loadContent,
  loadQualifications,
  isQualificationsLoaded,
  isNotificationsLoaded,
  portalExperience,
  eligibleForMembership,
  isAfterExamSuppressionPeriod,
  hasCompletedPER,
  notificationsHasError,
  setAppError,
  lmsUrl,
  isAccountDetailsLoaded,
  loadAccountDetails,
  isJesResident,
  isJesMaltaResident,
}) => {
  const shouldLoadQualificationsData =
    canLoadQualificationsData(portalExperience) &&
    isNotificationsLoaded &&
    !isAfterExamSuppressionPeriod;
  const showPostExamsPage =
    canLoadQualificationsData(portalExperience) &&
    isNotificationsLoaded &&
    isAfterExamSuppressionPeriod;

  useEffect(() => {
    if (!content) {
      loadContent();
    }
  }, [content]);

  useEffect(() => {
    if (!isQualificationsLoaded && shouldLoadQualificationsData) {
      loadQualifications();
    }

    if (!isAccountDetailsLoaded) {
      loadAccountDetails();
    }
  }, [isQualificationsLoaded, shouldLoadQualificationsData]);

  useEffect(() => {
    if (notificationsHasError) {
      setAppError({ response: { status: "notificationsError" } });
    }
  }, [notificationsHasError]);

  const pageReady =
    !!content && isNotificationsLoaded && !notificationsHasError && !!isAccountDetailsLoaded;

  if (!pageReady) {
    return <PageContentLoader />;
  }

  const {
    heading,
    quote,
    title,
    card,
    footer,
    tileGrid,
    links,
    applyForMembership,
    applyForMembershipPerIncomplete,
    postExams,
    membershipApplication,
  } = content;

  const showMembershipCTA =
    eligibleForMembership && isNotificationsLoaded && !notificationsHasError;
  const membershipCardContent = hasCompletedPER
    ? applyForMembership
    : applyForMembershipPerIncomplete;

  const getPostExamLinks = () => {
    const postExamsContentLinks = postExams.contentLinks.filter(
      link => !link.href?.startsWith("LMS_")
    );

    const postExamsLmsLinks = postExams.contentLinks
      .filter(link => link.href === lmsUrl.id)
      .map(link => {
        const parsedLink = link;
        parsedLink.href = lmsUrl.href;
        return parsedLink;
      });
    return postExamsContentLinks.concat(postExamsLmsLinks);
  };

  const renderPostExamsPage = () => (
    <Container className="section">
      <div className="cell">
        <PromotionCard
          className="post-exam-publication-card"
          content={{
            ...postExams,
            links: getPostExamLinks(),
          }}
        />
      </div>
    </Container>
  );

  const renderPage = () => (
    <>
      <Container className="section u-margin-bottom-1">
        <div className="cell">
          {title && <h3 className="section-title">{title}</h3>}
          {portalExperience === EXPERIENCES.STUDENT && isJesResident && (
            <Alert type="info" className="u-margin-bottom-2">
              <BodyCopy>{Parser(staticContent.jesMessage)}</BodyCopy>
            </Alert>
          )}

          {portalExperience === EXPERIENCES.STUDENT && isJesMaltaResident && (
            <Alert type="info" className="u-margin-bottom-2">
              <BodyCopy>{Parser(staticContent.jesMaltaMessage)}</BodyCopy>
            </Alert>
          )}
          {showMembershipCTA && <PromotionCard content={membershipCardContent} />}
          {membershipApplication && (
            <MembershipApplicationProgressCard content={membershipApplication} />
          )}
          {card && <ExamsLinkCard card={card} />}
          {shouldLoadQualificationsData && <ProgressCards />}
        </div>
      </Container>

      <Container className="section">
        <div className="cell">
          <DashboardCard
            heading={staticContent.lmsCardText}
            button={
              <Button
                variant="primary"
                text={staticContent.lmsCardCTAText}
                iconPosition="right"
                type="button"
                to={lmsUrl?.href}
                icon={IconOpenInANewWindow16}
              />
            }
          />
        </div>
      </Container>
      {tileGrid && tileGrid.tiles && <TileGridSection content={tileGrid} />}

      {links && <AdditionalLinks heading={footer} links={links} />}
    </>
  );

  return (
    <>
      <BrowserTitle content={content} />
      <PageHeader
        className="u-margin-bottom-2"
        pageTitle={heading}
        quote={isAfterExamSuppressionPeriod ? null : quote}
      />

      {showPostExamsPage ? renderPostExamsPage() : renderPage()}
    </>
  );
};

QualificationsPage.propTypes = {
  content: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    quote: PropTypes.string,
    title: PropTypes.string,
    applyForMembership: PropTypes.shape({}),
    applyForMembershipPerIncomplete: PropTypes.shape({}),
    membershipApplication: PropTypes.shape({}),
    card: PropTypes.shape({
      text: PropTypes.string,
      link: PropTypes.shape({
        icon: PropTypes.string,
        text: PropTypes.string,
        href: PropTypes.string,
      }),
    }),
    footer: PropTypes.string,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        link: PropTypes.shape({
          text: PropTypes.string,
          href: PropTypes.string,
        }),
      })
    ),
    tileGrid: {
      heading: PropTypes.string,
      tiles: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          image: PropTypes.string,
          link: PropTypes.shape({
            text: PropTypes.string,
            href: PropTypes.string,
          }),
        })
      ),
    },
    postExams: PropTypes.shape({
      contentLinks: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
  loadQualifications: PropTypes.func.isRequired,
  loadContent: PropTypes.func.isRequired,
  isQualificationsLoaded: PropTypes.bool,
  isNotificationsLoaded: PropTypes.bool,
  portalExperience: PropTypes.string.isRequired,
  isAfterExamSuppressionPeriod: PropTypes.bool,
  eligibleForMembership: PropTypes.bool,
  hasCompletedPER: PropTypes.bool,
  notificationsHasError: PropTypes.bool,
  setAppError: PropTypes.func.isRequired,
  lmsUrl: PropTypes.shape({
    id: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  }),
  isAccountDetailsLoaded: PropTypes.bool,
  loadAccountDetails: PropTypes.func.isRequired,
  isJesResident: PropTypes.bool,
  isJesMaltaResident: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(QualificationsPage);
