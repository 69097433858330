import React from "react";
import PropTypes from "prop-types";
import { Card, LoadingDots } from "acca-design-system";

const PreloaderPanel = ({ className, heading }) => (
  <Card center className={className}>
    <LoadingDots className="u-margin-top-2 u-margin-bottom-1" />
    <h4 className="h600 u-font-heading u-margin-bottom-2">{heading}</h4>
  </Card>
);

PreloaderPanel.propTypes = {
  heading: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default PreloaderPanel;
