export const ACTION_TYPES = {
  RESET: "CPD_DECLARATION/RESET",
  CLOSE_FORM: "CPD_DECLARATION/CLOSE_FORM",
  SHOW_FORM: "CPD_DECLARATION/SHOW_FORM",
  SET_STEP: "CPD_DECLARATION/SET_STEP",
  SET_REQUIREMENTS_MET: "CPD_DECLARATION/SET_REQUIREMENTS_MET",
  SET_ROUTE: "CPD_DECLARATION/SET_ROUTE",
  SET_ENGAGEMENT_PARTNER: "CPD_DECLARATION/SET_ENGAGEMENT_PARTNER",
  SET_CONFIRMED: "CPD_DECLARATION/SET_CONFIRMED",
  SUBMIT: "CPD_DECLARATION/SUBMIT",
  SUBMIT_SUCCESS: "CPD_DECLARATION/SUBMIT_SUCCESS",
  SUBMIT_ERROR: "CPD_DECLARATION/SUBMIT_ERROR",
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const setStep = step => ({
  type: ACTION_TYPES.SET_STEP,
  step,
});

export const setIsRequirementsMet = isRequirementsMet => ({
  type: ACTION_TYPES.SET_REQUIREMENTS_MET,
  isRequirementsMet,
});

export const setRoute = route => ({
  type: ACTION_TYPES.SET_ROUTE,
  route,
});

export const setIsEngagementPartner = isEngagementPartner => ({
  type: ACTION_TYPES.SET_ENGAGEMENT_PARTNER,
  isEngagementPartner,
});

export const showDeclarationForm = year => ({
  type: ACTION_TYPES.SHOW_FORM,
  year,
});

export const closeDeclarationForm = () => ({
  type: ACTION_TYPES.CLOSE_FORM,
});

export const setConfirmed = isConfirmed => ({
  type: ACTION_TYPES.SET_CONFIRMED,
  isConfirmed,
});

export const submit = () => ({
  type: ACTION_TYPES.SUBMIT,
});
