import React from "react";
import PropTypes from "prop-types";
import Parser from "html-react-parser";
import classnames from "classnames";
import IconMap from "components/IconMap/IconMap";

import { Card, BodyCopy, LinkList, LinkListItem, Button } from "acca-design-system";

import "./promotion-card.scss";

const PromotionCard = ({ content, className }) => {
  const { title, message, links, linksListTitle, button, illustrationSrc } = content;
  const buttonIcon = button && IconMap({ name: button.icon });

  const classes = classnames("promotion-card", className);

  return (
    <section className="section">
      <Card center className={classes}>
        {illustrationSrc && <img src={illustrationSrc} alt="" className="u-margin-bottom-2" />}

        <h3 className="h400 u-font-heading u-margin-bottom-2">{Parser(title)}</h3>
        {message && <BodyCopy className="u-margin-bottom-1">{Parser(message)}</BodyCopy>}
        {button && (
          <Button
            className="u-margin-bottom-1"
            text={button.text}
            to={button.href}
            icon={buttonIcon}
          />
        )}
        {linksListTitle && <h3 className="u-margin-top-2 u-margin-bottom-1">{linksListTitle}</h3>}
        {links && (
          <LinkList className="promotion-card__links u-text-left">
            {links.map(({ text, href, icon }, i) => {
              const linkIcon = IconMap({ name: icon });
              return (
                <LinkListItem key={i} to={href} text={text} icon={linkIcon} iconPosition="right" />
              );
            })}
          </LinkList>
        )}
      </Card>
    </section>
  );
};

PromotionCard.propTypes = {
  className: PropTypes.string,
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    message: PropTypes.string,
    button: PropTypes.shape({
      text: PropTypes.string,
      href: PropTypes.string,
      icon: PropTypes.string,
    }),
    illustrationSrc: PropTypes.string,
    linksListTitle: PropTypes.string,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        href: PropTypes.string,
        icon: PropTypes.string,
      })
    ),
  }),
};

export default PromotionCard;
