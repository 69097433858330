export const ACTION_TYPES = {
  FETCH_CONTACT_DETAILS: "CONTACT_DETAILS/FETCH_CONTACT_DETAILS",
  FETCH_CONTACT_DETAILS_SUCCESS: "CONTACT_DETAILS/FETCH_CONTACT_DETAILS_SUCCESS",
  FETCH_CONTACT_DETAILS_ERROR: "CONTACT_DETAILS/FETCH_CONTACT_DETAILS_ERROR",

  SUBMIT_CONTACT_DETAILS: "CONTACT_DETAILS/SUBMIT",
  SUBMIT_CONTACT_DETAILS_SUCCESS: "CONTACT_DETAILS/SUBMIT_SUCCESS",
  SUBMIT_CONTACT_DETAILS_ERROR: "CONTACT_DETAILS/SUBMIT_ERROR",

  FETCH_ADDRESSES: "CONTACT_DETAILS/FETCH_ADDRESSES",
  FETCH_ADDRESSES_SUCCESS: "CONTACT_DETAILS/FETCH_ADDRESSES_SUCCESS",
  FETCH_ADDRESSES_ERROR: "CONTACT_DETAILS/FETCH_ADDRESSES_ERROR",

  FETCH_ADDRESS_INFO: "FETCH_ADDRESS_INFO/SUBMIT",
  FETCH_ADDRESS_INFO_SUCCESS: "FETCH_ADDRESS_INFO/SUBMIT_SUCCESS",
  FETCH_ADDRESS_INFO_ERROR: "FETCH_ADDRESS_INFO/SUBMIT_ERROR",

  FETCH_COUNTRIES: "CONTACT_DETAILS/FETCH_COUNTRIES",
  FETCH_COUNTRIES_SUCCESS: "CONTACT_DETAILS/FETCH_COUNTRIES_SUCCESS",
  FETCH_COUNTRIES_ERROR: "CONTACT_DETAILS/FETCH_COUNTRIES_ERROR",
  SET_DEFAULT_COUNTRY: "CONTACT_DETAILS/SET_DEFAULT_COUNTRY",

  TOGGLE_READ_ONLY_SECTION: "CONTACT_DETAILS/TOGGLE_READ_ONLY_SECTION",

  TOGGLE_ADDRESS_TYPE: "CONTACT_DETAILS/TOGGLE_ADDRESS_TYPE",
  REVERT_ADDRESS_TYPE: "CONTACT_DETAILS/REVERT_ADDRESS_TYPE",
};

export const fetchContactDetails = () => ({
  type: ACTION_TYPES.FETCH_CONTACT_DETAILS,
});

export const submitContactDetails = (form, promise) => ({
  type: ACTION_TYPES.SUBMIT_CONTACT_DETAILS,
  form,
  promise,
});

export const fetchAddresses = (params, callback) => ({
  type: ACTION_TYPES.FETCH_ADDRESSES,
  params,
  callback,
});

export const fetchAddressInfo = (id, callback) => ({
  type: ACTION_TYPES.FETCH_ADDRESS_INFO,
  id,
  callback,
});

export const fetchCountries = () => ({
  type: ACTION_TYPES.FETCH_COUNTRIES,
});

export const toggleReadOnlySection = (sectionName, isReadOnly) => ({
  type: ACTION_TYPES.TOGGLE_READ_ONLY_SECTION,
  sectionName,
  isReadOnly,
});

export const toggleAddressType = (keyName, value) => ({
  type: ACTION_TYPES.TOGGLE_ADDRESS_TYPE,
  keyName,
  value,
});

export const setDefaultCountry = value => ({
  type: ACTION_TYPES.SET_DEFAULT_COUNTRY,
  value,
});

export const revertAddressType = keyName => ({
  type: ACTION_TYPES.REVERT_ADDRESS_TYPE,
  keyName,
});
