import { ACTION_TYPES } from "./actions";

export const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_EMPLOYMENT_LOOKUP:
      return {
        ...state,
        employment: null,
      };
    case ACTION_TYPES.FETCH_EMPLOYMENT_LOOKUP_SUCCESS: {
      return {
        ...state,
        employment: action.payload,
      };
    }
    case ACTION_TYPES.FETCH_MEMBERSHIP_EMPLOYMENT_CATEGORIES:
      return {
        ...state,
        membershipEmploymentCategories: null,
      };
    case ACTION_TYPES.FETCH_MEMBERSHIP_EMPLOYMENT_CATEGORIES_SUCCESS: {
      const { employment } = action.payload;
      return {
        ...state,
        membershipEmploymentCategories: employment.member,
      };
    }
    case ACTION_TYPES.FETCH_MEMBERSHIP_EMPLOYMENT_CATEGORIES_ERROR: {
      return {
        ...state,
      };
    }
    case ACTION_TYPES.FETCH_NETWORKS: {
      return {
        ...state,
        networksLoading: true,
      };
    }
    case ACTION_TYPES.FETCH_NETWORKS_SUCCESS: {
      const { networks } = action.payload;
      return {
        ...state,
        networks,
        networksLoading: false,
      };
    }
    case ACTION_TYPES.FETCH_NETWORKS_ERROR: {
      return {
        ...state,
        networksLoading: false,
      };
    }
    default:
      return state;
  }
};

export default reducer;
