export const ACTION_TYPES = {
  RESET: "REGISTER_OF_RETIRED_MEMBERS/RESET",
  CLOSE_FORM: "REGISTER_OF_RETIRED_MEMBERS/CLOSE_FORM",
  SET_STEP: "REGISTER_OF_RETIRED_MEMBERS/SET_STEP",
  SUBMIT: "REGISTER_OF_RETIRED_MEMBERS/SUBMIT",
  SUBMIT_SUCCESS: "REGISTER_OF_RETIRED_MEMBERS/SUBMIT_SUCCESS",
  SUBMIT_ERROR: "REGISTER_OF_RETIRED_MEMBERS/SUBMIT_ERROR",

  SET_IS_PERMANENT_RETIREMENT: "REGISTER_OF_RETIRED_MEMBERS/SET_IS_PERMANENT_RETIREMENT",
  SET_IS_PC_OR_INSOLVENCY_LICENCE_HOLDER:
    "REGISTER_OF_RETIRED_MEMBERS/SET_IS_PC_OR_INSOLVENCY_LICENCE_HOLDER",
  SET_DECLARATION_ACCEPTED: "REGISTER_OF_RETIRED_MEMBERS/SET_DECLARATION_ACCEPTED",
  SET_RETIREMENT_DATE: "REGISTER_OF_RETIRED_MEMBERS/SET_RETIREMENT_DATE",
  SET_CHINA_DATA_PROTECTION_ACCEPTED: "REGISTER_OF_RETIRED_MEMBERS/SET_CHINA_DATA_PROTECTION_ACCEPTED"
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const setStep = step => ({
  type: ACTION_TYPES.SET_STEP,
  step,
});

export const closeForm = () => ({
  type: ACTION_TYPES.CLOSE_FORM,
});

export const submit = () => ({
  type: ACTION_TYPES.SUBMIT,
});

export const setIsPermanentRetirement = value => ({
  type: ACTION_TYPES.SET_IS_PERMANENT_RETIREMENT,
  value,
});

export const setIsPcOrInsolvencyLicenceHolder = value => ({
  type: ACTION_TYPES.SET_IS_PC_OR_INSOLVENCY_LICENCE_HOLDER,
  value,
});

export const setDeclarationAccepted = value => ({
  type: ACTION_TYPES.SET_DECLARATION_ACCEPTED,
  value,
});

export const setChinaDataProtectionAccepted = value => ({
  type: ACTION_TYPES.SET_CHINA_DATA_PROTECTION_ACCEPTED,
  value,
});

export const setRetirementDate = value => ({
  type: ACTION_TYPES.SET_RETIREMENT_DATE,
  value,
});
