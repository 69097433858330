import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import {
  UserProfile,
  Sidebar,
  SidebarContainer,
  IconProfile16,
  IconHelp16,
  Hamburger,
  NavBar,
} from "acca-design-system";
import { fetchNotifications } from "state/profile/actions";
import {
  getIsUserLoaded,
  getAvatarProfile,
  getIsNotificationsLoaded,
} from "state/profile/selectors";
import { getPageFrameworkContent, getPageFrameworkFailedToLoad } from "state/content/selectors";
import { getControls } from "state/main-navigation/selectors";

import ROUTES from "constants/routes";
import USER_TYPES from "constants/userTypes";
import MainNavigation from "../MainNavigation/MainNavigation";

const mapStateToProps = state => ({
  isUserLoaded: getIsUserLoaded(state),
  profile: getAvatarProfile(state),
  content: getPageFrameworkContent(state),
  controls: getControls(state),
  isNotificationsLoaded: getIsNotificationsLoaded(state),
  failedToLoadPageFramework: getPageFrameworkFailedToLoad(state),
});

const mapDispatchToProps = dispatch => ({
  goToRoute: route => dispatch(push(route)),
  loadNotifications: () => dispatch(fetchNotifications()),
});

export const getAvatarUserTypeDisplayName = userType => {
  if (userType === USER_TYPES.graduate) {
    return "Tech Grad";
  }
  return userType;
};

const SiteSidebar = ({
  isUserLoaded,
  profile,
  goToRoute,
  content,
  controls,
  loadNotifications,
  isNotificationsLoaded,
  failedToLoadPageFramework,
}) => {
  if (failedToLoadPageFramework) {
    return null;
  }

  const [menuDrawOpen, setMenuDrawOpen] = useState(false);
  const showSkeleton = !isUserLoaded || !content;
  const navBar = () => (
    <NavBar
      menuDrawOpen={menuDrawOpen}
      menu={{
        text: content.utilityNav ? content.utilityNav.menu : "Menu",
        icon: Hamburger,
      }}
      links={[
        {
          text: content.utilityNav ? content.utilityNav.account : "Account",
          icon: IconProfile16,
          to: ROUTES.accountLanding,
        },
        {
          text: content.utilityNav ? content.utilityNav.help : "Help",
          icon: IconHelp16,
          to: ROUTES.helpPage,
        },
      ]}
      onLinkClick={to => goToRoute(to)}
      onMenuClick={() => {
        setMenuDrawOpen(!menuDrawOpen);
      }}
    />
  );

  useEffect(() => {
    if (!isNotificationsLoaded) {
      loadNotifications();
    }
  }, []);

  return (
    <SidebarContainer showLoadingSkeleton={showSkeleton}>
      {!showSkeleton && (
        <Sidebar
          userProfile={
            <UserProfile
              userTypeText={getAvatarUserTypeDisplayName(profile.userType)}
              userType={profile.userType}
              name={profile.name}
              initials={profile.initials}
            />
          }
          navigation={<MainNavigation setMenuDrawOpen={setMenuDrawOpen} />}
          navBar={navBar()}
          isActive={menuDrawOpen}
          closeMenu={() => {
            setMenuDrawOpen(false);
          }}
          controls={controls}
        />
      )}
    </SidebarContainer>
  );
};

SiteSidebar.propTypes = {
  isUserLoaded: PropTypes.bool.isRequired,
  isNotificationsLoaded: PropTypes.bool.isRequired,
  loadNotifications: PropTypes.func,
  profile: PropTypes.shape({
    userType: PropTypes.string,
    name: PropTypes.string,
    initials: PropTypes.string,
  }),
  goToRoute: PropTypes.func.isRequired,
  content: PropTypes.shape({
    utilityNav: PropTypes.shape({
      menu: PropTypes.string,
      account: PropTypes.string,
      help: PropTypes.string,
    }),
  }),
  controls: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      to: PropTypes.string,
      onClick: PropTypes.func,
    })
  ).isRequired,
  failedToLoadPageFramework: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteSidebar);
