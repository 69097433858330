import React, { Component, createRef } from "react";
import { Prompt } from "react-router-dom";
import classnames from "classnames";
import Formsy from "formsy-react";
import { toast } from "react-toastify";

import SaveBar from "components/Form/SaveBar/SaveBar";

export default WrappedComponent => {
  class WithForm extends Component {
    constructor(props) {
      super(props);
      this.enableFocus = this.enableFocus.bind(this);
      this.disableFocus = this.disableFocus.bind(this);
      this.enableButton = this.enableButton.bind(this);
      this.disableButton = this.disableButton.bind(this);
      this.handleFormChange = this.handleFormChange.bind(this);

      this.formRef = createRef();
      this.state = { isDirty: false, hasFocus: false, canSubmit: false, showErrorPrompt: false };
    }

    componentDidUpdate(prevProps, prevState) {
      const { toggleIsFieldsChanged } = this.props;

      if (
        prevState.isDirty !== toggleIsFieldsChanged &&
        prevProps.toggleIsFieldsChanged !== toggleIsFieldsChanged
      ) {
        this.setState({ isDirty: toggleIsFieldsChanged, canSubmit: true });
      }
    }

    enableButton() {
      this.setState({
        canSubmit: true,
      });
    }

    disableButton() {
      this.setState({
        canSubmit: false,
      });
    }

    enableFocus() {
      this.setState({ hasFocus: true });
    }

    disableFocus() {
      this.setState({ hasFocus: false });
    }

    handleFormChange(values, isDirty) {
      const { toggleIsFieldsChanged } = this.props;

      if (!toggleIsFieldsChanged) {
        this.setState({ isDirty, showErrorPrompt: false });
      } else {
        this.setState({ isDirty: toggleIsFieldsChanged, showErrorPrompt: false });
      }
    }

    render() {
      const { isDirty, hasFocus, canSubmit, showErrorPrompt } = this.state;
      const {
        shouldShowSaveBar,
        children,
        onSubmit,
        isSubmitting,
        classNames,
        errorMessage,
        onFormComplete,
      } = this.props;
      const formClasses = classnames(
        "form",
        {
          "form--has-sticky-save": isDirty,
          "form--has-focus": hasFocus,
        },
        classNames
      );
      const savedChangesText = isDirty ? "Unsaved changes" : "No changes made";

      return (
        <Formsy
          noValidate
          classnames={formClasses}
          ref={this.formRef}
          onValidSubmit={model => {
            new Promise((resolve, reject) => {
              onSubmit(model, { resolve, reject });
            })
              .then(() => {
                this.setState({ isDirty: false });
                toast.success("Changes saved successfully");

                if (onFormComplete) onFormComplete();
              })
              .catch(() => {
                this.setState({ showErrorPrompt: true, isDirty: false });
              });
          }}
          onChange={this.handleFormChange}
          onValid={() => {
            if (isDirty) {
              this.enableButton();
            }
          }}
          onInvalid={this.disableButton}
          onFocus={this.enableFocus}
          onBlur={this.disableFocus}
        >
          <Prompt
            when={isDirty}
            message={() => `You have unsaved changes. If you leave now they will not be saved.`}
          />
          <WrappedComponent {...this.props}>{children}</WrappedComponent>
          {shouldShowSaveBar && (
            <SaveBar
              hasFocus={hasFocus}
              disabled={!canSubmit}
              isSubmitting={isSubmitting}
              errorMessage={errorMessage}
              showErrorPrompt={showErrorPrompt}
            >
              {savedChangesText}
            </SaveBar>
          )}
        </Formsy>
      );
    }
  }

  return WithForm;
};
