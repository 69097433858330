export const ACTION_TYPES = {
  SUBMIT_WAIVER: "CPD_WAVIER_REQUEST/SUBMIT",
  SUBMIT_WAIVER_SUCCESS: "CPD_WAVIER_REQUEST/SUBMIT_SUCCESS",
  SUBMIT_WAIVER_ERROR: "CPD_WAVIER_REQUEST/SUBMIT_ERROR",

  TOGGLE_FORM: "CPD_WAVIER_REQUEST/TOGGLE_FORM",
  SET_STEP: "CPD_WAVIER_REQUEST/SET_STEP",
};

export const submitWaiver = payload => ({
  type: ACTION_TYPES.SUBMIT_WAIVER,
  payload,
});

export const toggleWaiverForm = (showForm, payload = {}) => ({
  type: ACTION_TYPES.TOGGLE_FORM,
  showForm,
  payload,
});

export const setStep = step => ({
  type: ACTION_TYPES.SET_STEP,
  step,
});
