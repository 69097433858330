import ROUTES from "constants/routes";

const member = {
  accountCorrespondence: {
    heading: "Account correspondence",
    questions: [
      {
        id: "importantUpdatesByEmail", // should map consent keys from the customer API
        heading: "Important updates from ACCA",
        description: `<p>This includes publications and updates like:</p>
          <ul>
            <li>Reminders</li>
            <li>Practising certificate renewals</li>
            <li>AGM notifications</li>
            <li>CPD declarations</li>
          </ul>`,
        onlineCountryDescription: `
          <p>You will receive these types of updates by email:</p>
          <ul>
            <li>Reminders</li>
            <li>Practising certificate renewals</li>
            <li>AGM notifications</li>
            <li>CPD declarations</li>
          </ul>
        `,
        trueLabel: "Email",
        falseLabel: "Post",
        requiresEmail: true,
      },
      {
        id: "accountCorrespondenceByEmail",
        heading: "Account correspondence by email",
        description:
          "<p>Mandatory or important information which relate directly to your account and renewals. You can receive these by email (otherwise we will send them by post).</p>",
        trueLabel: "Email",
        falseLabel: "Post",
        requiresEmail: true,
      },
      {
        id: "importantUpdatesBySms",
        heading: "Important information by SMS",
        description: "<p>Would you also like information by SMS where available?</p>",
        trueLabel: "Yes",
        falseLabel: "No",
        requiresMobile: true,
      },
    ],
    footnote:
      "<p><strong>Please note:</strong> as part of providing our service, we need to make sure that important information reaches you. Even though we will use your preferred method when possible - from time to time we’ll use other methods.</p>",
  },
  additionalQuestions: {
    heading: "Additional content and notifications",
    questions: [
      {
        id: "accaEventsAndSurveys",
        heading: "ACCA events and surveys",
        description:
          "<p>In order to assist you with your career, we may send you information and updates relating to our services and events using the communication methods indicated above. This includes details of events and ACCA surveys.</p>",
        trueLabel: "Yes",
        falseLabel: "No",
        requiresImportantUpdatesByEmail: true,
      },
      {
        id: "directoryOfMembers",
        heading: "Include in directory of members",
        description: `
        <p>Show up in our global member directory. It's used by thousands of people and companies world-wide looking for ACCA qualified members.</p>
<p><strong>Please note:</strong> If you opt-out of the directory of members, only your name, designation and year of admission will be shown in the directory.</p>
        `,
        trueLabel: "Yes",
        falseLabel: "No",
      },
      {
        id: "thirdPartyMarketing",
        heading: "Offers from third parties",
        description:
          "<p>ACCA would like to keep you informed of products and services from third-party organisations that may be of interest, relevance or benefit to you in your career development. All third-party organisations are strictly vetted and the mailing/email list is never supplied directly to them. All offers are sent by ACCA or an ACCA-approved agency.</p>",
        trueLabel: "Yes",
        falseLabel: "No",
        requiresImportantUpdatesByEmail: true,
      },
    ],
    footnote:
      "<p>Please see our <a href='https://www.accaglobal.com/gb/en/footertoolbar/privacy/data-protection.html' target='_blank'>Data protection notice</a> and <a href='https://www.accaglobal.com/gb/en/footertoolbar/privacy/acca-website-privacy-promise.html' target='_blank'>Privacy promise</a> for more information on how your information is held and used.</p>",
  },
};

const student = {
  accountCorrespondence: {
    heading: "Account correspondence",
    questions: [
      {
        id: "importantUpdatesByEmail", // should map consent keys from the customer API
        heading: "Important updates from ACCA",
        description: `<p>This includes publications and updates like:</p>
                <ul>
                  <li>Exam results</li>
                  <li>Alerts to enter for exams</li>
                  <li>Your annual subscription</li>
                </ul>`,
        onlineCountryDescription: `
          <p>You will receive these types of updates by email:</p>
                <ul>
                  <li>Exam results</li>
                  <li>Alerts to enter for exams</li>
                  <li>Your annual subscription</li>
                </ul>
        `,
        trueLabel: "Email",
        falseLabel: "Post",
        requiresEmail: true,
      },
      {
        id: "accountCorrespondenceByEmail",
        heading: "Account correspondence by email",
        description:
          "<p>Mandatory or important information which relate directly to your account and renewals. You can receive these by email (otherwise we will send them by post).</p>",
        trueLabel: "Email",
        falseLabel: "Post",
        requiresEmail: true,
      },
      {
        id: "importantUpdatesBySms",
        heading: "Important information by SMS",
        description: "<p>Would you also like information by SMS where available?</p>",
        trueLabel: "Yes",
        falseLabel: "No",
        requiresMobile: true,
      },
    ],
    footnote:
      "<p><strong>Please note:</strong> as part of providing our service, we need to make sure that important information reaches you. Even though we will use your preferred method when possible - from time to time we’ll use other methods.</p>",
  },
  additionalQuestions: {
    heading: "Additional questions",
    questions: [
      {
        id: "examResultsBySms",
        heading: "Exam results by SMS",
        description: "<p>Get your exam results direct to your mobile phone</p>",
        trueLabel: "Yes",
        falseLabel: "No",
        requiresMobile: true,
      },
      {
        id: "accaEventsAndSurveys",
        heading: "ACCA events and surveys",
        description:
          "<p>In order to assist you with your studies, we may send you information and updates relating to our services and events using the communication methods indicated above. This includes details of events and ACCA surveys.</p>",
        trueLabel: "Yes",
        falseLabel: "No",
        requiresEmail: true,
        requiresImportantUpdatesByEmail: true,
      },
      {
        id: "affiliateList",
        heading: "Include in list of Passed Finalists",
        description: "<p>Show up in our list of passed finalists</p>",
        trueLabel: "Yes",
        falseLabel: "No",
      },
      {
        id: "thirdPartyMarketing",
        heading: "Offers from third parties",
        description:
          "<p>ACCA would like to keep you informed of products and services from third- party organisations that may be of interest, relevance or benefit to you in your career development. All third-party organisations are strictly vetted and the mailing/email list is never supplied directly to them. All offers are sent by ACCA or an ACCA-approved agency.</p>",

        trueLabel: "Yes",
        falseLabel: "No",
        requiresImportantUpdatesByEmail: true,
      },
      {
        id: "shareDetailWithEmployers",
        heading: "Share details with your employer",
        description:
          "<p>ACCA often communicates with employers to discuss improvements and ways in which ACCA can provide enhanced support for students. When communicating with employers it is often helpful to provide a list of our students who are working at that company.</p>",
        trueLabel: "Yes",
        falseLabel: "No",
      },
    ],
    footnote:
      "<p>Please see our <a href='https://www.accaglobal.com/gb/en/footertoolbar/privacy/data-protection.html' target='_blank'>Data protection notice</a> and <a href='https://www.accaglobal.com/gb/en/footertoolbar/privacy/acca-website-privacy-promise.html' target='_blank'>Privacy promise</a> for more information on how your information is held and used.</p>",
  },
};

const affiliate = {
  accountCorrespondence: {
    heading: "Account correspondence",
    questions: [
      {
        id: "importantUpdatesByEmail", // should map consent keys from the customer API
        heading: "Important updates from ACCA",
        description: "<p>This includes publications, updates and reminders.</p>",
        onlineCountryDescription: "<p>This includes publications, updates and reminders.</p>",
        trueLabel: "Email",
        falseLabel: "Post",
        requiresEmail: true,
      },
      {
        id: "accountCorrespondenceByEmail",
        heading: "Account correspondence by email",
        description:
          "<p>Mandatory or important information which relate directly to your account and renewals. You can receive these by email (otherwise we will send them by post).</p>",
        trueLabel: "Email",
        falseLabel: "Post",
        requiresEmail: true,
      },
      {
        id: "importantUpdatesBySms",
        heading: "Important information by SMS",
        description: "<p>Would you also like information by SMS where available?</p>",
        trueLabel: "Yes",
        falseLabel: "No",
        requiresMobile: true,
      },
    ],
    footnote:
      "<p><strong>Please note:</strong> as part of providing our service, we need to make sure that important information reaches you. Even though we will use your preferred method when possible - from time to time we’ll use other methods.</p>",
  },
  additionalQuestions: {
    heading: "Additional questions",
    questions: [
      {
        id: "accaEventsAndSurveys",
        heading: "ACCA events and surveys",
        description:
          "<p>In order to assist you with your studies, we may send you information and updates relating to our services and events using the communication methods indicated above. This includes details of events and ACCA surveys.</p>",
        trueLabel: "Yes",
        falseLabel: "No",
        requiresEmail: true,
        requiresImportantUpdatesByEmail: true,
      },
      {
        id: "affiliateList",
        heading: "Include in list of Passed Finalists",
        description: "<p>Show up in our list of passed finalists</p>",
        trueLabel: "Yes",
        falseLabel: "No",
      },
      {
        id: "thirdPartyMarketing",
        heading: "Offers from third parties",
        description:
          "<p>ACCA would like to keep you informed of products and services from third- party organisations that may be of interest, relevance or benefit to you in your career development. All third-party organisations are strictly vetted and the mailing/email list is never supplied directly to them. All offers are sent by ACCA or an ACCA-approved agency.</p>",

        trueLabel: "Yes",
        falseLabel: "No",
        requiresImportantUpdatesByEmail: true,
      },
    ],
    footnote:
      "<p>Please see our <a href='https://www.accaglobal.com/gb/en/footertoolbar/privacy/data-protection.html' target='_blank'>Data protection notice</a> and <a href='https://www.accaglobal.com/gb/en/footertoolbar/privacy/acca-website-privacy-promise.html' target='_blank'>Privacy promise</a> for more information on how your information is held and used.</p>",
  },
};

export default {
  browserTitle: {
    message: "Communication Preferences | ACCA Portal",
  },
  heading: "Communication Preferences",
  quote: "How should we stay in touch with you?",
  examSuppressionMessage:
    "<p><strong>Your changes may not apply to the current exam results release</strong></p><p>Any changes you make to your details may not update in time for the upcoming exam results release. This is due to exam results processing. Any changes you do make will be ready in time for the next session.</p>",
  errorMessages: {
    generic:
      "There was a problem saving your update. Please try again or <a href='/#/help'>contact us</a>.",
    noMobile: `No mobile number found for this account. <a href='/#${ROUTES.accountContactDetailsPage}' rel='noopener noreferrer'>Please update your account details.</a>`,
    noEmail: `No email address found for this account. <a href='/#${ROUTES.accountContactDetailsPage}' rel='noopener noreferrer'>Please update your account details.</a>`,
    duplicateEmail: `This email address is already in use. <a href='/#${ROUTES.accountContactDetailsPage}' rel='noopener noreferrer'>Please enter a unique email address.</a>`,
    requiresImportantUpdatesByEmail: "Only available if you get important updates by email.",
  },
  consents: {
    member,
    student,
    affiliate,
    graduate: affiliate,
  },

  memberNetworks: {
    heading: "Member networks",
    sectorNetworks: {
      heading: "Join sector networks",
      description:
        "<p>Select the sector networks you’d like to hear from. You can join one or more networks. You’ll receive news and updates by email from your chosen sector(s).</p>",
      placeholder: "Select sector networks",
    },
    regionalNetworks: {
      heading: "Join a regional network",
      description:
        "<p>Select the regional network you’d like to hear from. You can join one regional network. You’ll receive news and updates by email from your chosen network.</p>",
      placeholder: "Select regional network",
    },
  },
};
