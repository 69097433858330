import { ACTION_TYPES } from "./actions";

export const initialState = {};

const updatePractisingCertificates = (practisingCertificates, certificateNumber, newProps) => {
  return practisingCertificates.map(practisingCertificate => {
    if (practisingCertificate.certificateNumber !== certificateNumber) {
      return practisingCertificate;
    }

    return {
      ...practisingCertificate,
      ...newProps,
    };
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_DOCS:
      return initialState;
    case ACTION_TYPES.FETCH_DOCS_SUCCESS: {
      const {
        goodStanding,
        equivalence,
        certificates,
        practisingCertificates,
        membershipCertificate,
        docket,
      } = action.payload;
      return {
        ...state,
        keyResources: {
          goodStanding,
          equivalence,
          certificates,
          membershipCertificate,
          docket,
        },
        practisingCertificates,
      };
    }
    case ACTION_TYPES.FETCH_MEMBERSHIP_CERTIFICATE_DOWNLOAD: {
      return {
        ...state,
        keyResources: {
          ...state.keyResources,
          membershipCertificate: {
            ...state.keyResources.membershipCertificate,
            isLoading: true,
          },
        },
      };
    }
    case ACTION_TYPES.FETCH_MEMBERSHIP_CERTIFICATE_DOWNLOAD_SUCCESS: {
      return {
        ...state,
        keyResources: {
          ...state.keyResources,
          membershipCertificate: {
            ...state.keyResources.membershipCertificate,
            isLoading: false,
            hasDownloadError: false,
          },
        },
      };
    }
    case ACTION_TYPES.FETCH_MEMBERSHIP_CERTIFICATE_DOWNLOAD_ERROR: {
      return {
        ...state,
        keyResources: {
          ...state.keyResources,
          membershipCertificate: {
            ...state.keyResources.membershipCertificate,
            isLoading: false,
            hasDownloadError: true,
          },
        },
      };
    }
    case ACTION_TYPES.FETCH_PRACTISING_CERTIFICATE_DOWNLOAD: {
      return {
        ...state,
        practisingCertificates: updatePractisingCertificates(
          state.practisingCertificates,
          action.certificateNumber,
          {
            isLoading: true,
          }
        ),
      };
    }
    case ACTION_TYPES.FETCH_PRACTISING_CERTIFICATE_DOWNLOAD_SUCCESS: {
      return {
        ...state,
        practisingCertificates: updatePractisingCertificates(
          state.practisingCertificates,
          action.certificateNumber,
          {
            isLoading: false,
            hasDownloadError: false,
          }
        ),
      };
    }
    case ACTION_TYPES.FETCH_PRACTISING_CERTIFICATE_DOWNLOAD_ERROR: {
      return {
        ...state,
        practisingCertificates: updatePractisingCertificates(
          state.practisingCertificates,
          action.certificateNumber,
          {
            isLoading: false,
            hasDownloadError: true,
          }
        ),
      };
    }
    default:
      return state;
  }
};

export default reducer;
