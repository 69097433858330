import React from "react";
import PropTypes from "prop-types";
import Parser from "html-react-parser";

import { Doughnut, BodyCopy, Alert, LinkList, LinkListItem } from "acca-design-system";

import QUALIFICATION_PATHWAYS from "constants/pathways";
import IconMap from "components/IconMap/IconMap";
import QualificationsCard from "../QualificationsCard/QualificationsCard";

const ExamProgressCards = ({ content, pathway, qualifications }) => {
  const { exams } = qualifications;
  const { completed = null, total = null, hasConditionalExemptions } = exams;

  const contentKey =
    pathway === QUALIFICATION_PATHWAYS.ACCA_QUAL || pathway.indexOf("TRAIL_L7") !== -1
      ? QUALIFICATION_PATHWAYS.ACCA_QUAL
      : QUALIFICATION_PATHWAYS.FIA;

  const examsContent = content && content.content.find(({ key }) => key === contentKey);
  const conditionalExemptionsContent =
    content && content.content.find(({ key }) => key === "CONDITIONAL_EXEMPTIONS");

  const showExams = examsContent && completed != null && total != null;

  if (!showExams) {
    return null;
  }

  const doughnut = () => {
    const unitOfMeasurement = "EXAMS";
    const showBigNumber =
      pathway === QUALIFICATION_PATHWAYS.TRAIL_L4 || pathway === QUALIFICATION_PATHWAYS.TRAIL_L7;

    if (showBigNumber) {
      const progress = completed > 0 ? 100 : 0;
      return (
        <Doughnut progress={progress} size="medium" className="u-red">
          <p>
            <span className="h300 u-font-heading">{completed}</span> <br />
            <span className="u-grey">{unitOfMeasurement}</span>
          </p>
        </Doughnut>
      );
    }

    const progress = Math.round((completed / total) * 100);
    return (
      <Doughnut progress={progress} size="medium" className="u-red">
        <p>
          <span className="h400 u-font-heading">{completed}</span>/{total} <br />
          <span className="u-grey">{unitOfMeasurement}</span>
        </p>
      </Doughnut>
    );
  };

  const exemptionsAlert = () => (
    <Alert className="u-margin-top-2">
      <BodyCopy className="u-margin-bottom-1">
        {Parser(conditionalExemptionsContent.message)}
      </BodyCopy>
      <LinkList>
        {conditionalExemptionsContent.links.map(({ text, href, icon }, i) => {
          const linkIcon = IconMap({ name: icon });
          return <LinkListItem key={i} to={href} text={text} icon={linkIcon} />;
        })}
      </LinkList>
    </Alert>
  );
  return (
    <QualificationsCard
      sectionTitle={content.title}
      title={examsContent.title}
      links={examsContent.links}
      doughnut={doughnut()}
      additionalContent={hasConditionalExemptions && exemptionsAlert()}
    >
      {examsContent.message && <BodyCopy>{Parser(examsContent.message)}</BodyCopy>}
    </QualificationsCard>
  );
};

ExamProgressCards.propTypes = {
  pathway: PropTypes.string.isRequired,
  qualifications: PropTypes.shape({
    exams: PropTypes.shape({
      total: PropTypes.number,
      completed: PropTypes.number,
      hasConditionalExemptions: PropTypes.bool,
    }),
  }),
  content: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default ExamProgressCards;
