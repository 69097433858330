import { ACTION_TYPES } from "./actions";

export const initialState = {
  sections: {
    transfer: { isReadOnly: true },
    oxfordBrookes: { isReadOnly: true },
  },
};

function checkDetails(state, action) {
  if (action.values) {
    return {
      ...state.accountDetails,
      oxfordBrookesStatus: action.values.oxfordBrookesStatus
        ? action.values.oxfordBrookesStatus
        : state.accountDetails.oxfordBrookesStatus,
      transferFIAToAcca: action.values.transferFIAToAcca
        ? {
            ...state.accountDetails.transferFIAToAcca,
            selectedOption: action.values.transferFIAToAcca,
          }
        : state.accountDetails.transferFIAToAcca,
    };
  }

  return state.accountDetails;
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_ACCOUNT_DETAILS:
      return {
        ...state,
        accountDetails: null,
        error: null,
      };
    case ACTION_TYPES.FETCH_ACCOUNT_DETAILS_SUCCESS: {
      return {
        ...state,
        accountDetails: action.payload,
      };
    }
    case ACTION_TYPES.SUBMIT_ACCOUNT_DETAILS: {
      return {
        ...state,
        isSubmitting: true,
        error: null,
      };
    }
    case ACTION_TYPES.SUBMIT_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        accountDetails: { ...checkDetails(state, action) },
        isSubmitting: false,
        sections: initialState.sections,
      };
    case ACTION_TYPES.SUBMIT_ACCOUNT_DETAILS_ERROR:
      return {
        ...state,
        error: action.error.response,
        isSubmitting: false,
      };
    case ACTION_TYPES.TOGGLE_IS_READ_ONLY:
      return {
        ...state,
        sections: {
          ...state.sections,
          [action.sectionName]: {
            isReadOnly: action.isReadOnly,
          },
        },
      };

    default:
      return state;
  }
};

export default reducer;
