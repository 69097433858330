import React from "react";
import HandoffPage from "components/HandoffPage/HandoffPage";

const InternationalPracticingCertificateHandoffPage = () => (
  <HandoffPage
    message="Practicing Certification handoff page"
    outgoingUrl={process.env.INTERNATIONAL_PRACTISING_CERTIFICATE_OUTGOING_URL}
  />
);

export default InternationalPracticingCertificateHandoffPage;
