import React from "react";
import { ConnectedRouter } from "connected-react-router";
import { history } from "state/store";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import "focus-visible";
import AppInitialiser from "components/AppInitialiser/AppInitialiser";
import store from "../../state/store";
import MEMBER_REDIRECTS from "../../constants/memberRedirects";

/**
 * Redirect to the business portal if authGroups contain FIRMS
 * @param {*} authGroups
 */
export const invalidAuthGroupRedirect = authGroups => {
  return authGroups.indexOf("FIRMS") > -1 ? process.env.BUSINESS_PORTAL_URL : null;
};

export const membersGroupRedirect = (authGroups, route) => {
    if (authGroups.indexOf("ACCA_MEMBERS") > -1) {
        return MEMBER_REDIRECTS[route] || process.env.PP_PORTAL_BASE_URL;
    }
    return null;
};

const App = () => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <CookiesProvider>
          <AppInitialiser invalidAuthGroupRedirect={invalidAuthGroupRedirect} membersGroupRedirect={membersGroupRedirect}/>
        </CookiesProvider>
      </ConnectedRouter>
    </Provider>
  );
};

export default App;
