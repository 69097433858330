import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Parser from "html-react-parser";
import { BodyCopy } from "acca-design-system";

const TransferACCADisplay = ({ content, data, available }) => {
  const { quote, options, label } = content;
  const key = available.find(c => c.value === data);
  const option = key ? options.find(c => c.value === key.contentKey) : undefined;

  return (
    <Fragment>
      <BodyCopy className="u-margin-bottom-2">{Parser(quote)}</BodyCopy>
      <dl>
        <dt>{label}</dt>
        <dd>{option ? Parser(`${option.label} - ${option.text}`) : "Not yet selected"}</dd>
      </dl>{" "}
    </Fragment>
  );
};

TransferACCADisplay.propTypes = {
  content: PropTypes.shape({
    heading: PropTypes.string,
    quote: PropTypes.string,
    warning: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string })),
  }).isRequired,
  data: PropTypes.string,
  available: PropTypes.arrayOf(PropTypes.shape()),
};

export default TransferACCADisplay;
