export const ACTION_TYPES = {
  FETCH_DOCS: "DOCSDOWNLOADS/FETCH_DOCS",
  FETCH_DOCS_SUCCESS: "DOCSDOWNLOADS/FETCH_DOCS_SUCCESS",
  FETCH_DOCS_ERROR: "DOCSDOWNLOADS/FETCH_DOCS_ERROR",

  FETCH_PRACTISING_CERTIFICATE_DOWNLOAD: "DOCSDOWNLOADS/FETCH_PRACTISING_CERTIFICATE_DOWNLOAD",
  FETCH_PRACTISING_CERTIFICATE_DOWNLOAD_SUCCESS:
    "DOCSDOWNLOADS/FETCH_PRACTISING_CERTIFICATE_DOWNLOAD_SUCCESS",
  FETCH_PRACTISING_CERTIFICATE_DOWNLOAD_ERROR:
    "DOCSDOWNLOADS/FETCH_PRACTISING_CERTIFICATE_DOWNLOAD_ERROR",

  FETCH_MEMBERSHIP_CERTIFICATE_DOWNLOAD: "DOCSDOWNLOADS/FETCH_MEMBERSHIP_CERTIFICATE_DOWNLOAD",
  FETCH_MEMBERSHIP_CERTIFICATE_DOWNLOAD_SUCCESS:
    "DOCSDOWNLOADS/FETCH_MEMBERSHIP_CERTIFICATE_DOWNLOAD_SUCCESS",
  FETCH_MEMBERSHIP_CERTIFICATE_DOWNLOAD_ERROR:
    "DOCSDOWNLOADS/FETCH_MEMBERSHIP_CERTIFICATE_DOWNLOAD_ERROR",
};

export const fetchDocs = () => ({
  type: ACTION_TYPES.FETCH_DOCS,
});

export const fetchPractisingCertificate = (certificateNumber, certificateType) => ({
  type: ACTION_TYPES.FETCH_PRACTISING_CERTIFICATE_DOWNLOAD,
  certificateNumber,
  certificateType,
});

export const fetchMembershipCertificate = (certificateNumber, certificateType) => ({
  type: ACTION_TYPES.FETCH_MEMBERSHIP_CERTIFICATE_DOWNLOAD,
  certificateNumber,
  certificateType,
});
