import { withFormsy } from "formsy-react";
import React from "react";
import PropTypes from "prop-types";

import { FormField, Select } from "acca-design-system";

import Label from "components/Form/Label/Label";

class SelectField extends React.Component {
  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(value) {
    const { setValue, onChange } = this.props;

    if (onChange) onChange(value);

    // setValue() will set the value of the component, which in
    // turn will validate it and the rest of the form
    // Important: Don't skip this step. This pattern is required
    // for Formsy to work.
    setValue(value);
  }

  render() {
    const {
      containerClassName,
      getErrorMessage,
      getValue,
      id,
      label,
      options,
      required,
      multiple,
      disabled,
      hasJumpList,
    } = this.props;

    // An error message is returned only if the component is invalid
    const errorMessage = getErrorMessage();

    return (
      <FormField valid className={containerClassName}>
        {label && <Label id={id} text={label} required={required} />}
        <Select
          id={id}
          key={id}
          onChange={this.changeValue}
          options={options}
          value={getValue()}
          multiple={multiple}
          disabled={disabled}
          hasJumpList={hasJumpList}
        />
        <span>{errorMessage}</span>
      </FormField>
    );
  }
}

SelectField.propTypes = {
  containerClassName: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getErrorMessage: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  hasJumpList: PropTypes.bool,
};

export default withFormsy(SelectField);
