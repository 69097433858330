import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import profile from "state/profile/reducer";
import content from "state/content/reducer";
import appError from "state/errors/reducer";
import cpd from "state/cpd/reducer";
import cpdDeclaration from "state/cpd-declaration/reducer";
import cpdWaiverRequest from "state/cpd-waiver-request/reducer";
import accountDetails from "state/accountDetails/reducer";
import qualifications from "state/qualifications/reducer";
import communicationPreferences from "state/communicationPreferences/reducer";
import employment from "state/employment/reducer";
import lookup from "state/lookup/reducer";
import documents from "state/docDownloads/reducer";
import contactDetails from "state/contactDetails/reducer";
import applyForMembership from "state/applyForMembership/reducer";
import registerOfRetiredMembers from "state/registerOfRetiredMembers/reducer";
import login from "state/login/reducer";

const customerReducer = history =>
  combineReducers({
    router: connectRouter(history),
    profile,
    content,
    appError,
    cpd,
    cpdDeclaration,
    cpdWaiverRequest,
    accountDetails,
    qualifications,
    communicationPreferences,
    employment,
    lookup,
    documents,
    contactDetails,
    applyForMembership,
    registerOfRetiredMembers,
    login,
  });

export default customerReducer;
