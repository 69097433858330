import REGISTER_OF_RETIRED_MEMBERS_STEPS from "constants/registerOfRetiredMembersSteps";
import { ACTION_TYPES } from "./actions";

export const initialState = {
  step: REGISTER_OF_RETIRED_MEMBERS_STEPS.LANDING,
  values: {
    declarationAccepted: false,
    isPermanentRetirement: null,
    isPcOrInsolvencyLicenceHolder: null,
  },
};

const updateValue = (state, key, value) => ({
  ...state.values,
  [key]: value,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    case ACTION_TYPES.CLOSE_FORM:
      return { ...initialState };
    case ACTION_TYPES.SET_STEP:
      return {
        ...state,
        step: action.step,
      };
    case ACTION_TYPES.SUBMIT_SUCCESS:
      return {
        ...state,
        step: REGISTER_OF_RETIRED_MEMBERS_STEPS.COMPLETE,
      };
    case ACTION_TYPES.SUBMIT_ERROR:
      return {
        ...state,
        step: REGISTER_OF_RETIRED_MEMBERS_STEPS.ERROR,
      };
    case ACTION_TYPES.SET_DECLARATION_ACCEPTED:
      return {
        ...state,
        values: updateValue(state, "declarationAccepted", action.value),
      };
    case ACTION_TYPES.SET_CHINA_DATA_PROTECTION_ACCEPTED:
      return {
        ...state,
        values: updateValue(state, "chinaDataProtectionAccepted", action.value),
      };
    case ACTION_TYPES.SET_RETIREMENT_DATE:
      return {
        ...state,
        values: updateValue(state, "retirementDate", action.value),
      };
    case ACTION_TYPES.SET_IS_PERMANENT_RETIREMENT:
      return {
        ...state,
        values: updateValue(state, "isPermanentRetirement", action.value),
      };
    case ACTION_TYPES.SET_IS_PC_OR_INSOLVENCY_LICENCE_HOLDER:
      return {
        ...state,
        values: updateValue(state, "isPcOrInsolvencyLicenceHolder", action.value),
      };
    default:
      return state;
  }
};

export default reducer;
