import _get from "lodash.get";

export const getPreviousPath = state => state.content.previousPath;

export const getPageFrameworkContent = state => state.content.pageFramework;
export const getPageFrameworkFailedToLoad = state =>
  _get(state.content, "pageFramework.failedToLoad", false);
export const getLandingPageContent = state => state.content.landingPage;
export const getPageNotFoundContent = state => state.content.pageNotFound;
export const getFooterContent = state =>
  state.content.pageFramework ? state.content.pageFramework.footer : null;
export const getCpdContent = state => state.content.cpd;
export const getProfessionalDevelopmentContent = state => state.content.professionalDevelopment;
export const getMyDetailsContent = state => state.content.myDetails;
export const getLoginDetailsContent = state => state.content.loginDetails;
export const getAccountDetailsContent = state => state.content.accountDetails;
export const getQualificationsContent = state => state.content.qualifications;
export const getFeesContent = state => state.content.fees;
export const getCommunicationPreferencesContent = state => state.content.communicationPreferences;
export const getDocsContent = state => state.content.docs;
export const getHelpInformationContent = state => state.content.helpInformation;
export const getEmploymentContent = state => state.content.employment;
export const getContactDetailsContent = state => state.content.contactDetails;
export const getOnboardingContent = state => state.content.onboarding;
export const getSuspensionsContent = state => state.content.suspensions;
export const getCriticalAlertContent = state => _get(state.content, "pageFramework.alert", false);
