import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { BodyCopy } from "acca-design-system";
import { InputField, SelectField } from "components/Form";

const EmploymentInputForm = ({ content, data, lookup }) => {
  const showField = (field, key) =>
    field !== undefined && !!content.fields.find(c => c.key === key);

  return (
    <Fragment>
      {showField(data.companyName, "CompanyName") ? (
        <>
          <InputField
            label={content.fields.find(c => c.key === "CompanyName").text}
            name="companyName"
            type="text"
            id="companyName"
            value={data.companyName}
            maxLength="67"
            disabled={data.businessAddressAvailable === "N"}
          />
          <BodyCopy className="u-text-right u-overline-caption">Max 67 chars</BodyCopy>
        </>
      ) : null}

      {showField(data.jobTitle, "JobTitle") ? (
        <>
          <InputField
            label={content.fields.find(c => c.key === "JobTitle").text}
            name="jobTitle"
            id="jobTitle"
            type="text"
            value={data.jobTitle}
            maxLength="40"
            disabled={data.businessAddressAvailable === "N"}
          />
          <BodyCopy className="u-text-right u-overline-caption">Max 40 chars</BodyCopy>
        </>
      ) : null}

      {showField(data.businessCategory, "BusinessCategory") ? (
        <SelectField
          label={content.fields.find(c => c.key === "BusinessCategory").text}
          options={lookup.businessCategories}
          placeholder="Select..."
          name="businessCategory"
          value={{
            value: data.businessCategory,
            label: lookup.businessCategories.find(l => l.value === data.businessCategory)
              ? lookup.businessCategories.find(l => l.value === data.businessCategory).label
              : "",
          }}
          id="businessCategory"
        />
      ) : null}

      {showField(data.employmentCategory, "EmploymentCategory") ? (
        <SelectField
          label={content.fields.find(c => c.key === "EmploymentCategory").text}
          options={lookup.employmentCategories}
          placeholder="Select..."
          name="employmentCategory"
          value={{
            value: data.employmentCategory,
            label: lookup.employmentCategories.find(l => l.value === data.employmentCategory)
              ? lookup.employmentCategories.find(l => l.value === data.employmentCategory).label
              : "",
          }}
          id="employmentCategory"
        />
      ) : null}

      {showField(data.organisationSize, "OrganisationSize") ? (
        <SelectField
          label={content.fields.find(c => c.key === "OrganisationSize").text}
          options={lookup.organisationSizes}
          placeholder="Select..."
          name="organisationSize"
          value={{
            value: data.organisationSize,
            label: lookup.organisationSizes.find(l => l.value === data.organisationSize)
              ? lookup.organisationSizes.find(l => l.value === data.organisationSize).label
              : "",
          }}
          id="organisationSize"
        />
      ) : null}

      {showField(data.jobCategory, "JobCategory") ? (
        <SelectField
          label={content.fields.find(c => c.key === "JobCategory").text}
          options={lookup.jobCategories}
          placeholder="Select..."
          name="jobCategory"
          required
          value={{
            value: data.jobCategory,
            label: lookup.jobCategories.find(l => l.value === data.jobCategory)
              ? lookup.jobCategories.find(l => l.value === data.jobCategory).label
              : "",
          }}
          id="jobCategory"
        />
      ) : null}
    </Fragment>
  );
};

EmploymentInputForm.propTypes = {
  lookup: PropTypes.shape({
    jobTitles: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ),
    businessCategories: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ),
    employmentCategories: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ),
    organisationSizes: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ),
    jobCategories: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ),
  }),
  content: PropTypes.shape({
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        key: PropTypes.string,
      })
    ).isRequired,
    warning: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({
    companyName: PropTypes.string,
    jobTitle: PropTypes.string,
    fullname: PropTypes.string,
    businessCategory: PropTypes.string,
    employmentCategory: PropTypes.string,
    organisationSize: PropTypes.string,
    jobCategory: PropTypes.string,
    businessAddressAvailable: PropTypes.string,
  }),
};

export default EmploymentInputForm;
