export const ACTION_TYPES = {
  FETCH_EMPLOYMENT_LOOKUP: "LOOKUP/FETCH_EMPLOYMENT_LOOKUP",
  FETCH_EMPLOYMENT_LOOKUP_SUCCESS: "LOOKUP/FETCH_EMPLOYMENT_LOOKUP_SUCCESS",
  FETCH_EMPLOYMENT_LOOKUP_ERROR: "LOOKUP/FETCH_EMPLOYMENT_LOOKUP_ERROR",

  FETCH_NETWORKS: "LOOKUP/FETCH_NETWORKS",
  FETCH_NETWORKS_ERROR: "LOOKUP/FETCH_NETWORKS_ERROR",
  FETCH_NETWORKS_SUCCESS: "LOOKUP/FETCH_NETWORKS_SUCCESS",

  FETCH_MEMBERSHIP_EMPLOYMENT_CATEGORIES: "LOOKUP/FETCH_MEMBERSHIP_EMPLOYMENT_CATEGORIES",
  FETCH_MEMBERSHIP_EMPLOYMENT_CATEGORIES_SUCCESS:
    "LOOKUP/FETCH_MEMBERSHIP_EMPLOYMENT_CATEGORIES_SUCCESS",
  FETCH_MEMBERSHIP_EMPLOYMENT_CATEGORIES_ERROR:
    "LOOKUP/FETCH_MEMBERSHIP_EMPLOYMENT_CATEGORIES_ERROR",
};

export const fetchEmploymentLookup = () => ({
  type: ACTION_TYPES.FETCH_EMPLOYMENT_LOOKUP,
});

export const fetchMembershipEmploymentCategories = () => ({
  type: ACTION_TYPES.FETCH_MEMBERSHIP_EMPLOYMENT_CATEGORIES,
});

export const fetchNetworks = () => ({
  type: ACTION_TYPES.FETCH_NETWORKS,
});
