export const ACTION_TYPES = {
  FETCH_CPD_HISTORY: "CPD/FETCH_CPD_HISTORY",
  FETCH_CPD_HISTORY_SUCCESS: "CPD/FETCH_CPD_HISTORY_SUCCESS",
  FETCH_CPD_HISTORY_ERROR: "CPD/FETCH_CPD_HISTORY_ERROR",

  DELETE_WAIVER: "CPD/DELETE_WAIVER",
  DELETE_WAIVER_SUCCESS: "CPD/DELETE_WAIVER_SUCCESS",
  DELETE_WAIVER_ERROR: "CPD/DELETE_WAIVER_ERROR",
};

export const fetchCpdHistory = () => ({
  type: ACTION_TYPES.FETCH_CPD_HISTORY,
});

export const deleteWaiver = waiverId => ({
  type: ACTION_TYPES.DELETE_WAIVER,
  waiverId,
});
