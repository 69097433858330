import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { LiveMessage } from "react-aria-live";

import { getUserType } from "state/profile/selectors";

import { trackPageView } from "analytics/trackEvents";

import usePageLoadTracking from "analytics/PageLoadTracking";

const mapStateToProps = state => ({
  userType: getUserType(state),
});

const BrowserTitle = ({ content, userType, customPageViewPath }) => {
  const pageName = content && content.browserTitle ? content.browserTitle.message : "";

  useEffect(() => {
    if (pageName !== "" && userType) {
      trackPageView(pageName, userType, customPageViewPath);
    }
  }, [pageName, userType]);

  usePageLoadTracking(content);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {pageName !== "" && (
        <LiveMessage message={`${pageName} page has loaded`} aria-live="assertive" />
      )}
      <Helmet>
        <title>{pageName}</title>
      </Helmet>
    </>
  );
};

BrowserTitle.propTypes = {
  customPageViewPath: PropTypes.string,
  userType: PropTypes.string,
  content: PropTypes.shape({
    browserTitle: PropTypes.shape({
      message: PropTypes.string.isRequired,
    }),
  }),
};

export default connect(mapStateToProps)(BrowserTitle);
