import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import DATE_FORMATS from "constants/dates";
import { IconCertificate32 } from "acca-design-system";

import DownloadCard from "components/DownloadCard/DownloadCard";
import { fetchPractisingCertificate } from "state/docDownloads/actions";

const mapDispatchToProps = dispatch => ({
  downloadPdf: (certificateNumber, certificateType) =>
    dispatch(fetchPractisingCertificate(certificateNumber, certificateType)),
});

export const PractisingCertificateDownloadCard = ({
  certificateName,
  expiryDisplayDate,
  jurisdictionDescription,
  certificateNumber,
  certificateType,
  isLoading,
  hasDownloadError,
  downloadPdf,
}) => {
  const expires = format(parseISO(expiryDisplayDate), DATE_FORMATS.FORMAT_DAY_MONTH_YEAR);
  return (
    <DownloadCard
      isLoading={!!isLoading}
      icon={IconCertificate32}
      hasError={!!hasDownloadError}
      fetchDownload={() => downloadPdf(certificateNumber, certificateType)}
      heading={certificateName}
      message={`${jurisdictionDescription}, expires ${expires}`}
    />
  );
};

PractisingCertificateDownloadCard.propTypes = {
  certificateName: PropTypes.string.isRequired,
  expiryDisplayDate: PropTypes.string.isRequired,
  jurisdictionDescription: PropTypes.string.isRequired,
  certificateType: PropTypes.string.isRequired,
  certificateNumber: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  hasDownloadError: PropTypes.bool,
  downloadPdf: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(PractisingCertificateDownloadCard);
