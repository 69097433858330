export default {
  system: {
    id: "page-not-found",
    language: "en",
    content: {
      browserTitle: {
        message: "This is just a dummy so that AEM renders the JSON of the content fragment",
      },
      heading: "Page not found",
      message: "The link you have followed may be broken, or the page may have been removed.",
      button: {
        text: "Back to homepage",
        href: "/",
      },
      footer: {
        heading: "Need help?",
        message: "Get 24/7 support from our customer service team.",
      },
    },
  },
};
