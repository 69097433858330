import React from "react";
import PropTypes from "prop-types";
import { DashboardCard, Button, IconOpenInANewWindow16 } from "acca-design-system";
import Parser from "html-react-parser";

const FeeLinkCard = ({ card }) => {
  const { text, link } = card;

  return (
    <DashboardCard
      heading={Parser(text)}
      className="u-margin-bottom-2"
      button={
        link &&
        link.href && (
          <Button
            text={link.text}
            to={link.href}
            icon={IconOpenInANewWindow16}
            iconPosition="right"
          />
        )
      }
    />
  );
};

FeeLinkCard.propTypes = {
  card: PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.shape({
      text: PropTypes.string,
      href: PropTypes.string,
    }),
  }).isRequired,
};

export default FeeLinkCard;
