const ROUTES = {
  landingPage: "/",
  professionalDevelopmentPage: "/professional-development",
  annualDeclarationsPage: "/professional-development/annual-declarations",
  examsPage: "/my-qualifications",
  applyForMembershipPage: "/apply-for-membership",
  documentsResourcesPage: "/documents-and-downloads",
  feesAndTransactionsPage: "/fees-and-transactions",
  errorPage: "/error",
  accountLanding: "/my-details",
  accountContactDetailsPage: "/my-details/contact-details",
  accountDetailsPage: "/my-details/account-details",
  registerOfRetiredMembers: "/retirement",
  accountCommunicationPreferencesPage: "/my-details/communication-preferences",
  employmentDetailsPage: "/my-details/employment-details",
  loginDetailsPage: "/my-details/login-details",
  helpPage: "/help",
  outgoingEvidenceTrackerPage: "/outgoing/evidence-tracker",
  outgoingPracticeCertificateRenewalHandoffPage: "/outgoing/practising-certificate-renewal",
  outgoingInternationalPracticeCertificateHandoffPage:
    "/outgoing/international-practising-certificate-renewal",
  outgoingFipHandoffPage: "/outgoing/foundations-in-professionalism",
};

export default ROUTES;
