import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Parser from "html-react-parser";

import PageContentLoader from "components/PageContentLoader/PageContentLoader";
import { getPageNotFoundContent } from "state/content/selectors";
import { fetchPageNotFoundContent } from "state/content/actions";
import AppMessage from "components/AppMessage/AppMessage";
import illustrationSrc from "assets/images/illustrations/illustration-404.svg";
import BrowserTitle from "components/BrowserTitle/BrowserTitle";
import { BodyCopy } from "acca-design-system";

const mapStateToProps = state => ({
  content: getPageNotFoundContent(state),
});

const mapDispatchToProps = dispatch => ({
  loadContent: () => dispatch(fetchPageNotFoundContent()),
});

const PageNotFoundPage = ({ content, loadContent }) => {
  useEffect(() => {
    if (!content) {
      loadContent();
    }
  }, []);

  if (!content) {
    return <PageContentLoader />;
  }

  const { heading, message, button, footer } = content;

  return (
    <Fragment>
      <BrowserTitle content={content} />
      <AppMessage
        title={heading}
        message={message}
        buttons={[button]}
        footer={
          <>
            <h3 className="h700 u-font-heading u-margin-bottom-1">{footer.heading}</h3>
            <BodyCopy>
              <p>
                {Parser(footer.message)}{" "}
                <a
                  className="u-black"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.accaglobal.com/gb/en/footertoolbar/contact-us.html"
                >
                  Get help
                </a>
                .
              </p>
            </BodyCopy>
          </>
        }
        illustrationSrc={illustrationSrc}
      />
    </Fragment>
  );
};

PageNotFoundPage.propTypes = {
  content: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    button: PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    }).isRequired,
    footer: PropTypes.shape({
      heading: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
    }).isRequired,
  }),
  loadContent: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageNotFoundPage);
