import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { PageHeader, Alert, BodyCopy } from "acca-design-system";

import PageContentLoader from "components/PageContentLoader/PageContentLoader";
import BrowserTitle from "components/BrowserTitle/BrowserTitle";
import Container from "components/Container/Container";

import { handleError } from "state/errors/actions";
import { fetchConsents, fetchUserNetworks } from "state/communicationPreferences/actions";
import {
  getIsConsentsLoaded,
  getShowMemberNetworksQuestions,
  getIsUserNetworksLoaded,
} from "state/communicationPreferences/selectors";
import {
  getUserType,
  getIsExamSuppressionPeriod,
  getNotificationsHasError,
} from "state/profile/selectors";
import Parser from "html-react-parser";
import { fetchNetworks } from "state/lookup/actions";
import { getIsNetworkLookupLoaded } from "state/lookup/selectors";
import content from "./CommunicationPreferencesPage.content";
import CommunicationPreferencesSection from "./components/CommunicationPreferencesSection/CommunicationPreferencesSection";
import MemberNetworksSection from "./components/MemberNetworksSection/MemberNetworksSection";

const mapStateToProps = state => ({
  isConsentsLoaded: getIsConsentsLoaded(state),
  showMemberNetworksQuestions: getShowMemberNetworksQuestions(state),
  isUserNetworksLoaded: getIsUserNetworksLoaded(state),
  isNetworkLookupLoaded: getIsNetworkLookupLoaded(state),
  customerType: getUserType(state),
  isExamSuppressionPeriod: getIsExamSuppressionPeriod(state),
  notificationsHasError: getNotificationsHasError(state),
});

const mapDispatchToProps = dispatch => ({
  loadConsents: () => dispatch(fetchConsents()),
  loadAllNetworks: () => dispatch(fetchNetworks()),
  loadUserNetworks: () => dispatch(fetchUserNetworks()),
  setAppError: error => dispatch(handleError(error)),
});

const CommunicationPreferencesPage = ({
  loadConsents,
  loadAllNetworks,
  loadUserNetworks,
  isConsentsLoaded,
  showMemberNetworksQuestions,
  isUserNetworksLoaded,
  isNetworkLookupLoaded,
  customerType,
  isExamSuppressionPeriod,
  setAppError,
  notificationsHasError,
}) => {
  useEffect(() => {
    loadConsents();
    if (showMemberNetworksQuestions) {
      if (!isNetworkLookupLoaded) {
        loadAllNetworks();
      }
      loadUserNetworks();
    }
  }, []);

  useEffect(() => {
    if (notificationsHasError) {
      setAppError({ response: { status: "notificationsError" } });
    }
  }, [notificationsHasError]);

  const isPageReady =
    isConsentsLoaded &&
    ((showMemberNetworksQuestions && isUserNetworksLoaded && isNetworkLookupLoaded) ||
      !showMemberNetworksQuestions);

  if (!isPageReady) {
    return <PageContentLoader />;
  }

  const { heading, quote, examSuppressionMessage } = content;

  const { accountCorrespondence, additionalQuestions } = content.consents[customerType];

  return (
    <Fragment>
      <BrowserTitle content={content} />
      <PageHeader className="u-margin-bottom-2" pageTitle={heading} quote={quote} />

      <Container innerClassName="communication-preferences">
        <div className="cell">
          {isExamSuppressionPeriod && (
            <Alert type="warning" className="u-margin-bottom-2">
              <BodyCopy>{Parser(examSuppressionMessage)}</BodyCopy>
            </Alert>
          )}
          <CommunicationPreferencesSection {...accountCorrespondence} />
          <CommunicationPreferencesSection {...additionalQuestions} />
          {showMemberNetworksQuestions && <MemberNetworksSection />}
        </div>
      </Container>
    </Fragment>
  );
};

CommunicationPreferencesPage.propTypes = {
  loadConsents: PropTypes.func.isRequired,
  loadAllNetworks: PropTypes.func.isRequired,
  loadUserNetworks: PropTypes.func.isRequired,
  isConsentsLoaded: PropTypes.bool.isRequired,
  showMemberNetworksQuestions: PropTypes.bool.isRequired,
  isUserNetworksLoaded: PropTypes.bool.isRequired,
  isNetworkLookupLoaded: PropTypes.bool.isRequired,
  customerType: PropTypes.string.isRequired,
  isExamSuppressionPeriod: PropTypes.bool.isRequired,
  setAppError: PropTypes.func,
  notificationsHasError: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunicationPreferencesPage);
