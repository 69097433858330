/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import { ACTION_TYPES } from "./actions";

export const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOG_PREVIOUS_PATH:
      return {
        ...state,
        previousPath: action.previousPath,
      };
    case ACTION_TYPES.FETCH_PAGE_FRAMEWORK_CONTENT_SUCCESS:
      return {
        ...state,
        pageFramework: action.payload.content,
      };
    case ACTION_TYPES.FETCH_PAGE_FRAMEWORK_CONTENT_ERROR:
      return {
        ...state,
        pageFramework: {
          failedToLoad: true,
        },
      };
    case ACTION_TYPES.FETCH_LANDING_PAGE_CONTENT_SUCCESS:
      return {
        ...state,
        landingPage: action.payload.content,
      };
    case ACTION_TYPES.FETCH_PAGE_NOT_FOUND_CONTENT_SUCCESS:
      return {
        ...state,
        pageNotFound: action.payload.content,
      };
    case ACTION_TYPES.FETCH_CPD_CONTENT_SUCCESS:
      return {
        ...state,
        cpd: action.payload.content,
      };
    case ACTION_TYPES.FETCH_PROFESSIONAL_DEVELOPMENT_CONTENT_SUCCESS:
      return {
        ...state,
        professionalDevelopment: action.payload.content,
      };
    case ACTION_TYPES.FETCH_MY_DETAILS_CONTENT_SUCCESS:
      return {
        ...state,
        myDetails: action.payload.content,
      };
    case ACTION_TYPES.FETCH_LOGIN_DETAILS_CONTENT_SUCCESS:
      return {
        ...state,
        loginDetails: action.payload.content,
      };
    case ACTION_TYPES.FETCH_ACCOUNT_DETAILS_CONTENT_SUCCESS:
      return {
        ...state,
        accountDetails: action.payload.content,
      };
    case ACTION_TYPES.FETCH_QUALIFICATIONS_CONTENT_SUCCESS:
      return {
        ...state,
        qualifications: action.payload.content,
      };
    case ACTION_TYPES.FETCH_FEES_CONTENT_SUCCESS:
      return {
        ...state,
        fees: action.payload.content,
      };
    case ACTION_TYPES.FETCH_DOCS_CONTENT_SUCCESS:
      return {
        ...state,
        docs: action.payload.content,
      };
    case ACTION_TYPES.FETCH_HELP_INFORMATION_CONTENT_SUCCESS:
      return {
        ...state,
        helpInformation: action.payload.content,
      };
    case ACTION_TYPES.FETCH_EMPLOYMENT_CONTENT_SUCCESS:
      return {
        ...state,
        employment: action.payload.content,
      };
    case ACTION_TYPES.FETCH_COMMUNICATION_PREFERENCES_CONTENT_SUCCESS:
      return {
        ...state,
        communicationPreferences: action.payload.content,
      };
    case ACTION_TYPES.FETCH_CONTACT_DETAILS_CONTENT_SUCCESS:
      return {
        ...state,
        contactDetails: action.payload.content,
      };
    case ACTION_TYPES.FETCH_ONBOARDING_CONTENT_SUCCESS:
      return {
        ...state,
        onboarding: action.payload.content,
      };
    case ACTION_TYPES.FETCH_SUSPENSIONS_CONTENT_SUCCESS:
      return {
        ...state,
        suspensions: action.payload.content,
      };
    default:
      return state;
  }
};

export default reducer;
