import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { PageHeader } from "acca-design-system";

import {
  getKeyResources,
  getPractisingCertificates,
  getCanDownloadMembershipCertificate,
} from "state/docDownloads/selectors";
import {
  getIsExamSuppressionPeriod,
  getIsNotificationsLoaded,
  getNotificationsHasError,
} from "state/profile/selectors";
import { getDocsContent } from "state/content/selectors";

import { fetchDocs } from "state/docDownloads/actions";
import { handleError } from "state/errors/actions";
import { fetchDocsContent } from "state/content/actions";

import PageContentLoader from "components/PageContentLoader/PageContentLoader";
import BrowserTitle from "components/BrowserTitle/BrowserTitle";
import Container from "components/Container/Container";
import AdditionalLinks from "components/AdditionalLinks/AdditionalLinks";
import DocLinkCard from "./components/DocLinkCard/DocLinkCard";
import PractisingCertificateDownloadCard from "./components/PractisingCertificateDownloadCard/PractisingCertificateDownloadCard";
import MembershipCertificateDownloadCard from "./components/MembershipCertificateDownloadCard/MembershipCertificateDownloadCard";

const mapStateToProps = state => ({
  keyResources: getKeyResources(state),
  practisingCertificates: getPractisingCertificates(state),
  canDownloadMembershipCertificate: getCanDownloadMembershipCertificate(state),
  content: getDocsContent(state),
  isExamSuppressionPeriod: getIsExamSuppressionPeriod(state),
  isNotificationsLoaded: getIsNotificationsLoaded(state),
  notificationsHasError: getNotificationsHasError(state),
});

const mapDispatchToProps = dispatch => ({
  loadDocs: () => dispatch(fetchDocs()),
  loadContent: () => dispatch(fetchDocsContent()),
  setAppError: error => dispatch(handleError(error)),
});

const DocDownloadsPage = ({
  loadDocs,
  keyResources,
  content,
  loadContent,
  isExamSuppressionPeriod,
  isNotificationsLoaded,
  setAppError,
  notificationsHasError,
  practisingCertificates,
  canDownloadMembershipCertificate,
}) => {
  useEffect(() => {
    loadDocs();

    if (!content) {
      loadContent();
    }
  }, []);

  useEffect(() => {
    if (notificationsHasError) {
      setAppError({ response: { status: "notificationsError" } });
    }
  }, [notificationsHasError]);

  const pageReady = !!content && !!keyResources && !!isNotificationsLoaded;

  if (!pageReady) {
    return <PageContentLoader />;
  }

  const { heading, quote, title, cards, footer, links } = content;

  return (
    <Fragment>
      <BrowserTitle content={content} />
      <PageHeader className="u-margin-bottom-2" pageTitle={heading} quote={quote} />

      <Container className="section">
        <div className="cell">
          <h3 className="section-title">{title}</h3>
          {cards.map((cardContent, i) => {
            const keyResource = keyResources[cardContent.key];

            if (isExamSuppressionPeriod && cardContent.key === "goodStanding") {
              keyResource.eligible = false;
              keyResource.warning = "suppress";
            }

            if (cardContent.key === "membershipCertificate") {
              return (
                !!canDownloadMembershipCertificate && (
                  <MembershipCertificateDownloadCard
                    key={i}
                    content={cardContent}
                    membershipCertificate={keyResource}
                  />
                )
              );
            }

            return (
              keyResource && <DocLinkCard key={i} document={keyResource} content={cardContent} />
            );
          })}
        </div>
        {practisingCertificates && practisingCertificates.length > 0 && (
          <div className="cell">
            <h3 className="section-title">Practising certificates</h3>
            {practisingCertificates.map((practisingCertificate, i) => (
              <PractisingCertificateDownloadCard {...practisingCertificate} key={i} />
            ))}
          </div>
        )}
      </Container>

      {links && <AdditionalLinks heading={footer} links={links} />}
    </Fragment>
  );
};

DocDownloadsPage.propTypes = {
  canDownloadMembershipCertificate: PropTypes.bool.isRequired,
  practisingCertificates: PropTypes.arrayOf(PropTypes.shape({})),
  loadDocs: PropTypes.func.isRequired,
  keyResources: PropTypes.shape({
    goodStanding: PropTypes.shape({}),
  }),
  isExamSuppressionPeriod: PropTypes.bool.isRequired,
  isNotificationsLoaded: PropTypes.bool.isRequired,
  content: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    quote: PropTypes.string,
    title: PropTypes.string,
    cards: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        link: PropTypes.shape({
          icon: PropTypes.string,
          text: PropTypes.string,
          href: PropTypes.string,
        }),
      })
    ),
    footer: PropTypes.string,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        link: PropTypes.shape({
          text: PropTypes.string,
          href: PropTypes.string,
        }),
      })
    ),
  }),
  loadContent: PropTypes.func.isRequired,
  setAppError: PropTypes.func,
  notificationsHasError: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocDownloadsPage);
