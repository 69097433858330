import { takeLeading, call, select } from "redux-saga/effects";
import ApiService from "services/ApiService";
import apiConfig from "configs/apiConfig";
import fetchSaga from "utilities/fetchSaga";
import { getUserType } from "state/profile/selectors";
import { ACTION_TYPES } from "./actions";

function* handleFetchEmploymentLookup({ type }) {
  const customerType = yield select(getUserType);
  yield call(
    fetchSaga(ApiService.callApi, apiConfig.endpoints.lookup.employment(customerType), type)
  );
}

function* handleFetchMembershipEmploymentCategoriesLookup({ type }) {
  yield call(fetchSaga(ApiService.callApi, apiConfig.endpoints.lookup.employment("member"), type));
}

function* handleFetchNetworks({ type }) {
  yield call(fetchSaga(ApiService.callApi, apiConfig.endpoints.lookup.networks, type));
}

export default function* lookupSagas() {
  yield takeLeading(ACTION_TYPES.FETCH_EMPLOYMENT_LOOKUP, handleFetchEmploymentLookup);
  yield takeLeading(
    ACTION_TYPES.FETCH_MEMBERSHIP_EMPLOYMENT_CATEGORIES,
    handleFetchMembershipEmploymentCategoriesLookup
  );
  yield takeLeading(ACTION_TYPES.FETCH_NETWORKS, handleFetchNetworks);
}
