const REGISTER_OF_RETIRED_MEMBERS_STEPS = {
  LANDING: "LANDING",
  PERMANENT_RETIREMENT: "PERMANENT_RETIREMENT",
  INSOLVENCY_LICENCE: "INSOLVENCY_LICENCE",
  DECLARATION: "DECLARATION",
  DATA_PROTECTION_CHINA: "DATA_PROTECTION_CHINA",
  DATA_PROTECTION: "DATA_PROTECTION",
  SUBMITTING: "SUBMITTING",
  COMPLETE: "COMPLETE",
  ERROR: "ERROR",
  ALREADY_RETIRED: "ALREADY_RETIRED",
};
export default REGISTER_OF_RETIRED_MEMBERS_STEPS;
