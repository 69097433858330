const MEMBER_REDIRECTS = {
    "/": `${process.env.PP_PORTAL_BASE_URL}/sso-home`,
    "/professional-development" : `${process.env.PP_PORTAL_BASE_URL}/professional-development`,
    "/professional-development/annual-declarations" : `${process.env.PP_PORTAL_BASE_URL}/professional-development/`,
    "/my-qualifications" : `${process.env.PP_PORTAL_BASE_URL}/qualifications`,
    "/documents-and-downloads" : `${process.env.PP_PORTAL_BASE_URL}/personal-documents`,
    "/fees-and-transactions" : `${process.env.PP_PORTAL_BASE_URL}/personal-finance/`,
    "/my-details" : `${process.env.PP_PORTAL_BASE_URL}/my-details/`,
    "/my-details/contact-details" : `${process.env.PP_PORTAL_BASE_URL}/personal-account/my-details`,
    "/my-details/account-details" : `${process.env.PP_PORTAL_BASE_URL}/personal-account/my-details`,
    "/retirement" : `${process.env.PP_PORTAL_BASE_URL}`,
    "/my-details/communication-preferences" : `${process.env.PP_PORTAL_BASE_URL}/personal-account/communication-preferences`,
    "/my-details/employment-details" : `${process.env.PP_PORTAL_BASE_URL}/personal-account/my-details`,
    "/my-details/login-details" : `${process.env.PP_PORTAL_BASE_URL}/personal-account/my-details`,
    "/help" : `${process.env.PP_PORTAL_BASE_URL}/help`,
    "/outgoing/evidence-tracker" : `${process.env.PP_PORTAL_BASE_URL}/professional-development/`,
    "/outgoing/practising-certificate-renewal" : `${process.env.PP_PORTAL_BASE_URL}`,
    "/outgoing/international-practising-certificate-renewal" : `${process.env.PP_PORTAL_BASE_URL}`,
    "/outgoing/foundations-in-professionalism" : `${process.env.PP_PORTAL_BASE_URL}/qualifications`,
};

export default MEMBER_REDIRECTS;
