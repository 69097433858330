import React, { Suspense, lazy } from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import ROUTES from "constants/routes";
import withErrorHandling from "containers/withErrorHandling";
import PageContentLoader from "components/PageContentLoader/PageContentLoader";
import CommunicationPreferencesPage from "pages/CommunicationPreferencesPage/CommunicationPreferencesPage";
import SuspensionPage from "pages/SuspensionPage/SuspensionPage";
import FipHandoffPage from "pages/Outgoing/FipHandoffPage/FipHandoffPage";
import EvidenceTrackerHandoffPage from "pages/Outgoing/EvidenceTrackerHandoffPage/EvidenceTrackerHandoffPage";
import PracticingCertificateRenewalHandoffPage from "pages/Outgoing/PracticingCertificateRenewalHandoffPage/PracticingCertificateRenewalHandoffPage";
import InternationalPracticingCertificateHandoffPage from "pages/Outgoing/InternationalPracticingCertificateHandoffPage/InternationalPracticingCertificateHandoffPage";
import QualificationsPage from "pages/QualificationsPage/QualificationsPage";
import ProfessionalDevelopmentPage from "pages/ProfessionalDevelopmentPage/ProfessionalDevelopmentPage";
import MyDetailsPage from "../MyDetailsPage/MyDetailsPage";
import AccountDetailsPage from "../AccountDetailsPage/AccountDetailsPage";
import LoginDetailsPage from "../LoginDetailsPage/LoginDetailsPage";
import FeesPage from "../FeesPage/FeesPage";
import DocsPage from "../DocDownloadsPage/DocDownloadsPage";
import PageNotFoundPage from "../PageNotFoundPage/PageNotFoundPage";
import HelpPage from "../HelpPage/HelpPage";
import EmploymentPage from "../EmploymentPage/EmploymentPage";

const LandingPage = lazy(() =>
  import(/* webpackChunkName: "LandingPage" */ "pages/LandingPage/LandingPage")
);

const ContactDetailsPage = lazy(() =>
  import(/* webpackChunkName: "ContactDetailsPage" */ "pages/ContactDetailsPage/ContactDetailsPage")
);

const CpdPage = lazy(() => import(/* webpackChunkName: "CpdPage" */ "pages/CpdPage/CpdPage"));

const ApplyForMembershipPage = lazy(() =>
  import(
    /* webpackChunkName: "ApplyForMembershipPage" */ "pages/ApplyForMembershipPage/ApplyForMembershipPage"
  )
);

const RegisterOfRetiredMembersPage = lazy(() =>
  import(
    /* webpackChunkName: "RegisterOfRetiredMembersPage" */ "pages/RegisterOfRetiredMembersPage/RegisterOfRetiredMembersPage"
  )
);

const pages = [
  {
    path: ROUTES.landingPage,
    component: LandingPage,
    exact: true,
  },
  {
    path: ROUTES.professionalDevelopmentPage,
    component: ProfessionalDevelopmentPage,
    exact: true,
    allowWhenSuspended: true,
  },
  {
    path: ROUTES.annualDeclarationsPage,
    component: CpdPage,
    exact: true,
    allowWhenSuspended: true,
  },
  {
    path: ROUTES.examsPage,
    component: QualificationsPage,
    exact: true,
  },
  {
    path: ROUTES.applyForMembershipPage,
    component: ApplyForMembershipPage,
    exact: true,
  },
  {
    path: ROUTES.registerOfRetiredMembers,
    component: RegisterOfRetiredMembersPage,
    exact: true,
  },
  {
    path: ROUTES.accountLanding,
    component: MyDetailsPage,
    exact: true,
  },
  {
    path: ROUTES.accountDetailsPage,
    component: AccountDetailsPage,
    exact: true,
  },
  {
    path: ROUTES.employmentDetailsPage,
    component: EmploymentPage,
    exact: true,
  },
  {
    path: ROUTES.accountContactDetailsPage,
    component: ContactDetailsPage,
    exact: true,
  },
  {
    path: ROUTES.loginDetailsPage,
    component: LoginDetailsPage,
    exact: true,
  },
  {
    path: ROUTES.feesAndTransactionsPage,
    component: FeesPage,
    exact: true,
    allowWhenSuspended: true,
  },
  {
    path: ROUTES.accountCommunicationPreferencesPage,
    component: CommunicationPreferencesPage,
    exact: true,
  },
  {
    path: ROUTES.documentsResourcesPage,
    component: DocsPage,
    exact: true,
  },
  {
    path: ROUTES.helpPage,
    component: HelpPage,
    exact: true,
    allowWhenSuspended: true,
  },
];

export const getComponentForRoute = (
  { component, allowWhenSuspended },
  isSuspended,
  isSuspensionGracePeriod
) => {
  if (!isSuspended) {
    return component;
  }
  if (isSuspensionGracePeriod && allowWhenSuspended) {
    return component;
  }

  return SuspensionPage;
};

const Router = ({ isSuspended, isSuspensionGracePeriod, appPages }) => {
  const renderRoute = route => {
    return (
      <Route
        key={route.path}
        path={route.path}
        component={withErrorHandling(
          getComponentForRoute(route, isSuspended, isSuspensionGracePeriod)
        )}
        exact={route.exact}
      />
    );
  };
  return (
    <Suspense fallback={<PageContentLoader />}>
      <Switch>
        {pages.map(page => renderRoute(page))}
        {appPages && appPages.map(page => renderRoute(page))}
        <Route
          path={ROUTES.outgoingEvidenceTrackerPage}
          render={EvidenceTrackerHandoffPage}
          exact
        />
        <Route
          path={ROUTES.outgoingPracticeCertificateRenewalHandoffPage}
          render={PracticingCertificateRenewalHandoffPage}
          exact
        />
        <Route
          path={ROUTES.outgoingInternationalPracticeCertificateHandoffPage}
          render={InternationalPracticingCertificateHandoffPage}
          exact
        />
        <Route path={ROUTES.outgoingFipHandoffPage} render={FipHandoffPage} exact />
        <Route component={PageNotFoundPage} />
      </Switch>
    </Suspense>
  );
};

Router.propTypes = {
  isSuspended: PropTypes.bool.isRequired,
  isSuspensionGracePeriod: PropTypes.bool,
  appPages: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Router;
