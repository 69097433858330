import { takeLatest, call, put, select } from "redux-saga/effects";
import ApiService from "services/ApiService";
import apiConfig from "configs/apiConfig";
import fetchSaga from "utilities/fetchSaga";
import STEPS from "constants/cpdWaiverRequestSteps";
import { ACTION_TYPES } from "./actions";
import { getAccaId } from "../profile/selectors";
import { getFormValues } from "./selectors";

function* handleSubmitCpdWaiverRequest({ type }) {
  const accaId = yield select(getAccaId);
  const requestBody = yield select(getFormValues);
  yield put({
    type: ACTION_TYPES.SET_STEP,
    step: STEPS.SUBMITTING,
  });

  yield call(
    fetchSaga(ApiService.callApi, apiConfig.endpoints.user.submitCpdWaiverRequest(accaId), type, {
      method: "POST",
      requestBody,
    })
  );
}

export default function* cpdWaiverSagas() {
  yield takeLatest(ACTION_TYPES.SUBMIT_WAIVER, handleSubmitCpdWaiverRequest);
}
