import React, { Fragment } from "react";
import Parser from "html-react-parser";
import PropTypes from "prop-types";
import DOMPurify from "dompurify";

const FieldList = ({ fields }) => {
  const renderField = ({ label, value }, i) => {
    return (
      <Fragment key={i}>
        <dt>{label}</dt>
        <dd>{value && value !== "" ? Parser(DOMPurify.sanitize(value)) : "-"}</dd>
      </Fragment>
    );
  };

  return <dl>{fields.map(renderField)}</dl>;
};

FieldList.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
};

export default FieldList;
