import EXPERIENCES from "constants/portalExperiences";
import { getPortalExperience } from "state/profile/selectors";
import { getCpdDeclarationOverview } from "../profile/selectors";
import STATUS from "../../constants/cpdDeclarationStatus";

export const getKeyResources = state => state.documents.keyResources;
export const getPractisingCertificates = state => state.documents.practisingCertificates;
export const getCanDownloadMembershipCertificate = state =>
  getPortalExperience(state) === EXPERIENCES.MEMBER &&
  !!state.documents?.keyResources?.membershipCertificate;

export const isOfGoodStanding = state => {
  if (
    state.documents &&
    state.documents.keyResources &&
    state.documents.keyResources.goodStanding
  ) {
    return (
      state.documents.keyResources.goodStanding.eligible ||
      (!state.documents.keyResources.goodStanding.eligible &&
        state.documents.keyResources.goodStanding.warning === "practice" &&
        !state.documents.keyResources.certificates.warning?.includes("fees") &&
        getCpdDeclarationOverview(state) !== STATUS.OVERDUE)
    );
  }
  return false;
};
