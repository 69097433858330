import React, { useState } from "react";
import { connect } from "react-redux";
import { Alert, BodyCopy, IconClose16, IconButton } from "acca-design-system";
import "./critical-alert.scss";
import { getCriticalAlertContent } from "state/content/selectors";
import classnames from "classnames";
import { useCookies } from "react-cookie";

import HTMLReactParser from "html-react-parser";

const mapStateToProps = state => ({
  content: getCriticalAlertContent(state),
});

export const CRITICAL_ALERT_CLOSED__COOKIE_KEY = "CRITICAL_ALERT_CLOSED__COOKIE_KEY";

export const CriticalAlert = ({ content }) => {
  if (!content) {
    return null;
  }

  const [showAlert, setShowAlert] = useState(true);
  const [cookies, setCookie] = useCookies();
  if (CRITICAL_ALERT_CLOSED__COOKIE_KEY in cookies) {
    return null;
  }

  const { heading, message } = content;

  const className = classnames("critical-alert", {
    "critical-alert--out": !showAlert,
  });

  return (
    <Alert type="warning" className={className}>
      <BodyCopy>
        <p className="u-font-bold h700">{heading}</p>
        {HTMLReactParser(message)}
      </BodyCopy>
      <IconButton
        size="small"
        icon={<IconClose16 />}
        onClick={() => {
          setShowAlert(false);
          setTimeout(() => {
            setCookie(CRITICAL_ALERT_CLOSED__COOKIE_KEY, true);
          }, 1000);
        }}
        text="Close"
        className="critical-alert__close"
        tabIndex="0"
      />
    </Alert>
  );
};

export default connect(mapStateToProps)(CriticalAlert);
