import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import rollbar from "error-logger";
import { getError } from "state/errors/selectors";
import { getPageFrameworkFailedToLoad } from "state/content/selectors";
import ErrorPage from "pages/ErrorPage/ErrorPage";
import { getIsUserLoaded } from "state/profile/selectors";
import PageContentLoader from "components/PageContentLoader/PageContentLoader";

const mapStateToProps = state => ({
  appError: getError(state),
  failedToLoadPageFramework: getPageFrameworkFailedToLoad(state),
  isUserLoaded: getIsUserLoaded(state),
});

const withErrorHandling = WrappedComponent => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }

    static getDerivedStateFromError(e) {
      rollbar.error(e);
      return { hasError: true };
    }

    render() {
      const { appError, failedToLoadPageFramework, isUserLoaded } = this.props;
      const { hasError } = this.state;

      if (appError || hasError) {
        return <ErrorPage error={appError} isTakeOver={failedToLoadPageFramework} />;
      }
      if (!isUserLoaded) {
        return <PageContentLoader />;
      }
      return <WrappedComponent />;
    }
  };
};

export default compose(connect(mapStateToProps), withErrorHandling);
