import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  getIsQualificationsLoaded,
  getQualifications,
  getHasQualificationsError,
} from "state/qualifications/selectors";
import { getPathway, getPortalExperience } from "state/profile/selectors";

import { getQualificationsContent } from "state/content/selectors";

import AsyncErrorPanel from "components/AsyncErrorPanel/AsyncErrorPanel";
import PreloaderPanel from "components/PreloaderPanel/PreloaderPanel";
import EXPERIENCES from "constants/portalExperiences";
import ExamProgressCard from "./ExamProgressCard";
import EthicsProgressCard from "./EthicsProgressCard";
import ExperienceProgressCard from "./ExperienceProgressCard";

const mapStateToProps = state => ({
  isQualificationsLoaded: getIsQualificationsLoaded(state),
  qualifications: getQualifications(state),
  content: getQualificationsContent(state),
  pathway: getPathway(state),
  portalExperience: getPortalExperience(state),
  hasError: getHasQualificationsError(state),
});

const ProgressCards = ({
  isQualificationsLoaded,
  content,
  pathway,
  qualifications,
  hasError,
  portalExperience,
}) => {
  if (hasError) {
    return <AsyncErrorPanel heading="We couldn’t load your qualification information right now." />;
  }
  if (!isQualificationsLoaded) {
    return <PreloaderPanel heading="We're looking for your qualification information" />;
  }

  const { exams, ethics, practicalExperience } = content;
  const isAffiliate = portalExperience === EXPERIENCES.AFFILIATE;

  return (
    <>
      {isAffiliate && (
        <>
          <ExperienceProgressCard content={practicalExperience} qualifications={qualifications} />
          <ExamProgressCard content={exams} pathway={pathway} qualifications={qualifications} />
          <EthicsProgressCard content={ethics} qualifications={qualifications} />
        </>
      )}
      {!isAffiliate && (
        <>
          <ExamProgressCard content={exams} pathway={pathway} qualifications={qualifications} />
          <EthicsProgressCard content={ethics} qualifications={qualifications} />
          <ExperienceProgressCard content={practicalExperience} qualifications={qualifications} />
        </>
      )}
    </>
  );
};

ProgressCards.propTypes = {
  hasError: PropTypes.bool,
  pathway: PropTypes.string.isRequired,
  qualifications: PropTypes.shape({}),
  isQualificationsLoaded: PropTypes.bool,
  content: PropTypes.shape({
    exams: PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    ethics: PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    practicalExperience: PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default connect(mapStateToProps)(ProgressCards);
