import React, { Fragment } from "react";
import BrowserTitle from "components/BrowserTitle/BrowserTitle";
import AppMessage from "components/AppMessage/AppMessage";
import illustrationSrc from "assets/images/illustrations/account-lock.svg";
import { Button } from "acca-design-system";
import AuthService from "services/AuthService";
import content from "./UnsupportedPage.content";

const UnsupportedPage = () => {
  const { title, message, buttonText, buttonTo } = content;
  return (
    <Fragment>
      <BrowserTitle content={content} />
      <AppMessage
        className="u-bg-white"
        title={title}
        message={message}
        illustrationSrc={illustrationSrc}
        buttons={[{ href: buttonTo, text: buttonText }]}
        footer={<Button variant="link" onClick={AuthService.logout} text="Logout" />}
      />
    </Fragment>
  );
};

export default UnsupportedPage;
