import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { PageHeader } from "acca-design-system";

import PageContentLoader from "components/PageContentLoader/PageContentLoader";
import { getFeesContent } from "state/content/selectors";
import { fetchFeesContent } from "state/content/actions";
import BrowserTitle from "components/BrowserTitle/BrowserTitle";
import Container from "components/Container/Container";
import AdditionalLinks from "components/AdditionalLinks/AdditionalLinks";

import {
  getIsEligibleForReducedSubs,
  getHasAppliedForReducedSubs,
  getIsNotificationsLoaded,
  getNotificationsHasError,
} from "state/profile/selectors";
import { handleError } from "state/errors/actions";
import FeeLinkCard from "./components/FeeLinkCard/FeeLinkCard";

const mapStateToProps = state => ({
  content: getFeesContent(state),
  isEligibleForReducedSubs: getIsEligibleForReducedSubs(state),
  hasAppliedForReducedSubs: getHasAppliedForReducedSubs(state),
  isNotificationsLoaded: getIsNotificationsLoaded(state),
  notificationsHasError: getNotificationsHasError(state),
});

const mapDispatchToProps = dispatch => ({
  loadContent: () => dispatch(fetchFeesContent()),
  setAppError: error => dispatch(handleError(error)),
});

const FeesPage = ({
  content,
  loadContent,
  isEligibleForReducedSubs,
  hasAppliedForReducedSubs,
  isNotificationsLoaded,
  setAppError,
  notificationsHasError,
}) => {
  useEffect(() => {
    if (!content) {
      loadContent();
    }
  }, []);

  useEffect(() => {
    if (notificationsHasError) {
      setAppError({ response: { status: "notificationsError" } });
    }
  }, [notificationsHasError]);

  const pageReady = !!content && !!isNotificationsLoaded;

  if (!pageReady) {
    return <PageContentLoader />;
  }

  const { heading, quote, title, card, footer, links } = content;
  const ltaSubscription = isEligibleForReducedSubs && content.ltaSubscription;
  const ltaExistingSubsApplication = hasAppliedForReducedSubs && content.ltaSubscription;

  return (
    <Fragment>
      <BrowserTitle content={content} />
      <PageHeader className="u-margin-bottom-2" pageTitle={heading} quote={quote} />

      <Container className="section">
        <div className="cell">
          <h3 className="section-title">{title}</h3>
          <FeeLinkCard card={card} />
          {ltaSubscription && (
            <>
              <h3 className="section-title">{ltaSubscription.standard.title}</h3>
              <FeeLinkCard card={ltaSubscription.standard} />
            </>
          )}

          {ltaExistingSubsApplication && (
            <>
              <h3 className="section-title">
                {ltaExistingSubsApplication.subscriptionReceived.title}
              </h3>
              <FeeLinkCard card={ltaExistingSubsApplication.subscriptionReceived} />
            </>
          )}
        </div>
      </Container>

      {links && <AdditionalLinks heading={footer} links={links} />}
    </Fragment>
  );
};

FeesPage.propTypes = {
  notificationsHasError: PropTypes.bool,
  isNotificationsLoaded: PropTypes.bool.isRequired,
  setAppError: PropTypes.func,
  isEligibleForReducedSubs: PropTypes.bool.isRequired,
  hasAppliedForReducedSubs: PropTypes.bool.isRequired,
  content: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    quote: PropTypes.string,
    title: PropTypes.string,
    card: PropTypes.shape({
      text: PropTypes.string,
      link: PropTypes.shape({
        icon: PropTypes.string,
        text: PropTypes.string,
        href: PropTypes.string,
      }),
    }),
    ltaSubscription: PropTypes.shape({
      standard: {
        title: PropTypes.string,
        text: PropTypes.string,
        link: PropTypes.shape({
          icon: PropTypes.string,
          text: PropTypes.string,
          href: PropTypes.string,
        }),
      },
      subscriptionReceived: {
        title: PropTypes.string,
        text: PropTypes.string,
      },
    }),
    footer: PropTypes.string,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        link: PropTypes.shape({
          text: PropTypes.string,
          href: PropTypes.string,
        }),
      })
    ),
  }),
  loadContent: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(FeesPage);
