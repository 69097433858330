export const ACTION_TYPES = {
  ERROR: "APP/ERROR",
  RESET_ERRORS: "APP/RESET_ERRORS",
};

export const handleError = error => ({
  type: ACTION_TYPES.ERROR,
  error,
});

export const handleResetErrors = () => ({
  type: ACTION_TYPES.RESET_ERRORS,
});
