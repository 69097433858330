import React, { Fragment } from "react";
import { withFormsy } from "formsy-react";
import PropTypes from "prop-types";
import Parser from "html-react-parser";

import { Alert, BodyCopy, RadioButton, ToggleControlGroup } from "acca-design-system";

class RadioButtonGroup extends React.Component {
  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(event) {
    const { setValue, handleChange } = this.props;
    // setValue() will set the value of the component, which in
    // turn will validate it and the rest of the form
    // Important: Don't skip this step. This pattern is required
    // for Formsy to work.
    setValue(event.currentTarget.value);

    if (handleChange) {
      handleChange(event.currentTarget.value);
    }
  }

  render() {
    const { options, getValue, id, inline, boxed, warning, isValid, getErrorMessage } = this.props;

    const errorMessage = getErrorMessage();

    return (
      <>
        <ToggleControlGroup inline={inline} id={id} name={id}>
          {options.map((option, i) => (
            <RadioButton
              key={i}
              id={option.id}
              name={option.id}
              value={option.value}
              text={option.label}
              checked={option.value === getValue()}
              onChange={this.changeValue}
              boxed={boxed}
            >
              <Fragment>{option.text}</Fragment>
              {option.warn && option.value === getValue() ? (
                <Alert type="warning" className="u-margin-top-1 u-margin-bottom-1">
                  <BodyCopy>{Parser(warning)}</BodyCopy>
                </Alert>
              ) : null}
            </RadioButton>
          ))}
        </ToggleControlGroup>
        {!isValid() && <Alert type="error">{errorMessage}</Alert>}
      </>
    );
  }
}

RadioButtonGroup.propTypes = {
  id: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  inline: PropTypes.bool,
  boxed: PropTypes.bool,
  handleChange: PropTypes.func,
  warning: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  isValid: PropTypes.func,
  getErrorMessage: PropTypes.func,
};

export default withFormsy(RadioButtonGroup);
