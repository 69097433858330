const content = {
  lmsCardText: "ACCA Learning - expert learning resources from ACCA and our partners",
  lmsCardCTAText: "Visit ACCA Learning",
  jesMessage:
    "You have indicated that you currently reside in a country in which ACCA operates a Joint Examination Scheme (JES) with the national accountancy body. You are required to register and maintain your student status with your local national accountancy body (If applicable). ACCA will share your personal data with the national accountancy body including but not limited to Name, Date of Birth, Contact Details, and Exams Results. <a target=\"_blank\" href='https://www.accaglobal.com/gb/en/footer-toolbar/contact-us/jes.html'>Read more information on ACCA’s data privacy policy and Joint Examination Schemes</a>",
  jesMaltaMessage:
    '<p>You have indicated that you currently reside in Malta, a country in which ACCA operates a Joint Examination Scheme (JES) with the national accountancy body, Malta Institute of Accountants (MIA). You are required to register and maintain your student status with MIA. ACCA will share your personal data with the MIA including but not limited to Name, Date of Birth, Contact Details, and Exams Results. <a target="_blank" href="https://www.accaglobal.com/gb/en/footer-toolbar/contact-us/jes.html">Read more information on ACCA’s data privacy policy and Joint Examination Schemes</a>. and also <a target="_blank" href="https://www.miamalta.org/students/registrations">register with MIA</a>.</p>',
};

export default content;
