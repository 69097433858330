import get from "lodash.get";

export const getIsEmploymentLookupLoaded = state => !!state.lookup.employment;
export const getEmploymentLookup = state => state.lookup.employment;

const getNetworksByCategory = (state, scope, category) => {
  const networks = get(state.lookup, scope, null);
  return networks ? networks.filter(network => network.category === category) : null;
};

export const getAllEmploymentNetworks = state =>
  getNetworksByCategory(state, "networks", "employment");
export const getAllRegionalNetworks = state => getNetworksByCategory(state, "networks", "regional");

export const getIsNetworkLookupLoaded = state => !!get(state, "lookup.networks", null);

export const getIsMembershipEmploymentCategoriesLoaded = state =>
  !!get(state, "lookup.membershipEmploymentCategories", null);
export const getMembershipEmploymentCategories = state =>
  state.lookup.membershipEmploymentCategories;
