import PropTypes from "prop-types";
import {
  IconOpenInANewWindow16,
  IconExchange32,
  IconQualification32,
  IconTelephone32,
  IconTimelineAlert32,
  IconMailingAddress32,
  IconSecondaryAddress32,
  IconDocument32,
  IconCertificate32,
  IconArrowLeft16,
  IconArrowRight16,
  IconEmployment32,
  IconEmail32,
  IconPdf32,
  IconAdd16,
  IconOpenInANewWindow24,
  IconUpload16,
  IconPassword32,
  IconTimelineLock32,
} from "acca-design-system";

const IconMap = ({ name }) => {
  const icons = {
    IconOpenInANewWindow16,
    IconExchange32,
    IconQualification32,
    IconTelephone32,
    IconTimelineAlert32,
    IconMailingAddress32,
    IconSecondaryAddress32,
    IconDocument32,
    IconCertificate32,
    IconArrowLeft16,
    IconArrowRight16,
    IconEmployment32,
    IconEmail32,
    IconPdf32,
    IconAdd16,
    IconOpenInANewWindow24,
    IconUpload16,
    IconPassword32,
    IconTimelineLock32,
  };

  return icons[name];
};

IconMap.propTypes = {
  name: PropTypes.string.isRequired,
};

export default IconMap;
