import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import DOMPurify from "dompurify";
import _get from "lodash.get";

import { ReplaceTemplates } from "utilities/Helpers";
import AppMessage from "components/AppMessage/AppMessage";
import errorIllustration from "assets/images/illustrations/error-500-small.svg";
import IconMap from "components/IconMap/IconMap";
import {initPageLoad, trackEvent} from "analytics/trackEvents";
import { BodyCopy, Button } from "acca-design-system";
import AuthService from "services/AuthService";
import content from "./ErrorPage.content";

const ErrorPage = ({ error, isTakeOver }) => {

  useEffect(() => {
    trackEvent({
      category: "Error Page",
      label: _get(error, "response.status", "Unknown Status"),
      action: "ERROR",
    });
  }, []);


  const status =
      error && error.response && content[error.response.status] ? error.response.status : "standard";


  const pageContent = content[status];
  const { preTitle, title, message, buttonText, footer, browserTitle } = pageContent;

  const illustrationSrc = pageContent.illustration && errorIllustration;
  const icon = IconMap({ name: pageContent.icon });
  const buttonIcon = IconMap({ name: pageContent.buttonIcon });

    const config = {
        ALLOWED_TAGS: [],
    };
    const sanitizedMessage = DOMPurify.sanitize(message, config);
    const errorInfo = {
        errorCode: "",
        errorType: browserTitle.message,
        errorDetail: sanitizedMessage,
    };

    initPageLoad({}, errorInfo);

  const renderAppMessage = additionalProps => (
      <AppMessage
          preTitle={ReplaceTemplates(preTitle, { status })}
          title={title}
          message={message}
          icon={icon}
          buttons={
            buttonText
                ? [
                  {
                    text: buttonText,
                    onClick: () => window.location.reload(),
                    buttonIcon,
                    iconPosition: "right",
                  },
                ]
                : null
          }
          {...additionalProps}
          footer={
              footer && (
                  <Fragment>
                    {footer.title && (
                        <h3 className="h700 u-font-heading u-margin-bottom-1">{footer.title}</h3>
                    )}
                    {footer.message && (
                        <Fragment>
                          <BodyCopy>
                            {footer.message}{" "}
                            {footer.link && (
                                <strong>
                                  <a
                                      className="u-black"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={footer.link.href}
                                  >
                                    {footer.link.text}
                                  </a>
                                </strong>
                            )}
                            .
                          </BodyCopy>
                          {isTakeOver && (
                              <BodyCopy>
                                <Button variant="link" onClick={AuthService.logout} text="Logout" />
                              </BodyCopy>
                          )}
                        </Fragment>
                    )}
                  </Fragment>
              )
          }
          illustrationSrc={illustrationSrc}
          onClick={() => {
            window.location.reload();
          }}
      />
  );

  const renderPageLayout = () => {
    if (isTakeOver) {
      return renderAppMessage({ className: "u-bg-white" });
    }
    return renderAppMessage();
  };

  return <Fragment>{renderPageLayout()}</Fragment>;
};

ErrorPage.propTypes = {
  error: PropTypes.shape({
    response: PropTypes.shape({
      status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
  isTakeOver: PropTypes.bool,
};

export default ErrorPage;
