const EXPERIENCES = {
  MEMBER: "MEMBER",
  STUDENT: "STUDENT",
  AFFILIATE: "AFFILIATE",
  GRADUATE: "GRADUATE",
  FIRM: "FIRM",
  LEGACY: "LEGACY",
  UNSUPPORTED: "UNSUPPORTED",
};
export default EXPERIENCES;
