import React from "react";
import PropTypes from "prop-types";
import Parser from "html-react-parser";

import { Doughnut, BodyCopy } from "acca-design-system";

import QualificationsCard from "../QualificationsCard/QualificationsCard";

const EthicsProgressCard = ({ content, qualifications }) => {
  const { moduleStatus } = qualifications;

  const ethicsContent = content && content.content.find(({ key }) => key === moduleStatus);
  const progress = moduleStatus && moduleStatus.indexOf("NOT_") === -1 ? 1 : 0;
  const unitOfMeasurement = "MODULE";

  const doughnut = () => (
    <Doughnut progress={progress * 100} size="medium" className="u-tangerine">
      <p>
        <span className="h400 u-font-heading">{progress}</span>/1 <br />
        <span className="u-grey">{unitOfMeasurement}</span>
      </p>
    </Doughnut>
  );

  return (
    <>
      {ethicsContent && (
        <QualificationsCard
          sectionTitle={content.title}
          title={ethicsContent.title}
          links={ethicsContent.links}
          doughnut={doughnut()}
        >
          {ethicsContent.message && <BodyCopy>{Parser(ethicsContent.message)}</BodyCopy>}
        </QualificationsCard>
      )}
    </>
  );
};

EthicsProgressCard.propTypes = {
  qualifications: PropTypes.shape({
    ethics: PropTypes.shape({ status: PropTypes.string }),
    moduleStatus: PropTypes.string,
  }),
  content: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default EthicsProgressCard;
