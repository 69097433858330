export const ACTION_TYPES = {
  FETCH_ACCOUNT_DETAILS: "ACCOUNTDETAILS/FETCH_ACCOUNT_DETAILS",
  FETCH_ACCOUNT_DETAILS_SUCCESS: "ACCOUNTDETAILS/FETCH_ACCOUNT_DETAILS_SUCCESS",
  FETCH_ACCOUNT_DETAILS_ERROR: "ACCOUNTDETAILS/FETCH_ACCOUNT_DETAILS_ERROR",

  SUBMIT_ACCOUNT_DETAILS: "ACCOUNTDETAILS/SUBMIT",
  SUBMIT_ACCOUNT_DETAILS_SUCCESS: "ACCOUNTDETAILS/SUBMIT_SUCCESS",
  SUBMIT_ACCOUNT_DETAILS_ERROR: "ACCOUNTDETAILS/SUBMIT_ERROR",

  TOGGLE_IS_READ_ONLY: "ACCOUNTDETAILS/TOGGLE_IS_READ_ONLY",

  UPDATED_OXFORD_BROOKES: "ACCOUNTDETAILS/UPDATED_OXFORD_BROOKES",
  UPDATED_TRANSFER_OPTION: "ACCOUNTDETAILS/UPDATED_TRANSFER_OPTION",
};

export const fetchAccountDetails = () => ({
  type: ACTION_TYPES.FETCH_ACCOUNT_DETAILS,
});

export const submitAccountDetails = (payload, promise) => ({
  type: ACTION_TYPES.SUBMIT_ACCOUNT_DETAILS,
  payload,
  promise,
});

export const toggleReadOnly = (isReadOnly, sectionName) => ({
  type: ACTION_TYPES.TOGGLE_IS_READ_ONLY,
  sectionName,
  isReadOnly,
});

export const updatedOxfordBrookes = value => ({
  type: ACTION_TYPES.UPDATED_OXFORD_BROOKES,
  value,
});

export const updatedTransferOption = label => ({
  type: ACTION_TYPES.UPDATED_TRANSFER_OPTION,
  label,
});
