import Rollbar from "rollbar";

const isRollbarEnabled = process.env.ENV !== "dev" && process.env.ROLLBAR_CLIENT_ACCESS_TOKEN;

const rollbar = new Rollbar(
  isRollbarEnabled
    ? {
        accessToken: process.env.ROLLBAR_CLIENT_ACCESS_TOKEN,
        enabled: true,
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
          environment: process.env.ENV,
          client: {
            javascript: {
              source_map_enabled: true,
              code_version: GIT_REVISION,
              // Optionally have Rollbar guess which frames the error was thrown from
              // when the browser does not provide line and column numbers.
              guess_uncaught_frames: true,
            },
          },
        },
      }
    : { enabled: false }
);

export default rollbar;
