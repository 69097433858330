import get from "lodash.get";
import head from "lodash.head";
import {
  getCountryOfResidence,
  getPortalExperience,
  getUserType,
  getIsOnlineCountry,
} from "state/profile/selectors";
import EXPERIENCES from "constants/portalExperiences";

export const getIsConsentsLoaded = state => !!state.communicationPreferences.consents;

export const getUserHasEmailAddress = state =>
  !!get(state, "communicationPreferences.primaryContactMechanisms.email", null);

export const getUserHasMobile = state =>
  !!get(state, "communicationPreferences.primaryContactMechanisms.mobile", null);

export const getQuestionState = (state, questionId) => {
  const consents = get(state, "communicationPreferences.consents", {});
  return questionId in consents
    ? consents[questionId]
    : {
        value: false,
        loading: true,
      };
};

export const getImportantUpdatesByEmail = state =>
  getQuestionState(state, "importantUpdatesByEmail");

export const getDisableUpdates = (questionId, state) => {
  switch (questionId) {
    case "importantUpdatesByEmail":
      if (getUserType(state) === "student" && getIsOnlineCountry(state)) {
        return true;
      }
      return false;
    default:
      return false;
  }
};

export const showQuestion = (state, questionId) => {
  switch (questionId) {
    case "abMagazine":
      return state.profile.countryOfResidence !== "Malaysia";
    case "accountCorrespondenceByEmail": {
      const hasEmail = getUserHasEmailAddress(state);
      if (!hasEmail) {
        return false;
      }
      const iube = getImportantUpdatesByEmail(state);
      if (!iube.value) {
        return false;
      }
      if (iube.loading) {
        return false;
      }
      if (getUserType(state) === "student" && getIsOnlineCountry(state)) {
        return false;
      }
      return true;
    }
    default:
      return true;
  }
};

export const getShowMemberNetworksQuestions = state => {
  if (getPortalExperience(state) !== EXPERIENCES.MEMBER) return false;
  const countryOfResidence = getCountryOfResidence(state);
  return ["United Kingdom", "Ireland"].includes(countryOfResidence);
};

const getNetworksByCategory = (state, scope, category) => {
  const networks = get(state.communicationPreferences, scope, null);
  return networks ? networks.filter(network => network.category === category) : null;
};

export const getUserEmploymentNetworks = state =>
  getNetworksByCategory(state, "userNetworks", "employment");
export const getUserRegionalNetwork = state => {
  const result = getNetworksByCategory(state, "userNetworks", "regional");
  return head(result);
};

export const getIsUserNetworksLoaded = state =>
  !!get(state.communicationPreferences, "userNetworks", null);

export const getIsNetworksSubmitting = state => state.communicationPreferences.isNetworksSubmitting;

export const getIsUserNetworksReloading = state =>
  state.communicationPreferences.userNetworksReloading === true;
