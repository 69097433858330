import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import IconMap from "components/IconMap/IconMap";
import DownloadCard from "components/DownloadCard/DownloadCard";
import { fetchMembershipCertificate } from "state/docDownloads/actions";
import DocLinkCard from "../DocLinkCard/DocLinkCard";

const mapDispatchToProps = dispatch => ({
  downloadPdf: (certificateNumber, certificateType) =>
    dispatch(fetchMembershipCertificate(certificateNumber, certificateType)),
});

const MembershipCertificateDownloadCard = ({ downloadPdf, membershipCertificate, content }) => {
  if (!membershipCertificate.eligible) {
    return <DocLinkCard content={content} document={membershipCertificate} />;
  }

  const { certificateNumber, certificateType, isLoading, hasDownloadError } = membershipCertificate;
  const { heading, message, link } = content;
  const icon = IconMap({ name: link.icon });
  return (
    <DownloadCard
      isLoading={!!isLoading}
      icon={icon}
      hasError={!!hasDownloadError}
      fetchDownload={() => downloadPdf(certificateNumber, certificateType)}
      heading={heading}
      message={message}
    />
  );
};

MembershipCertificateDownloadCard.propTypes = {
  membershipCertificate: PropTypes.shape({
    eligible: PropTypes.bool.isRequired,
    certificateNumber: PropTypes.string.isRequired,
    certificateType: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    hasDownloadError: PropTypes.bool,
  }).isRequired,
  content: PropTypes.shape({
    heading: PropTypes.string,
    message: PropTypes.string,
    link: PropTypes.shape({
      icon: PropTypes.string,
    }),
  }).isRequired,
  downloadPdf: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(MembershipCertificateDownloadCard);
