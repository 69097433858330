const config = {
  REDIRECT_URI: `${document.location.origin}/callback.html`,
  SILENT_RENEW_URI: `${document.location.origin}/silent-renew.html`,
  POST_LOGOUT_REDIRECT_URI: process.env.AUTH_POST_LOGOUT_REDIRECT_URI,
  AUTHORITY: process.env.AUTH_AUTHORITY,
  END_SESSION_ENDPOINT: process.env.AUTH_END_SESSION_ENDPOINT,
  CLIENT_ID: process.env.AUTH_CLIENT_ID,
};

export default Object.assign(config, process.env.AUTH_SCOPE && { SCOPE: process.env.AUTH_SCOPE });
