import _get from "lodash.get";
import { ACTION_TYPES } from "./actions";
import { ACTION_TYPES as CPD_DECLARATION_ACTION_TYPES } from "../cpd-declaration/actions";

export const initialState = {
  isUserLoaded: false,
  showModal: false,
  isNotificationsLoading: false,
};

const transformSuspension = ({ status, suspensionDetails }) => {
  return {
    isSuspended: status === "SUSPENDED",
    isSuspensionGracePeriod: _get(suspensionDetails, "gracePeriod", false),
    suspensionReasonCode: _get(suspensionDetails, "code", null),
    remainingGracePeriodDays: _get(suspensionDetails, "remainingDays", 0),
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_ACCA_ID:
      return {
        ...state,
        accaId: action.accaId,
      };
    case ACTION_TYPES.FETCH_USER_PROFILE_SUCCESS: {
      const { profile } = action.payload;
      const {
        name,
        fullname,
        firstname,
        surname,
        customerType,
        initials,
        perSupervisor,
        countryOfResidence,
        onlineCountry,
        portalExperience,
        pathway,
        pathwayDescription,
        isRetired,
      } = profile;
      return {
        ...state,
        isUserLoaded: true,
        name: fullname || name,
        firstName: firstname,
        surname,
        userType: customerType.toLowerCase(),
        initials,
        perSupervisor,
        countryOfResidence,
        onlineCountry,
        portalExperience,
        pathway,
        pathwayDescription,
        isRetired,
        ...transformSuspension(profile),
      };
    }
    case ACTION_TYPES.FETCH_USER_PROFILE_ERROR: {
      return {
        ...state,
        error: true,
      };
    }
    case ACTION_TYPES.FETCH_ACCOUNT_BALANCE:
      return {
        ...state,
        accountBalanceError: false,
      };
    case ACTION_TYPES.FETCH_ACCOUNT_BALANCE_SUCCESS:
      return {
        ...state,
        accountBalance: action.payload.accountBalance,
        accountBalanceError: false,
      };
    case ACTION_TYPES.FETCH_ACCOUNT_BALANCE_ERROR:
      return {
        ...state,
        accountBalanceError: true,
      };
    case ACTION_TYPES.FETCH_CPD_DECLARATION_OVERVIEW:
      return {
        ...state,
        cpdDeclarationOverviewError: false,
      };
    case ACTION_TYPES.FETCH_CPD_DECLARATION_OVERVIEW_SUCCESS:
      return {
        ...state,
        cpdDeclarationOverview: action.payload.declarationStatus,
        cpdDeclarationOverviewError: false,
      };
    case ACTION_TYPES.FETCH_CPD_DECLARATION_OVERVIEW_ERROR:
      return {
        ...state,
        cpdDeclarationOverviewError: true,
      };
    case CPD_DECLARATION_ACTION_TYPES.SUBMIT_SUCCESS:
      return {
        ...state,
        cpdDeclarationOverview: null,
      };
    case ACTION_TYPES.FETCH_NOTIFICATIONS:
      return {
        ...state,
        notifications: null,
        badges: null,
        isNotificationsLoading: true,
      };
    case ACTION_TYPES.FETCH_NOTIFICATIONS_SUCCESS: {
      const badges = [];

      action.payload.notifications.forEach(notification => {
        /** Exception to the rule.
         * PD don't want a CPD_REVIEW badge
         * https://accaitdev.atlassian.net/browse/DII-2032
         */
        if (notification === "CPD_REVIEW") {
          return;
        }

        const key = notification.substring(0, 3);
        const exists = badges.find(b => b.key === key);

        if (exists) {
          exists.count += 1;
        } else {
          badges.push({ key, count: 1 });
        }
      });

      return {
        ...state,
        notifications: action.payload.notifications,
        badges,
        notificationsError: false,
        isNotificationsLoading: false,
      };
    }
    case ACTION_TYPES.FETCH_NOTIFICATIONS_ERROR:
      return {
        ...state,
        notifications: [],
        badges: [],
        notificationsError: true,
        isNotificationsLoading: false,
      };
    case ACTION_TYPES.SHOW_MODAL:
      return {
        ...state,
        showModal: true,
      };
    case ACTION_TYPES.CLOSE_MODAL:
      return {
        ...state,
        showModal: false,
      };
    case ACTION_TYPES.FETCH_SESSION_ID_SUCCESS:
      return {
        ...state,
        sessionId: action.payload.sessionId,
      };
    case ACTION_TYPES.FETCH_ENCRYPTED_USERID_SUCCESS:
      return {
        ...state,
        encryptedUserId: action.payload.encryptId,
      };
    default:
      return state;
  }
};

export default reducer;
