import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Parser from "html-react-parser";

import { PageHeader, Button, Card, IconOpenInANewWindow16 } from "acca-design-system";

import { getLoginDetailsContent } from "state/content/selectors";
import { fetchLoginDetailsContent } from "state/content/actions";

import BrowserTitle from "components/BrowserTitle/BrowserTitle";
import Container from "components/Container/Container";
import PageContentLoader from "components/PageContentLoader/PageContentLoader";
import IconMap from "components/IconMap/IconMap";

const mapStateToProps = state => ({
  content: getLoginDetailsContent(state),
});

const mapDispatchToProps = dispatch => ({
  loadContent: () => dispatch(fetchLoginDetailsContent()),
});

const LoginDetailsPage = ({ content, loadContent }) => {
  useEffect(() => {
    if (!content) {
      loadContent();
    }
  }, []);

  const isPageReady = !!content;

  if (!isPageReady) {
    return <PageContentLoader />;
  }

  const { heading, quote, card } = content;

  return (
    <Fragment>
      <BrowserTitle content={content} />
      <PageHeader className="u-margin-bottom-2" pageTitle={heading} quote={quote} />
      <Container className="section">
        <div className="cell">
          <Card icon={IconMap({ name: card.icon })} iconSize="large">
            <div className="u-content-container-medium">
              <h3 className="section-title">{Parser(card.heading)}</h3>
              {card.link && (
                <Button
                  text={card.link.text}
                  variant="outlined"
                  to={card.link.href}
                  icon={IconOpenInANewWindow16}
                />
              )}
            </div>
          </Card>
        </div>
      </Container>
    </Fragment>
  );
};

LoginDetailsPage.propTypes = {
  content: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    quote: PropTypes.string.isRequired,
    card: PropTypes.shape({
      heading: PropTypes.string,
      text: PropTypes.string,
      icon: PropTypes.string,
      link: PropTypes.shape({
        text: PropTypes.string,
        href: PropTypes.string,
      }),
    }),
  }),
  loadContent: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginDetailsPage);
