import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import Parser from "html-react-parser";

import { PageHeader, CardGroup, Card, BodyCopy } from "acca-design-system";

import { getMyDetailsContent } from "state/content/selectors";
import { fetchMyDetailsContent } from "state/content/actions";

import BrowserTitle from "components/BrowserTitle/BrowserTitle";
import Container from "components/Container/Container";
import PageContentLoader from "components/PageContentLoader/PageContentLoader";
import IconMap from "components/IconMap/IconMap";

const mapStateToProps = state => ({
  content: getMyDetailsContent(state),
});

const mapDispatchToProps = dispatch => ({
  loadContent: () => dispatch(fetchMyDetailsContent()),
  goToRoute: route => dispatch(push(route)),
});

const MyDetailsPage = ({ content, loadContent, goToRoute }) => {
  useEffect(() => {
    if (!content) {
      loadContent();
    }
  }, []);

  const isPageReady = !!content;

  if (!isPageReady) {
    return <PageContentLoader />;
  }

  const renderCardGroup = (cardsContent, i) => (
    <CardGroup className="u-margin-bottom-1" key={i}>
      {cardsContent.map(({ icon, heading, message, link }) => {
        const cardIcon = IconMap({ name: icon });
        return (
          <Card
            key={heading}
            icon={cardIcon}
            iconSize="large"
            onClick={() => {
              goToRoute(link.href);
            }}
          >
            <div className="u-content-container-medium">
              <h3 className="section-title">{heading}</h3>
              <BodyCopy>{Parser(message)}</BodyCopy>
            </div>
          </Card>
        );
      })}
    </CardGroup>
  );

  const { pageContent, heading, quote } = content;

  return (
    <Fragment>
      <BrowserTitle content={content} />
      <PageHeader className="u-margin-bottom-2" pageTitle={heading} quote={quote} />
      <Container className="section">
        {pageContent && pageContent.heading && (
          <div className="cell">
            <h3 className="section-title">{pageContent.heading}</h3>
          </div>
        )}
        <div className="cell">{pageContent && pageContent.sections.map(renderCardGroup)}</div>
      </Container>
    </Fragment>
  );
};

MyDetailsPage.propTypes = {
  content: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    quote: PropTypes.string.isRequired,
    pageContent: PropTypes.shape({
      heading: PropTypes.string,
      sections: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
    }),
  }),
  loadContent: PropTypes.func.isRequired,
  goToRoute: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyDetailsPage);
