import get from "lodash.get";
import QUALIFICATION_PATHWAYS from "constants/pathways";
import EXPERIENCES from "constants/portalExperiences";
import { getPortalExperience } from "state/profile/selectors";

export const getIsQualificationsLoaded = state => !!state.qualifications.loaded;

export const getIsQualificationsLoading = state => !!state.qualifications.loading;

export const getQualifications = state => {
  return state.qualifications.qualificationProgress;
};

export const getHasQualificationsError = state => {
  return state.qualifications.hasError;
};

export const getHasCompletedExams = state => {
  const { exams } = getQualifications(state) || {};
  return exams && exams.total === exams.completed;
};

export const getHasCompletedEthics = state => {
  const { moduleStatus } = getQualifications(state) || {};
  return moduleStatus && moduleStatus.indexOf("NOT_") === -1;
};

export const getHasCompletedPER = state => {
  const qualifications = getQualifications(state) || {};
  const objectives = get(qualifications, "practicalExperience.objectives");
  const employment = get(qualifications, "practicalExperience.employment");
  const hasRequiredObjectives =
    objectives &&
    objectives.policyType === QUALIFICATION_PATHWAYS.PER &&
    objectives.totalAchievedObjectives >= objectives.totalRequiredObjectives;
  const hasRequiredMonths = employment && employment.totalAchieved >= employment.experienceRequired;

  return hasRequiredMonths && hasRequiredObjectives;
};

export const getAppliedForMembership = state => {
  const notifications = get(state, "profile.notifications", []);
  return notifications ? notifications.indexOf("APPLIED_FOR_MEMBERSHIP") > -1 : false;
};

export const getMembershipApplicationOnHold = state => {
  const notifications = get(state, "profile.notifications", []);
  return notifications ? notifications.indexOf("MEMBERSHIP_APPLICATION_ONHOLD") > -1 : false;
};

export const getMembershipApplicationComplete = state => {
  const notifications = get(state, "profile.notifications", []);
  return notifications ? notifications.indexOf("MEMBERSHIP_APPLICATION_COMPLETE") > -1 : false;
};

export const getEligibleForMembership = state => {
  const isEligibleUserType = getPortalExperience(state) === EXPERIENCES.AFFILIATE;

  const hasExistingApplicationStatus =
    getAppliedForMembership(state) ||
    getMembershipApplicationOnHold(state) ||
    getMembershipApplicationComplete(state);
  return (
    !hasExistingApplicationStatus &&
    getHasCompletedExams(state) &&
    getHasCompletedEthics(state) &&
    isEligibleUserType
  );
};
