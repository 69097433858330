export const ACTION_TYPES = {
  RESET: "APPLY_FOR_MEMBERSHIP/RESET",
  CLOSE_FORM: "APPLY_FOR_MEMBERSHIP/CLOSE_FORM",
  SET_STEP: "APPLY_FOR_MEMBERSHIP/SET_STEP",
  SUBMIT: "APPLY_FOR_MEMBERSHIP/SUBMIT",
  SUBMIT_SUCCESS: "APPLY_FOR_MEMBERSHIP/SUBMIT_SUCCESS",
  SUBMIT_ERROR: "APPLY_FOR_MEMBERSHIP/SUBMIT_ERROR",
  SET_CONVICTIONS: "APPLY_FOR_MEMBERSHIP/SET_CONVICTIONS",
  SET_PUBLIC: "APPLY_FOR_MEMBERSHIP/SET_PUBLIC",
  SET_PUBLIC_PRACTICE_CONFIRMED: "APPLY_FOR_MEMBERSHIP/SET_PUBLIC_PRACTICE_CONFIRMED",
  SET_CONFIRM_PERMISSION: "APPLY_FOR_MEMBERSHIP/SET_CONFIRM_PERMISSION",
  SET_EMPLOYMENT_CATEGORY: "APPLY_FOR_MEMBERSHIP/SET_EMPLOYMENT_CATEGORY",
  SET_BUSINESS_CATEGORY: "APPLY_FOR_MEMBERSHIP/SET_BUSINESS_CATEGORY",
  SET_PROFESSIONAL_QUALIFICATIONS: "APPLY_FOR_MEMBERSHIP/SET_PROFESSIONAL_QUALIFICATIONS",
  SET_INCLUDE_IN_DIRECTORY_OF_MEMBERS: "APPLY_FOR_MEMBERSHIP/SET_INCLUDE_IN_DIRECTORY_OF_MEMBERS",
  SET_EMPLOYMENT_NETWORKS: "APPLY_FOR_MEMBERSHIP/SET_EMPLOYMENT_NETWORKS",
  SET_REGIONAL_NETWORK: "APPLY_FOR_MEMBERSHIP/SET_REGIONAL_NETWORK",
  SET_DECLARATION_ACCEPTED: "APPLY_FOR_MEMBERSHIP/SET_DECLARATION_ACCEPTED",
  SET_ORGANISATION_SIZE: "APPLY_FOR_MEMBERSHIP/SET_ORGANISATION_SIZE",
  SET_JOB_TITLE: "APPLY_FOR_MEMBERSHIP/SET_JOB_TITLE",
  SET_COMPANY_NAME: "APPLY_FOR_MEMBERSHIP/SET_COMPANY_NAME",
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const setStep = step => ({
  type: ACTION_TYPES.SET_STEP,
  step,
});

export const closeForm = () => ({
  type: ACTION_TYPES.CLOSE_FORM,
});

export const submit = () => ({
  type: ACTION_TYPES.SUBMIT,
});

export const setConvictions = value => ({
  type: ACTION_TYPES.SET_CONVICTIONS,
  value,
});

export const setPublicPractice = value => ({
  type: ACTION_TYPES.SET_PUBLIC,
  value,
});

export const setPublicPracticeConfirmed = value => ({
  type: ACTION_TYPES.SET_PUBLIC_PRACTICE_CONFIRMED,
  value,
});

export const setConfirmedPermission = value => ({
  type: ACTION_TYPES.SET_CONFIRM_PERMISSION,
  value,
});

export const setEmploymentCategory = value => ({
  type: ACTION_TYPES.SET_EMPLOYMENT_CATEGORY,
  value,
});

export const setBusinessCategory = value => ({
  type: ACTION_TYPES.SET_BUSINESS_CATEGORY,
  value,
});

export const setProfessionalQualifications = value => ({
  type: ACTION_TYPES.SET_PROFESSIONAL_QUALIFICATIONS,
  value,
});

export const setIncludeInDirectoryOfMembers = value => ({
  type: ACTION_TYPES.SET_INCLUDE_IN_DIRECTORY_OF_MEMBERS,
  value,
});

export const setRegionalNetwork = value => ({
  type: ACTION_TYPES.SET_REGIONAL_NETWORK,
  value,
});

export const setEmploymentNetworks = value => ({
  type: ACTION_TYPES.SET_EMPLOYMENT_NETWORKS,
  value,
});

export const setDeclarationAccepted = value => ({
  type: ACTION_TYPES.SET_DECLARATION_ACCEPTED,
  value,
});

export const setOrganisationSize = value => ({
  type: ACTION_TYPES.SET_ORGANISATION_SIZE,
  value,
});

export const setJobTitle = value => ({
  type: ACTION_TYPES.SET_JOB_TITLE,
  value,
});

export const setCompanyName = value => ({
  type: ACTION_TYPES.SET_COMPANY_NAME,
  value,
});
