import React from "react";
import PropTypes from "prop-types";
import Parser from "html-react-parser";
import { Card, BodyCopy } from "acca-design-system";

const RegisterOfRetiredMembersCard = ({ content }) => {
  const { heading, message } = content;

  return (
    <Card>
      <div className="u-content-container-medium">
        <h3 className="section-title">{heading}</h3>
        <BodyCopy className="u-margin-bottom-1">{Parser(message)}</BodyCopy>
      </div>
    </Card>
  );
};

RegisterOfRetiredMembersCard.propTypes = {
  content: PropTypes.shape({
    heading: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
};

export default RegisterOfRetiredMembersCard;
