import { takeLatest, call, select } from "redux-saga/effects";
import ApiService from "services/ApiService";
import apiConfig from "configs/apiConfig";
import fetchSaga from "utilities/fetchSaga";
import { getAccaId } from "state/profile/selectors";
import { getRetirementDate } from "./selectors";
import { ACTION_TYPES } from "./actions";

export function* handleSubmitApplication({ type }) {
  const accaId = yield select(getAccaId);
  const retirementDate = yield select(getRetirementDate);

  yield call(
    fetchSaga(
      ApiService.callApi,
      apiConfig.endpoints.user.submitRetirementApplication(accaId),
      type,
      {
        method: "POST",
        requestBody: {
          retirementDate,
        },
      }
    )
  );
}

export default function* regisiterOfRetiredMembersSaga() {
  yield takeLatest(ACTION_TYPES.SUBMIT, handleSubmitApplication);
}
