import { takeLeading, call, select } from "redux-saga/effects";
import ApiService from "services/ApiService";
import apiConfig from "configs/apiConfig";
import fetchSaga from "utilities/fetchSaga";
import { getAccaId } from "state/profile/selectors";
import { ACTION_TYPES } from "./actions";

function* handleFetchQualifications({ type }) {
  const accaId = yield select(getAccaId);
  yield call(fetchSaga(ApiService.callApi, apiConfig.endpoints.user.qualifications(accaId), type), {
    timeout: 30000,
  });
}

export default function* qualificationsSagas() {
  yield takeLeading(ACTION_TYPES.FETCH_QUALIFICATIONS, handleFetchQualifications);
}
