import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setAccaId, fetchUserProfile } from "state/profile/actions";
import RootPage from "pages/RootPage/RootPage";
import AuthService from "services/AuthService";
import {
  getAccaId,
  getIsPortalExperienceSupported,
  getPortalExperience,
  getNotifications,
  getIsNotificationsLoaded,
  getNotificationsHasError,
} from "state/profile/selectors";

import { getLoginStatus } from "state/login/selectors";
import { loginSuccess } from "state/login/actions";

import { fetchQualifications } from "state/qualifications/actions";
import {
  getIsQualificationsLoaded,
  getQualifications,
  getHasQualificationsError,
} from "state/qualifications/selectors";
import UnsupportedPage from "pages/UnsupportedPage/UnsupportedPage";
import ErrorPage from "pages/ErrorPage/ErrorPage";
import rollbar from "error-logger";
import { formatAccaStatus, getFormattedUserDetails } from "analytics/utils";
import PageContentLoader from "../PageContentLoader/PageContentLoader";
import "styles/app.scss";
import { initUserData, pushDataLayerLoginCompletedEvent } from "../../analytics/trackEvents";

export const getAuthGroups = user => {
  if (!user?.profile?.usergroups) {
    return [];
  }

  const groupsConfig = Array.isArray(user.profile.usergroups)
    ? user.profile.usergroups
    : [user.profile.usergroups];

  const groups = groupsConfig.map(group => {
    const cn = group
      .split(",")
      .map(pair => pair.split("="))
      .find(pair => pair[0] === "cn");

    return cn?.length === 2 ? cn[1] : null;
  });

  return groups.filter(x => !!x);
};

const mapStateToProps = state => ({
  accaId: getAccaId(state),
  isExperienceSupported: getIsPortalExperienceSupported(state),
  portalExperience: getPortalExperience(state),
  notifications: getNotifications(state),
  isNotificationsLoaded: getIsNotificationsLoaded(state),
  hasNotificationsError: getNotificationsHasError(state),
  isQualificationsLoaded: getIsQualificationsLoaded(state),
  qualifications: getQualifications(state),
  hasQualificationsError: getHasQualificationsError(state),
  hasError: getHasQualificationsError(state),
  isLoggedIn: getLoginStatus(state),
});

const mapDispatchToProps = dispatch => ({
  setUsersAccaId: accaId => dispatch(setAccaId(accaId)),
  getUserProfile: accaId => dispatch(fetchUserProfile(accaId)),
  loadQualifications: () => dispatch(fetchQualifications()),
  setloginSuccess: () => dispatch(loginSuccess()),
});

class AppInitialiser extends Component {
  constructor(props) {
    super(props);
    this.state = { authError: false, pushedLoggedInEvent: false };
  }

  componentDidMount() {
    const { getUser, userManager, login } = AuthService;
    getUser()
      .then(user => {
        if (!user || user.expired) {
          window.sessionStorage.setItem("returnUrl", window.location.hash);
          login().catch(error => {
            this.setState({ authError: true });
            rollbar.error("Login error", error);
          });
        } else {
          const { setUsersAccaId, getUserProfile, invalidAuthGroupRedirect, membersGroupRedirect } = this.props;

          const authGroups = getAuthGroups(user);
          if (authGroups?.length > 0) {
            const redirectOnInvalidAuthGroup = invalidAuthGroupRedirect(authGroups);
            if (redirectOnInvalidAuthGroup) {
              window.location.href = redirectOnInvalidAuthGroup;
            }
            const memberRedirect = membersGroupRedirect(authGroups, window.location.hash.substring(1));
            if (memberRedirect) {
              window.location = memberRedirect;
            }
          }
          setUsersAccaId(user.profile.sub);
          getUserProfile(user.profile.sub);
          initUserData(user.profile.sub);
        }
      })
      .catch(ex => {
        throw ex;
      });

    userManager.events.addSilentRenewError(() => {
      AuthService.userManager.clearStaleState().then(() => {
        AuthService.userManager.removeUser();
        window.location.replace("/logged-out.html");
      });
    });

    userManager.events.addUserSignedOut(() => {
      userManager.clearStaleState().then(() => {
        userManager.removeUser();
        window.location.replace("/logged-out.html");
      });
    });
  }

  componentDidUpdate(prevProps) {
    const {
      notifications,
      isNotificationsLoaded,
      portalExperience,
      accaId,
      isQualificationsLoaded,
      loadQualifications,
      qualifications,
      isLoggedIn,
      setloginSuccess,
      hasQualificationsError,
      hasNotificationsError,
    } = this.props;

    const { pushedLoggedInEvent } = this.state;

    const shouldLoadQualificationsData = false;

    if (
      !isQualificationsLoaded &&
      isNotificationsLoaded &&
      shouldLoadQualificationsData &&
      !hasQualificationsError
    ) {
      loadQualifications();
    }

    if (
      (isNotificationsLoaded || hasNotificationsError) &&
      portalExperience &&
      !isLoggedIn &&
      !pushedLoggedInEvent &&
      (isQualificationsLoaded ||
        (!isQualificationsLoaded && !shouldLoadQualificationsData) ||
        hasQualificationsError) &&
      (notifications !== prevProps.notifications ||
        portalExperience !== prevProps.portalExperience ||
        qualifications !== prevProps.qualifications ||
        hasNotificationsError !== prevProps.hasNotificationsError ||
        hasQualificationsError !== prevProps.hasQualificationsError)
    ) {
      const {
        notificationsLoginCompletedEventValues,
        qualificationsLoginCompletedEventValues,
      } = getFormattedUserDetails(
        hasNotificationsError,
        hasQualificationsError,
        notifications,
        qualifications,
        portalExperience
      );

      const { feesOwed, cpdStatus } = notificationsLoginCompletedEventValues;
      const { examProgress, perStatus, ethicsStatus } = qualificationsLoginCompletedEventValues;
      const accaStatus = formatAccaStatus(portalExperience);

      pushDataLayerLoginCompletedEvent(
        accaId,
        accaStatus,
        feesOwed,
        examProgress,
        cpdStatus,
        perStatus,
        ethicsStatus
      );
      this.setState({ pushedLoggedInEvent: true });
      sessionStorage.setItem("isLoggedIn", true);
      setloginSuccess();
    }
  }

  componentWillUnmount() {
    AuthService.userManager.events.removeUserSignedOut();
    AuthService.userManager.events.removeSilentRenewError();
  }

  render() {
    const { authError } = this.state;
    if (authError) {
      return <ErrorPage isTakeOver />;
    }
    const { accaId, isExperienceSupported, portalExperience } = this.props;
    const isPageReady = accaId && isExperienceSupported;
    if (!isExperienceSupported) {
      if (portalExperience === "UNSUPPORTED") {
        return <UnsupportedPage />;
      }
      window.location = process.env.UNSUPPORTED_CUSTOMER_REDIRECT;
    }

    return isPageReady ? <RootPage /> : <PageContentLoader />;
  }
}
AppInitialiser.propTypes = {
  getUserProfile: PropTypes.func.isRequired,
  setUsersAccaId: PropTypes.func.isRequired,
  isExperienceSupported: PropTypes.bool.isRequired,
  portalExperience: PropTypes.string,
  accaId: PropTypes.string,
  invalidAuthGroupRedirect: PropTypes.func.isRequired,
  membersGroupRedirect: PropTypes.func.isRequired,
  notifications: PropTypes.arrayOf(PropTypes.string),
  isNotificationsLoaded: PropTypes.bool.isRequired,
  isQualificationsLoaded: PropTypes.bool.isRequired,
  loadQualifications: PropTypes.func.isRequired,
  qualifications: PropTypes.shape({}),
  hasNotificationsError: PropTypes.bool.isRequired,
  hasQualificationsError: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  setloginSuccess: PropTypes.func.isRequired,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppInitialiser));
