import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ReplaceTemplates } from "utilities/Helpers";
import { PageHeader } from "acca-design-system";

import CONTACT_DETAILS from "constants/contactDetails";

import PageContentLoader from "components/PageContentLoader/PageContentLoader";
import { getHelpInformationContent } from "state/content/selectors";
import { fetchHelpInformationContent } from "state/content/actions";
import BrowserTitle from "components/BrowserTitle/BrowserTitle";
import Container from "components/Container/Container";
import AdditionalLinks from "components/AdditionalLinks/AdditionalLinks";
import HelpCard from "./components/HelpCard/HelpCard";

const mapStateToProps = state => ({
  content: getHelpInformationContent(state),
});

const mapDispatchToProps = dispatch => ({
  loadContent: () => dispatch(fetchHelpInformationContent()),
});

const HelpPage = ({ content, loadContent }) => {
  useEffect(() => {
    if (!content) {
      loadContent();
    }
  }, []);

  const pageReady = !!content;

  if (!pageReady) {
    return <PageContentLoader />;
  }

  const { heading, quote, title, footer, links, cards } = content;

  return (
    <Fragment>
      <BrowserTitle content={content} />
      <PageHeader className="u-margin-bottom-2" pageTitle={heading} quote={quote} />

      <Container className="section">
        <div className="cell">
          {title && <h3 className="section-title">{title}</h3>}
          {cards.map((card, i) => {
            const cardTitle = ReplaceTemplates(card.title, {
              telephone: `<a href="tel: ${CONTACT_DETAILS.telephone}">${CONTACT_DETAILS.telephone}</a>`,
            });

            return <HelpCard key={i} content={{ ...card, title: cardTitle }} />;
          })}
        </div>
      </Container>

      {links && <AdditionalLinks heading={footer} links={links} />}
    </Fragment>
  );
};

HelpPage.propTypes = {
  content: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    quote: PropTypes.string,
    title: PropTypes.string,
    cards: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        link: PropTypes.shape({
          icon: PropTypes.string,
          text: PropTypes.string,
          href: PropTypes.string,
        }),
      })
    ),
    footer: PropTypes.string,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        link: PropTypes.shape({
          text: PropTypes.string,
          href: PropTypes.string,
        }),
      })
    ),
  }),
  loadContent: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(HelpPage);
