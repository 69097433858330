import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Parser from "html-react-parser";
import { PreferenceCard, BodyCopy, Alert } from "acca-design-system";
import {
  getUserHasEmailAddress,
  getUserHasMobile,
  getQuestionState,
  getImportantUpdatesByEmail,
  getDisableUpdates,
} from "state/communicationPreferences/selectors";
import { updateConsent } from "state/communicationPreferences/actions";
import { getIsOnlineCountry } from "state/profile/selectors";
import content from "../../CommunicationPreferencesPage.content";

const mapStateToProps = (state, ownProps) => {
  const question = getQuestionState(state, ownProps.id);

  return {
    isOnlineCountryUser: getIsOnlineCountry(state),
    userHasEmailAddress: getUserHasEmailAddress(state),
    userHasMobileNumber: getUserHasMobile(state),
    importantUpdatesByEmail: getImportantUpdatesByEmail(state),
    disabled: getDisableUpdates(ownProps.id, state),
    loading: question.loading,
    value: question.value,
    error: question.error,
  };
};

const mapDispatchToProps = dispatch => ({
  setUserConsent: (id, val) => dispatch(updateConsent(id, val)),
});

const renderError = error => {
  if (!error) {
    return null;
  }

  if (error === "DUPLICATE_EMAIL") {
    return <Alert type="warning">{Parser(content.errorMessages.duplicateEmail)}</Alert>;
  }

  return <Alert type="error">{Parser(content.errorMessages.generic)}</Alert>;
};

export const Question = ({
  id,
  heading,
  description,
  onlineCountryDescription,
  trueLabel,
  falseLabel,
  requiresEmail,
  requiresMobile,
  requiresImportantUpdatesByEmail,

  isOnlineCountryUser,
  userHasEmailAddress,
  userHasMobileNumber,
  importantUpdatesByEmail,
  loading,
  value,
  error,
  setUserConsent,
  disabled,
  className = "",
}) => {
  const questionDescription =
    isOnlineCountryUser && onlineCountryDescription ? onlineCountryDescription : description;

  const showEmailAlert = !userHasEmailAddress && requiresEmail;
  const showMobileAlert = !userHasMobileNumber && requiresMobile;
  const showImportantUpdatesByEmailAlert =
    !importantUpdatesByEmail.value && requiresImportantUpdatesByEmail;

  const hideToggle =
    disabled || showEmailAlert || showMobileAlert || showImportantUpdatesByEmailAlert;

  const isLoading = loading || (requiresImportantUpdatesByEmail && importantUpdatesByEmail.loading);

  return (
    <PreferenceCard
      id={id}
      heading={heading}
      falseLabel={falseLabel}
      trueLabel={trueLabel}
      toggleValue={value}
      hideToggle={hideToggle}
      handleToggle={val => setUserConsent(id, val)}
      loading={isLoading}
      className={className}
    >
      <BodyCopy>
        {Parser(questionDescription)}
        {showEmailAlert && !showImportantUpdatesByEmailAlert && (
          <Alert type="warning">{Parser(content.errorMessages.noEmail)}</Alert>
        )}
        {showMobileAlert && <Alert type="warning">{Parser(content.errorMessages.noMobile)}</Alert>}
        {showImportantUpdatesByEmailAlert && (
          <Alert type="warning">
            {Parser(content.errorMessages.requiresImportantUpdatesByEmail)}
          </Alert>
        )}
        {renderError(error)}
      </BodyCopy>
    </PreferenceCard>
  );
};

Question.propTypes = {
  id: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  trueLabel: PropTypes.string.isRequired,
  falseLabel: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onlineCountryDescription: PropTypes.string,
  requiresEmail: PropTypes.bool,
  requiresMobile: PropTypes.bool,
  requiresImportantUpdatesByEmail: PropTypes.bool,
  isOnlineCountryUser: PropTypes.bool.isRequired,
  userHasEmailAddress: PropTypes.bool.isRequired,
  userHasMobileNumber: PropTypes.bool.isRequired,
  importantUpdatesByEmail: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    value: PropTypes.bool.isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  value: PropTypes.bool.isRequired,
  error: PropTypes.string,
  setUserConsent: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Question);
