const CPD_DECLARATION_ROUTES = {
  UNIT_ROUTE: "UNIT_ROUTE",
  APPROVED_EMPLOYER: "APPROVED_EMPLOYER",
  IFAC_BODY: "IFAC_BODY",
  PART_TIME: "PART_TIME",
  NOT_MET_REQUIREMENTS: "NOT_MET_REQUIREMENTS",
  NOT_SPECIFIED: "NOT_SPECIFIED",
};

export default CPD_DECLARATION_ROUTES;
