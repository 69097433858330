import sha256 from "crypto-js/sha256";

const encryptAccaId = accaId => {
  const saltKey = process.env.AUTH_CLIENT_SALT;
  const encriptedAccaId = sha256(accaId + saltKey).toString();
  return encriptedAccaId;
};

export const initUserData = accaId => {
  const encriptedAccaId = encryptAccaId(accaId);

  window.digitalData = window.digitalData || {};
  window.digitalData.user = window.digitalData.user || [];
  window.digitalData.user[0] = {
    isLoggedIn: true,
    userID: encriptedAccaId,
  };
};

export const initPageLoad = (userData, errorInfo = null) => {
  const {
    accaId,
    accaStatus,
    feesOwed,
    examProgress,
    cpdStatus,
    perStatus,
    ethicsStatus,
    previousPage,
  } = userData || {};

  window.adobeDataLayer = window.adobeDataLayer || [];

  const pageLoadData = {
    event: "Page Load",
    pageViews: {
      value: 1,
    },
    page: {
      pageType: "",
      previousPageUrl: previousPage,
      urlBreakdown: window.location.href.replace("/#", ""),
    },
    user: {
      accaId,
      encryptedId: encryptAccaId(accaId),
      accaStatus,
      feesOwed,
      examProgress,
      cpdStatus,
      perStatus,
      ethicsStatus,
    },
  };

  if (errorInfo) {
    const { errorCode, errorType, errorDetail } = errorInfo;
    pageLoadData.error = { errorCode, errorType, errorDetail };
  }

  window.adobeDataLayer.push(pageLoadData);
};

export const trackPageView = (pageName, userType, customPath) => {
  window.digitalData = window.digitalData || {};
  window.digitalData.page = window.digitalData.page || {};
  const pathFragment = customPath || window.location.hash.substr(1);

  const path = userType ? `/${userType + pathFragment}` : pathFragment;
  const url = `${window.location.href.split("#")[0]}#${pathFragment}`;
  const prevPageInfo = window.digitalData.page.pageInfo || {};

  window.digitalData.page.pageInfo = {
    ...prevPageInfo,
    url,
    pageName,
    path,
  };
  const customEvent = document.createEvent("CustomEvent");
  customEvent.initCustomEvent("page-view", false, false, {
    url,
    pageName,
    path,
  });
  document.body.dispatchEvent(customEvent);
  // initPageLoad(userData);
  return window.digitalData;
};

export const trackEvent = ({ label, ...detail }) => {
  window.digitalData = window.digitalData || {};
  window.digitalData.events = window.digitalData.events || [];
  window.digitalData.events.push({ label, detail });

  const customEvent = document.createEvent("CustomEvent");
  customEvent.initCustomEvent("custom-event", false, false, {
    label,
    detail,
  });

  document.body.dispatchEvent(customEvent);

  return window.digitalData;
};

export const pushDataLayerLoginCompletedEvent = (
  accaId,
  accaStatus,
  feesOwed,
  examProgress,
  cpdStatus,
  perStatus,
  ethicsStatus
) => {
  window.adobeDataLayer = window.adobeDataLayer || [];

  const encryptedId = encryptAccaId(accaId);

  window.adobeDataLayer.push({
    event: "Login Completed",
    logins: {
      value: 1,
    },
    user: {
      accaId,
      encryptedId,
      accaStatus,
      feesOwed,
      examProgress,
      cpdStatus,
      perStatus,
      ethicsStatus,
    },
  });
};
