import React from "react";
import PropTypes from "prop-types";
import DetailListField from "components/DetailListField/DetailListField";

const AccountDetailsFieldList = ({ content, data, accaId }) => {
  const getLabel = key => content.find(c => c.key === key)?.text;

  const renderQualifications = () => {
    const label = getLabel("PROF_QUAL");

    if (!data.memberQualifications || !label) {
      return null;
    }

    return data.memberQualifications.length > 0 ? (
      <>
        <dt>{label}</dt>
        {data.memberQualifications.map(qual => {
          return <dd key={qual.code}>{qual.codeDescription}</dd>;
        })}
      </>
    ) : (
      <DetailListField label={label} value="None on record" />
    );
  };

  return (
    <dl>
      <DetailListField label={getLabel("ACCA_ID")} value={accaId} />
      <DetailListField label={getLabel("TITLE")} value={data.title} />
      <DetailListField label={getLabel("NAME")} value={data.fullname} />
      <DetailListField label={getLabel("DATE_OF_BIRTH")} value={data.birthDate} type="date" />
      <DetailListField label={getLabel("QUALIFICATION")} value={data.qualification} />
      <DetailListField label={getLabel("STATUS")} value={data.status} />
      <DetailListField label={getLabel("NATIONALITY")} value={data.nationality} />
      <DetailListField label={getLabel("REG_DATE")} value={data.registrationDate} type="date" />
      <DetailListField label={getLabel("GRAD_DATE")} value={data.graduationDate} type="date" />
      <DetailListField label={getLabel("MEMB_DATE")} value={data.membershipDate} type="date" />
      <DetailListField label={getLabel("FELLOW_DATE")} value={data.fellowDate} type="date" />
      <DetailListField
        label={getLabel("RETIREMENT_DATE")}
        value={data.retirementDate}
        type="date"
      />
      <DetailListField label={getLabel("HONOURS")} value={data.honours} />
      <DetailListField label={getLabel("FIRST_EXAM")} value={data.firstEligibleExam} type="date" />
      <DetailListField label={getLabel("JOINT_SCHEME")} value={data.jointScheme} />
      {renderQualifications()}
    </dl>
  );
};

AccountDetailsFieldList.propTypes = {
  accaId: PropTypes.string,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      key: PropTypes.string,
    })
  ).isRequired,
  data: PropTypes.shape({
    uln: PropTypes.string,
    title: PropTypes.string,
    fullname: PropTypes.string,
    birthDate: PropTypes.string,
    qualification: PropTypes.string,
    status: PropTypes.string,
    nationality: PropTypes.string,
    honours: PropTypes.string,
    graduationDate: PropTypes.string,
    registrationDate: PropTypes.string,
    membershipDate: PropTypes.string,
    fellowDate: PropTypes.string,
    retirementDate: PropTypes.string,
    memberQualifications: PropTypes.arrayOf(PropTypes.shape({})),
    firstEligibleExam: PropTypes.string,
    jointScheme: PropTypes.bool,
    transferToAcca: PropTypes.bool,
    transferDiploma: PropTypes.bool,
    transferPapers: PropTypes.bool,
    oxfordBookes: PropTypes.bool,
  }),
};

export default AccountDetailsFieldList;
