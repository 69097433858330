import React, { createRef, Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getLocation } from "connected-react-router";
import { withRouter, Link } from "react-router-dom";

import { LiveAnnouncer } from "react-aria-live";

import { fetchPageFrameworkContent, logPreviousPath } from "state/content/actions";

import ToastNotifications from "components/ToastNotifications/ToastNotifications";
import SiteFooter from "components/SiteFooter/SiteFooter";
import Sidebar from "components/Sidebar/Sidebar";
import {
  getIsSuspended,
  getErrorLoadingUser,
  getIsSuspensionGracePeriod,
} from "state/profile/selectors";
import CriticalAlert from "components/CriticalAlert/CriticalAlert";
import Router from "./Router";

const mapStateToProps = state => ({
  location: getLocation(state),
  isSuspended: getIsSuspended(state),
  isSuspensionGracePeriod: getIsSuspensionGracePeriod(state),
  errorLoadingUser: getErrorLoadingUser(state),
});

const mapDispatchToProps = dispatch => ({
  loadPageFrameworkTemplate: () => dispatch(fetchPageFrameworkContent()),
  setPreviousPath: previousPath => dispatch(logPreviousPath(previousPath)),
});

export class RootPage extends Component {
  constructor(props) {
    super(props);

    this.containerRef = createRef();
    this.mainContentRef = createRef();
  }

  componentDidMount() {
    const { loadPageFrameworkTemplate } = this.props;
    loadPageFrameworkTemplate();
  }

  componentDidUpdate(prevProps) {
    const { location, setPreviousPath } = this.props;

    if (location.pathname !== prevProps.location.pathname) {
      window.scroll(0, 0);
      this.containerRef.current.focus();
      setPreviousPath(prevProps.location.pathname);
    }
  }

  render() {
    const { isSuspended, errorLoadingUser, isSuspensionGracePeriod } = this.props;

    const hideSidebar = errorLoadingUser || (isSuspended && !isSuspensionGracePeriod);

    return (
      <div tabIndex="-1" ref={this.containerRef}>
        <Link
          to="#main-content"
          className="acca-skip-link"
          onClick={() => {
            this.mainContentRef.current.focus();
          }}
        >
          Skip to main content
        </Link>
        <LiveAnnouncer>
          <Fragment>
            {!hideSidebar && <Sidebar />}
            <div
              className="page-container"
              id="main-content"
              tabIndex="-1"
              ref={this.mainContentRef}
              role="group"
            >
              <Router isSuspended={isSuspended} isSuspensionGracePeriod={isSuspensionGracePeriod} />
              <SiteFooter />
            </div>
          </Fragment>
          <ToastNotifications />
          <CriticalAlert />
        </LiveAnnouncer>
      </div>
    );
  }
}

RootPage.propTypes = {
  location: PropTypes.oneOfType([
    PropTypes.shape({ pathname: PropTypes.string.isRequired }),
    PropTypes.string.isRequired,
  ]).isRequired,
  loadPageFrameworkTemplate: PropTypes.func,
  isSuspended: PropTypes.bool.isRequired,
  isSuspensionGracePeriod: PropTypes.bool,
  errorLoadingUser: PropTypes.bool,
  setPreviousPath: PropTypes.func,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RootPage));
