import React, { Fragment, useEffect } from "react";
import Parser from "html-react-parser";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Alert, BodyCopy, PageHeader } from "acca-design-system";

import PageContentLoader from "components/PageContentLoader/PageContentLoader";
import { getEmploymentContent } from "state/content/selectors";
import { fetchEmploymentContent } from "state/content/actions";
import {
  getIsEmploymentLoaded,
  getEmployment,
  getIsSubmitting,
  getIsReadOnly,
  getFormError,
} from "state/employment/selectors";
import { getUserType } from "state/profile/selectors";
import { fetchEmployment, submitEmployment, toggleReadOnly } from "state/employment/actions";
import { getIsEmploymentLookupLoaded, getEmploymentLookup } from "state/lookup/selectors";
import { fetchEmploymentLookup } from "state/lookup/actions";

import BrowserTitle from "components/BrowserTitle/BrowserTitle";
import { FormSection } from "components/Form";
import Container from "components/Container/Container";
import Form from "components/Form/Form/Form";
import EmploymentDisplayList from "./components/EmploymentDisplayList/EmploymentDisplayList";
import EmploymentInputForm from "./components/EmploymentInputForm/EmploymentInputForm";

const errorText = {
  prompt: "There are errors on the page",
  generic:
    "There was a problem saving your update. Please try again or <a href='/#/help'>contact us</a>.",
};

const mapStateToProps = state => ({
  isEmploymentLoaded: getIsEmploymentLoaded(state),
  employment: getEmployment(state),
  isEmploymentLookupLoaded: getIsEmploymentLookupLoaded(state),
  lookup: getEmploymentLookup(state),
  content: getEmploymentContent(state),
  isSubmitting: getIsSubmitting(state),
  isReadOnly: getIsReadOnly(state),
  customerType: getUserType(state),
  error: getFormError(state),
});

const mapDispatchToProps = dispatch => ({
  loadEmployment: () => dispatch(fetchEmployment()),
  loadEmploymentLookup: () => dispatch(fetchEmploymentLookup()),
  loadContent: () => dispatch(fetchEmploymentContent()),
  submit: (payload, promise) => dispatch(submitEmployment(payload, promise)),
  toggleIsReadOnly: isReadOnly => dispatch(toggleReadOnly(isReadOnly)),
});

const EmploymentPage = ({
  content,
  loadContent,
  isEmploymentLoaded,
  loadEmployment,
  employment,
  isEmploymentLookupLoaded,
  loadEmploymentLookup,
  lookup,
  submit,
  error,
  isSubmitting,
  isReadOnly,
  toggleIsReadOnly,
  customerType,
}) => {
  const errorSummary = errorText.generic;

  useEffect(() => {
    if (!isEmploymentLoaded) {
      loadEmployment();
    }

    if (!isEmploymentLookupLoaded) {
      loadEmploymentLookup();
    }

    if (!content) {
      loadContent();
    }
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, [error]);

  const pageReady = !!content && isEmploymentLoaded && isEmploymentLookupLoaded;

  if (!pageReady) {
    return <PageContentLoader />;
  }

  const { heading, quote, card } = content;
  const { title, text, icon } = card;

  return (
    <Fragment>
      <BrowserTitle content={content} />
      <PageHeader className="u-margin-bottom-2" pageTitle={heading} quote={quote} />
      <Container className="section">
        <div className="cell">
          {error && (
            <Alert type="error" className="u-margin-bottom-2">
              <BodyCopy>{Parser(errorSummary)}</BodyCopy>
            </Alert>
          )}

          <Form
            errorMessage={error ? errorText.prompt : null}
            shouldShowSaveBar={!isReadOnly}
            onSubmit={submit}
            isSubmitting={isSubmitting}
            onFormComplete={loadEmployment}
          >
            <FormSection
              isReadOnly={isReadOnly}
              content={{ heading: title, message: text, icon }}
              readOnly={
                <EmploymentDisplayList
                  content={content}
                  data={employment}
                  lookup={lookup.employment[customerType]}
                />
              }
              formFields={
                <EmploymentInputForm
                  content={content}
                  data={employment}
                  lookup={lookup.employment[customerType]}
                />
              }
              handleOnClick={() => {
                toggleIsReadOnly(!isReadOnly);
              }}
            >
              {employment.businessAddressAvailable === "N" && content.warning ? (
                <Alert type="warning" className="u-margin-bottom-1">
                  <BodyCopy>{Parser(content.warning)}</BodyCopy>
                </Alert>
              ) : null}
            </FormSection>
          </Form>
        </div>
      </Container>
    </Fragment>
  );
};

EmploymentPage.propTypes = {
  content: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    quote: PropTypes.string,
    title: PropTypes.string,
    card: PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.string,
      text: PropTypes.string,
      link: PropTypes.shape({
        icon: PropTypes.string,
        text: PropTypes.string,
      }),
    }),
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      })
    ),
    warning: PropTypes.string,
  }),
  isEmploymentLoaded: PropTypes.bool,
  loadEmployment: PropTypes.func.isRequired,
  employment: PropTypes.shape({
    businessAddressAvailable: PropTypes.string,
  }),
  isEmploymentLookupLoaded: PropTypes.bool,
  loadEmploymentLookup: PropTypes.func.isRequired,
  lookup: PropTypes.shape({
    employment: PropTypes.shape({}),
  }),
  loadContent: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  error: PropTypes.string,
  isSubmitting: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  toggleIsReadOnly: PropTypes.func,
  customerType: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmploymentPage);
