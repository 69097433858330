import { takeLeading, call, select } from "redux-saga/effects";
import ApiService from "services/ApiService";
import apiConfig from "configs/apiConfig";
import fetchSaga from "utilities/fetchSaga";
import { ACTION_TYPES } from "./actions";
import { getAccaId } from "../profile/selectors";

function* handleFetchHistory({ type }) {
  const accaId = yield select(getAccaId);
  yield call(
    fetchSaga(ApiService.callApi, apiConfig.endpoints.user.cpdHistory(accaId), type, {
      params: { adjustmentStatuses: "AWARDED,PENDING" },
    })
  );
}

function* handleDeleteCpdWaiver({ type, waiverId }) {
  const accaId = yield select(getAccaId);
  yield call(
    fetchSaga(
      ApiService.callApi,
      apiConfig.endpoints.user.deleteCpdWaiver(accaId, waiverId),
      type,
      {
        method: "DELETE",
      }
    )
  );
}

export default function* cpdSagas() {
  yield takeLeading(ACTION_TYPES.FETCH_CPD_HISTORY, handleFetchHistory);
  yield takeLeading(ACTION_TYPES.DELETE_WAIVER, handleDeleteCpdWaiver);
}
