/* eslint-disable default-case */
import { ACTION_TYPES as COMMNUNICATION_PREFERENCES_ACTION_TYPES } from "state/communicationPreferences/actions";
import { ACTION_TYPES as CPD_ACTION_TYPES } from "state/cpd/actions";
import { ACTION_TYPES as CPD_DECLARATION_ACTION_TYPES } from "state/cpd-declaration/actions";
import { ACTION_TYPES as CPD_WAIVER_REQUEST_ACTION_TYPES } from "state/cpd-waiver-request/actions";
import { ACTION_TYPES as EMPLOYMENT_ACTION_TYPES } from "state/employment/actions";
import { ACTION_TYPES as CONTACT_DETAILS_ACTION_TYPES } from "state/contactDetails/actions";
import { ACTION_TYPES as APPLY_FOR_MEMBERSHIP_ACTION_TYPES } from "state/applyForMembership/actions";
import { ACTION_TYPES as ACCOUNT_DETAILS_ACTION_TYPES } from "state/accountDetails/actions";
import { ACTION_TYPES as DOC_DOWNLOAD_ACTION_TYPES } from "state/docDownloads/actions";
import { ACTION_TYPES as REGISTER_OF_RETIRED_MEMBERS_ACTION_TYPES } from "state/registerOfRetiredMembers/actions";
import { trackEvent } from "./trackEvents";

const analyticsMiddleware = () => next => action => {
  let event = null;
  switch (action.type) {
    case ACCOUNT_DETAILS_ACTION_TYPES.SUBMIT_ACCOUNT_DETAILS:
      event = {
        category: "Account details",
        label: "Submit account details form",
        action: action.type,
      };
      break;
    case ACCOUNT_DETAILS_ACTION_TYPES.UPDATED_OXFORD_BROOKES:
      event = {
        category: "Account details",
        label: `Oxford brookes - ${action.label}`,
        action: action.type,
      };
      break;
    case ACCOUNT_DETAILS_ACTION_TYPES.UPDATED_TRANSFER_OPTION:
      event = {
        category: "Account details",
        label: `Transfer option - ${action.label}`,
        action: action.type,
      };
      break;
    case COMMNUNICATION_PREFERENCES_ACTION_TYPES.UPDATE_CONSENT:
      event = {
        category: "Communication preferences",
        label: `${action.consentId} - ${action.value.toString()}`,
        action: action.type,
      };
      break;
    case COMMNUNICATION_PREFERENCES_ACTION_TYPES.UPDATE_NETWORKS:
      event = {
        category: "Communication preferences",
        label: "Update Networks",
        action: action.type,
      };
      break;
    case CPD_ACTION_TYPES.DELETE_WAIVER:
      event = {
        category: "CPD waiver request",
        label: "Delete pending waiver",
        action: action.type,
      };
      break;
    case CPD_WAIVER_REQUEST_ACTION_TYPES.SUBMIT_WAIVER:
      event = {
        category: "CPD waiver request",
        label: "Submit waiver request",
        action: action.type,
      };
      break;
    case CPD_WAIVER_REQUEST_ACTION_TYPES.TOGGLE_FORM: {
      const label = action.showForm ? "Open form" : "Close form";
      event = {
        category: "CPD waiver request",
        label,
        action: action.type,
      };
      break;
    }
    case CPD_DECLARATION_ACTION_TYPES.SUBMIT:
      event = {
        category: "CPD declaration",
        label: "Submit declaration",
        action: action.type,
      };
      break;
    case CPD_DECLARATION_ACTION_TYPES.SHOW_FORM:
      event = {
        category: "CPD declaration",
        label: "Open form",
        action: action.type,
      };
      break;
    case CPD_DECLARATION_ACTION_TYPES.CLOSE_FORM:
      event = {
        category: "CPD declaration",
        label: "Close form",
        action: action.type,
      };
      break;
    case CPD_DECLARATION_ACTION_TYPES.SET_STEP:
      event = {
        category: "CPD declaration",
        label: `Navigate Steps - ${action.step}`,
        action: action.type,
      };
      break;
    case EMPLOYMENT_ACTION_TYPES.SUBMIT_EMPLOYMENT:
      event = {
        category: "Employment details",
        label: "Submit form",
        action: action.type,
      };
      break;
    case CONTACT_DETAILS_ACTION_TYPES.SUBMIT_CONTACT_DETAILS:
      event = {
        category: "Contact details",
        label: "Submit form",
        action: action.type,
      };
      break;
    case APPLY_FOR_MEMBERSHIP_ACTION_TYPES.CLOSE_FORM:
      event = {
        category: "Apply for membership",
        label: "Close form",
        action: action.type,
      };
      break;
    case APPLY_FOR_MEMBERSHIP_ACTION_TYPES.SHOW_FORM:
      event = {
        category: "Apply for membership",
        label: "Open form",
        action: action.type,
      };
      break;
    case APPLY_FOR_MEMBERSHIP_ACTION_TYPES.SUBMIT:
      event = {
        category: "Apply for membership",
        label: "Submit",
        action: action.type,
      };
      break;
    case APPLY_FOR_MEMBERSHIP_ACTION_TYPES.SET_STEP:
      event = {
        category: "Apply for membership",
        label: `Navigate Steps - ${action.step}`,
        action: action.type,
      };
      break;
    case DOC_DOWNLOAD_ACTION_TYPES.FETCH_PRACTISING_CERTIFICATE_DOWNLOAD_SUCCESS:
      event = {
        category: "Document and downloads",
        label: "Pracitising certificate download",
        action: action.type,
      };
      break;
    case DOC_DOWNLOAD_ACTION_TYPES.FETCH_MEMBERSHIP_CERTIFICATE_DOWNLOAD_SUCCESS:
      event = {
        category: "Document and downloads",
        label: "Membership certificate download",
        action: action.type,
      };
      break;
    case REGISTER_OF_RETIRED_MEMBERS_ACTION_TYPES.CLOSE_FORM:
      event = {
        category: "Register of retired members",
        label: "Close form",
        action: action.type,
      };
      break;
    case REGISTER_OF_RETIRED_MEMBERS_ACTION_TYPES.SUBMIT:
      event = {
        category: "Register of retired members",
        label: "Submit",
        action: action.type,
      };
      break;
    case REGISTER_OF_RETIRED_MEMBERS_ACTION_TYPES.SET_STEP:
      event = {
        category: "Register of retired members",
        label: `Navigate Steps - ${action.step}`,
        action: action.type,
      };
      break;
  }

  if (event) {
    trackEvent(event);
  }

  return next(action);
};

export default analyticsMiddleware;
