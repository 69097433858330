import { withFormsy } from "formsy-react";
import React from "react";
import PropTypes from "prop-types";

import { formatErrors } from "utilities/formUtils";

import { FormField, FormFieldValidation } from "acca-design-system";

import Label from "components/Form/Label/Label";

class InputField extends React.Component {
  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(event) {
    const { setValue } = this.props;
    // setValue() will set the value of the component, which in
    // turn will validate it and the rest of the form
    // Important: Don't skip this step. This pattern is required
    // for Formsy to work.
    setValue(event.currentTarget.value);
  }

  render() {
    const {
      hasMaxWidth,
      className,
      labelClassName,
      containerClassName,
      getErrorMessages,
      getValue,
      id,
      type,
      label,
      disabled,
      isRequiredField,
      maxLength,
      isValid,
      tabindex,
    } = this.props;

    const errorMessages = formatErrors(getErrorMessages());

    return (
      <FormField valid={isValid()} className={containerClassName} hasMaxWidth={hasMaxWidth}>
        {label && (
          <Label id={id} classNames={labelClassName} text={label} required={isRequiredField} />
        )}
        <input
          tabIndex={tabindex}
          id={id}
          onChange={this.changeValue}
          type={type}
          value={getValue() || ""}
          className={className}
          disabled={disabled}
          maxLength={maxLength}
        />
        {errorMessages.length > 0 && <FormFieldValidation list={errorMessages} />}
      </FormField>
    );
  }
}

InputField.defaultProps = {
  type: "string",
  isRequiredField: false,
};

InputField.propTypes = {
  hasMaxWidth: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  getErrorMessages: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isRequiredField: PropTypes.bool,
  maxLength: PropTypes.string,
  isValid: PropTypes.func,
  tabindex: PropTypes.string,
};

export default withFormsy(InputField);
