import { ACTION_TYPES } from "./actions";

export const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_CPD_HISTORY:
      return initialState;
    case ACTION_TYPES.FETCH_CPD_HISTORY_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        history: action.payload,
      };
    case ACTION_TYPES.DELETE_WAIVER_SUCCESS:
      return {
        ...state,
        history: {
          ...state.history,
          annualDeclarations: state.history.annualDeclarations.map(annualDeclaration => {
            return {
              ...annualDeclaration,
              adjustments: annualDeclaration.adjustments.filter(
                adjustment => adjustment.id !== action.payload.id
              ),
            };
          }),
        },
      };
    default:
      return state;
  }
};

export default reducer;
