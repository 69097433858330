import replace from "lodash.replace";
import { IconOpenInANewWindow16 } from "acca-design-system";

export const ReplaceTemplates = (string, pairs) => {
  let res = string;
  Object.keys(pairs).forEach(key => {
    res = replace(res, new RegExp(`{${key}}`, "g"), pairs[key]);
  });
  return res;
};

export const getUrlParameter = (location, paramName) => {
  const name = paramName.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(location.search);
  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
};
/**
 *
 * @param {decimal} amount
 * @param {bool} showZeroPence
 */
export const formatCurrency = (amount, showZeroPence = true) => {
  if (amount % 1 > 0 || showZeroPence) {
    return `£${amount.toFixed(2)}`;
  }
  return `£${amount}`;
};

export const maxWidthRenditions = images => {
  return images
    .sort((a, b) => (a.width > b.width ? -1 : 1))
    .map(image => ({
      maxWidth: image.width,
      src: image.href,
    }));
};

export const isExternaLink = url => {
  const pattern = new RegExp("^(?:[a-z]+:)?//", "i");
  return pattern.test(url);
};

/**
 * Is the link an /outgoing/ link which is relative page that then opens an external link in a new tab
 * @param {string} url
 */
export const isOutgoingRedirectLink = url => {
  return (
    !!url &&
    (url.toLowerCase().startsWith("/outgoing/") || url.toLowerCase().startsWith("/#/outgoing/"))
  );
};

/**
 * If the link is external or outgoing redirect it returns the external link icon
 * @param {string} url
 */
export const getLinkIcon = url => {
  if (isExternaLink(url) || isOutgoingRedirectLink(url)) {
    return IconOpenInANewWindow16;
  }
  return null;
};

export const networksToSelectOptions = networks =>
  networks
    ? networks
        .map(v => ({ label: v.name, value: v.code }))
        .sort((a, b) => (a.label > b.label ? 0 : -1))
    : [];
