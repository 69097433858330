import { takeLeading, takeLatest, call, select } from "redux-saga/effects";
import ApiService from "services/ApiService";
import apiConfig from "configs/apiConfig";
import fetchSaga from "utilities/fetchSaga";
import { getAccaId, getUserType } from "state/profile/selectors";
import { ACTION_TYPES } from "./actions";

function* handleFetchAccountDetails({ type }) {
  const accaId = yield select(getAccaId);
  const customerType = yield select(getUserType);
  yield call(
    fetchSaga(
      ApiService.callApi,
      apiConfig.endpoints.user.accountDetails(accaId, customerType),
      type
    )
  );
}

function* handleSubmitAccountDetails({ type, payload, promise }) {
  const { resolve, reject } = promise;

  if (payload.oxford || payload.transfer) {
    const accaId = yield select(getAccaId);
    const customerType = yield select(getUserType);

    const body = {};

    if (payload.oxford) {
      body.oxfordBrookes = {
        status: payload.oxford === "true",
      };
    }

    if (payload.transfer) {
      body.transferFIAToAcca = payload.transfer;
    }

    const { error } = yield call(
      fetchSaga(
        ApiService.callApi,
        apiConfig.endpoints.user.submitAccountDetails(accaId, customerType),
        type,
        {
          method: "PATCH",
          requestBody: body,
        },
        { values: body }
      )
    );

    if (error) {
      reject();
    }
  }

  resolve();
}

export default function* accountDetailsSagas() {
  yield takeLeading(ACTION_TYPES.FETCH_ACCOUNT_DETAILS, handleFetchAccountDetails);
  yield takeLatest(ACTION_TYPES.SUBMIT_ACCOUNT_DETAILS, handleSubmitAccountDetails);
}
