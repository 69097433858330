import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchQualifications } from "state/qualifications/actions";
import {
  getIsQualificationsLoading,
  getIsQualificationsLoaded,
  getQualifications,
  getHasQualificationsError,
} from "state/qualifications/selectors";
import { fetchNotifications } from "state/profile/actions";
import DOMPurify from "dompurify";
import { debounce } from "lodash";

import {
  getAccaId,
  getIsNotificationsLoaded,
  getNotifications,
  getIsNotificationsLoading,
  getNotificationsHasError,
  getPortalExperience,
} from "state/profile/selectors";

import { getError } from "state/errors/selectors";

import { formatAccaStatus, getFormattedUserDetails } from "analytics/utils";
import { getPreviousPath } from "state/content/selectors";
import errorContent from "pages/ErrorPage/ErrorPage.content";
import UnsupportedPageContent from "pages/UnsupportedPage/UnsupportedPage.content";
import PageNotFoundContent from "stubs/templates/pageNotFound";

import { initPageLoad } from "./trackEvents";
import ROUTES from "../constants/routes";
import EXPERIENCES from "../constants/portalExperiences";

const usePageLoadTracking = content => {
  const dispatch = useDispatch();

  const {
    qualifications = {},
    notifications,
    accaId,
    portalExperience,
    isQualificationsLoaded,
    isQualificationsLoading,
    hasQualificationsError,
    isNotificationsLoaded,
    isNotificationsLoading,
    hasNotificationsError,
    prevPageUrl,
    appError,
  } = useSelector(state => ({
    qualifications: getQualifications(state),
    notifications: getNotifications(state),
    accaId: getAccaId(state),
    portalExperience: getPortalExperience(state),
    isQualificationsLoaded: getIsQualificationsLoaded(state),
    isQualificationsLoading: getIsQualificationsLoading(state),
    hasQualificationsError: getHasQualificationsError(state),
    isNotificationsLoaded: getIsNotificationsLoaded(state),
    isNotificationsLoading: getIsNotificationsLoading(state),
    hasNotificationsError: getNotificationsHasError(state),
    prevPageUrl: getPreviousPath(state),
    appError: getError(state),
  }));
  let browserTitleMessage = "";
  let message = "";
  let isError = false;

  const shouldLoadQualificationsData = window.location.href.includes(ROUTES.examsPage) && portalExperience !== EXPERIENCES.MEMBER;

  if (appError) {
    const { response } = appError;
    const status = response && errorContent[response.status] ? response.status : "standard";
    const { browserTitle, message: pageMessage } = errorContent[status];
    browserTitleMessage = browserTitle.message;
    message = pageMessage;
    isError = true;
  } else if (
    content &&
    content.heading &&
    content.heading === PageNotFoundContent.system.content.heading
  ) {
    const { heading, message: notFoundMessage } = content;
    browserTitleMessage = heading;
    message = notFoundMessage;
    isError = true;
  } else if (content && content.title && content.title === UnsupportedPageContent.title) {
    const { title, message: unsupportedMessage } = content;
    browserTitleMessage = title;
    message = unsupportedMessage;
    isError = true;
  }

  useEffect(() => {
    const debouncedEffect = debounce(() => {
      if (!isNotificationsLoaded && !isNotificationsLoading && !hasNotificationsError) {
        dispatch(fetchNotifications());
      }

      if (
        !isQualificationsLoaded &&
        shouldLoadQualificationsData &&
        !isQualificationsLoading &&
        !hasQualificationsError
      ) {
        dispatch(fetchQualifications());
      }

      if (
        (isNotificationsLoaded || hasNotificationsError) &&
        ((isQualificationsLoaded && shouldLoadQualificationsData) ||
          (!isQualificationsLoaded && !shouldLoadQualificationsData) ||
          hasQualificationsError)
      ) {
        const accaStatus = formatAccaStatus(portalExperience);

        const {
          notificationsLoginCompletedEventValues,
          qualificationsLoginCompletedEventValues,
        } = getFormattedUserDetails(
          hasNotificationsError,
          hasQualificationsError,
          notifications,
          qualifications,
          portalExperience
        );

        const previousPage = prevPageUrl ? `${window.location.origin}${prevPageUrl}` : "";
        const userData = {
          accaId,
          accaStatus,
          ...notificationsLoginCompletedEventValues,
          ...qualificationsLoginCompletedEventValues,
          previousPage,
        };

        if (isError) {
          const config = {
            ALLOWED_TAGS: [],
          };
          const sanitizedMessage = DOMPurify.sanitize(message, config);
          const errorInfo = {
            errorCode: "",
            errorType: browserTitleMessage,
            errorDetail: sanitizedMessage,
          };

          initPageLoad(userData, errorInfo);
        } else {
          initPageLoad(userData);
        }
      }
    }, 500);

    debouncedEffect();
    return () => debouncedEffect.cancel();
  }, [
    isQualificationsLoaded,
    shouldLoadQualificationsData,
    isNotificationsLoaded,
    hasNotificationsError,
    hasQualificationsError,
    prevPageUrl,
  ]);
};

export default usePageLoadTracking;
