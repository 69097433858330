import { takeLatest, call, select } from "redux-saga/effects";
import ApiService from "services/ApiService";
import apiConfig from "configs/apiConfig";
import fetchSaga from "utilities/fetchSaga";
import { getAccaId } from "state/profile/selectors";
import { getSubmitRequestBody } from "./selectors";
import { ACTION_TYPES } from "./actions";

function* handleSubmitMembershipApplication({ type }) {
  const accaId = yield select(getAccaId);
  const requestBody = yield select(getSubmitRequestBody);

  yield call(
    fetchSaga(ApiService.callApi, apiConfig.endpoints.user.submitApplyForMembership(accaId), type, {
      method: "POST",
      requestBody,
    })
  );
}

export default function* applyForMembershipSaga() {
  yield takeLatest(ACTION_TYPES.SUBMIT, handleSubmitMembershipApplication);
}
