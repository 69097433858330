import React from "react";
import HandoffPage from "components/HandoffPage/HandoffPage";

const EvidenceTrackerHandoffPage = () => (
  <HandoffPage
    message="Evidence tracker handoff page"
    outgoingUrl={process.env.EVIDENCE_TRACKING_URL}
  />
);

export default EvidenceTrackerHandoffPage;
