export const ACTION_TYPES = {
  RESET: "EMPLOYMENT/RESET",
  FETCH_EMPLOYMENT: "EMPLOYMENT/FETCH_EMPLOYMENT",
  FETCH_EMPLOYMENT_SUCCESS: "EMPLOYMENT/FETCH_EMPLOYMENT_SUCCESS",
  FETCH_EMPLOYMENT_ERROR: "EMPLOYMENT/FETCH_EMPLOYMENT_ERROR",

  SUBMIT_EMPLOYMENT: "EMPLOYMENT/SUBMIT",
  SUBMIT_EMPLOYMENT_SUCCESS: "EMPLOYMENT/SUBMIT_SUCCESS",
  SUBMIT_EMPLOYMENT_ERROR: "EMPLOYMENT/SUBMIT_ERROR",

  TOGGLE_IS_READ_ONLY: "EMPLOYMENT/TOGGLE_IS_READ_ONLY",
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const fetchEmployment = () => ({
  type: ACTION_TYPES.FETCH_EMPLOYMENT,
});

export const submitEmployment = (payload, promise) => ({
  type: ACTION_TYPES.SUBMIT_EMPLOYMENT,
  payload,
  promise,
});

export const toggleReadOnly = isReadOnly => ({
  type: ACTION_TYPES.TOGGLE_IS_READ_ONLY,
  isReadOnly,
});
