import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DashboardCard, Button, IconArrowLeft16, IconArrowRight16 } from "acca-design-system";

import { ReplaceTemplates } from "utilities/Helpers";
import Container from "components/Container/Container";
import { getCpdDeclarationOverview } from "state/profile/selectors";
import "./professional-development-page.scss";

const mapStateToProps = state => ({
  cpdDeclarationOverview: getCpdDeclarationOverview(state),
});

const icons = {
  left: IconArrowLeft16,
  right: IconArrowRight16,
};

const AnnualDeclarationsNotification = ({ cpdDeclarationOverview, content }) => {
  const { status, nextDueDate, year } = cpdDeclarationOverview;

  if (status === "upToDate") {
    return null;
  }

  const { link, message } = content[status];
  const displayMessage = ReplaceTemplates(message, { year, date: nextDueDate });
  const Icon = link.icon ? icons[link.icon] : null;

  return (
    <Container className="u-margin-bottom-4">
      <div className="cell">
        <DashboardCard
          heading={displayMessage}
          button={
            link && (
              <Button
                text={link.text}
                to={link.href}
                icon={Icon}
                iconPosition="right"
                isReversed={link.urgent}
              />
            )
          }
        />
      </div>
    </Container>
  );
};

AnnualDeclarationsNotification.propTypes = {
  cpdDeclarationOverview: PropTypes.shape({
    status: PropTypes.string.isRequired,
    nextDueDate: PropTypes.string,
    year: PropTypes.number,
  }).isRequired,
  content: PropTypes.shape({}).isRequired,
};

export default connect(mapStateToProps)(AnnualDeclarationsNotification);
