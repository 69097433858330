import React from "react";
import PropTypes from "prop-types";
import Parser from "html-react-parser";
import { Card, BodyCopy } from "acca-design-system";

const LearningRecordDisplay = ({ content, data }) => {
  const { heading, quote, uln, privacy } = content;

  return uln && data ? (
    <Card>
      <div className="u-content-container-medium">
        <h3 className="section-title">{heading}</h3>
        <BodyCopy className="u-margin-bottom-1">{Parser(quote)}</BodyCopy>
        <BodyCopy>
          <dl>
            <dt>{uln.label}</dt>
            <dd>{data}</dd>
            <dt>{privacy.label}</dt>
            <dd>{Parser(privacy.text)}</dd>
          </dl>
        </BodyCopy>
      </div>
    </Card>
  ) : null;
};

LearningRecordDisplay.propTypes = {
  content: PropTypes.shape({
    heading: PropTypes.string,
    quote: PropTypes.string,
    uln: PropTypes.shape({
      label: PropTypes.string,
    }),
    privacy: PropTypes.shape({
      label: PropTypes.string,
      text: PropTypes.string,
    }),
  }).isRequired,
  data: PropTypes.string,
};

export default LearningRecordDisplay;
