const CPD_DECLARATION_STEPS = {
  LANDING: "LANDING",
  REQUIREMENTS_MET: "REQUIREMENTS_MET",
  ROUTE: "ROUTE",
  ENGAGEMENT_PARTNER: "ENGAGEMENT_PARTNER",
  ACCURACY_CONFIRMATION: "ACCURACY_CONFIRMATION",
  SUBMITTING: "SUBMITTING",
  COMPLETE: "COMPLETE",
  ERROR: "ERROR",
};
export default CPD_DECLARATION_STEPS;
