import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import BrowserTitle from "components/BrowserTitle/BrowserTitle";
import {
  getCountryOfResidence,
  getIsSuspensionGracePeriod,
  getSuspensionReasonCode,
  getGracePeriodEndDate,
  getUserType,
} from "state/profile/selectors";
import AppMessage from "components/AppMessage/AppMessage";
import illustrationSrc from "assets/images/illustrations/account-lock.svg";
import { BodyCopy, Button } from "acca-design-system";
import { getSuspensionsContent } from "state/content/selectors";
import { fetchSuspensionsContent } from "state/content/actions";
import PageContentLoader from "components/PageContentLoader/PageContentLoader";
import HTMLReactParser from "html-react-parser";
import AuthService from "services/AuthService";
import { ReplaceTemplates } from "utilities/Helpers";
import USER_TYPES from "constants/userTypes";

const mapStateToProps = state => ({
  content: getSuspensionsContent(state),
  countryOfResidence: getCountryOfResidence(state),
  isSuspensionGracePeriod: getIsSuspensionGracePeriod(state),
  reasonCode: getSuspensionReasonCode(state),
  gracePeriodEndDate: getGracePeriodEndDate(state),
  userType: getUserType(state),
});

const mapDispatchToProps = dispatch => ({
  loadContent: () => dispatch(fetchSuspensionsContent()),
});

const SuspensionPage = ({
  content,
  loadContent,
  countryOfResidence,
  reasonCode,
  isSuspensionGracePeriod,
  gracePeriodEndDate,
  userType,
}) => {
  useEffect(() => {
    if (!content) {
      loadContent();
    }
  }, []);

  const isPageReady = !!content;

  if (!isPageReady) {
    return <PageContentLoader />;
  }

  const { footer } = content;

  const getDetails = () => {
    if (countryOfResidence === "Singapore") {
      if (isSuspensionGracePeriod) {
        return userType === USER_TYPES.member
          ? content.singapore.gracePeriodMembers
          : content.singapore.gracePeriod;
      }
      return content.singapore.postGracePeriod;
    }

    if (!content.byCode[reasonCode]) {
      return isSuspensionGracePeriod
        ? content.generic.gracePeriod
        : content.generic.postGracePeriod;
    }

    const codeDetails = content.byCode[reasonCode];

    if (isSuspensionGracePeriod) {
      return codeDetails.gracePeriod ? codeDetails.gracePeriod : content.generic.gracePeriod;
    }

    return codeDetails.postGracePeriod
      ? codeDetails.postGracePeriod
      : content.generic.postGracePeriod;
  };

  const { title, description, reason, ctas } = getDetails();

  return (
    <Fragment>
      <BrowserTitle content={content} />
      <AppMessage
        className={isSuspensionGracePeriod ? "" : "u-bg-white"}
        preTitle={ReplaceTemplates(title, { deadline: gracePeriodEndDate })}
        title={ReplaceTemplates(reason, { deadline: gracePeriodEndDate })}
        message={ReplaceTemplates(description, { deadline: gracePeriodEndDate })}
        illustrationSrc={illustrationSrc}
        buttons={ctas}
        footer={
          <BodyCopy>
            {HTMLReactParser(footer.message)}
            {!isSuspensionGracePeriod && (
              <Button
                variant="link"
                onClick={AuthService.logout}
                className="u-margin-top-2"
                text="Logout"
              />
            )}
          </BodyCopy>
        }
      />
    </Fragment>
  );
};

SuspensionPage.propTypes = {
  userType: PropTypes.string.isRequired,
  countryOfResidence: PropTypes.string.isRequired,
  reasonCode: PropTypes.string.isRequired,
  isSuspensionGracePeriod: PropTypes.bool.isRequired,
  loadContent: PropTypes.func.isRequired,
  gracePeriodEndDate: PropTypes.shape({}).isRequired,
  content: PropTypes.shape({
    singapore: PropTypes.shape({
      gracePeriod: PropTypes.shape({}),
      gracePeriodMembers: PropTypes.shape({}),
      postGracePeriod: PropTypes.shape({}),
    }),
    generic: PropTypes.shape({
      gracePeriod: PropTypes.shape({}),
      postGracePeriod: PropTypes.shape({}),
    }),
    byCode: PropTypes.shape({}),
    footer: PropTypes.shape({
      title: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      link: PropTypes.shape({
        text: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuspensionPage);
