const LABELS = {
  COUNTRY: "COUNTRY",
  ADDRESS: "ADDRESS",
  CITY: "TOWN",
  COUNTY: "COUNTY",
  PROVINCE: "PROVINCE",
  ZIP: "ZIP",
  EMAIL: "PRIMARY_EMAIL",
  PRIMARY_NUMBER: "PRIMARY_NUMBER",
};
export default LABELS;
