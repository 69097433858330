import React from "react";
import PropTypes from "prop-types";

import { Doughnut } from "acca-design-system";

import QUALIFICATION_PATHWAYS from "constants/pathways";
import { ReplaceTemplates } from "utilities/Helpers";
import PromotionCard from "components/PromotionCard/PromotionCard";
import QualificationsCard from "../QualificationsCard/QualificationsCard";

import "./experience-progress-card.scss";

const ExperienceProgressCard = ({ content, qualifications }) => {
  const { practicalExperience } = qualifications;

  const shouldRenderCard = practicalExperience && Object.keys(practicalExperience).length > 0;

  if (!shouldRenderCard) {
    const experienceContent =
      content && content.content.find(({ key }) => key === QUALIFICATION_PATHWAYS.NO_DATA);
    const { links: button, ...cardContent } = experienceContent;
    return (
      <>
        {experienceContent && (
          <section className="section">
            <h3 className="section-title">{content.title}</h3>
            <PromotionCard
              content={{
                ...cardContent,
                button,
              }}
            />
          </section>
        )}
      </>
    );
  }

  const { objectives, employment } = practicalExperience;
  const {
    policyType,
    totalAchievedObjectives,
    totalRequiredObjectives,
    remainingRequiredObjectives,
  } = objectives;
  const { unitOfMeasurement, totalAchieved, totalClaimed, experienceRequired } = employment;
  const experienceContent = content && content.content.find(({ key }) => key === policyType);

  const objectiveText = totalAchievedObjectives === 1 ? "objective" : "objectives";

  let supportingText = () => (
    <div className="doughnut-supporting-text">
      <div className="doughnut-supporting-text-line-item">
        <div className="doughnut-supporting-text-line-item__title">
          <span className="doughnut-key u-border border--ocean-light" />
          {unitOfMeasurement} in relevant role
        </div>

        <div className="doughnut-supporting-text-line-item__description">
          {totalClaimed} {unitOfMeasurement} recorded, {totalAchieved} signed off
        </div>
      </div>

      <div className="doughnut-supporting-text-line-item">
        <div className="doughnut-supporting-text-line-item__title">
          <span className="doughnut-key u-border u-border--ocean u-bg-ocean" />
          Performance objectives
        </div>

        <div className="doughnut-supporting-text-line-item__description">
          {totalAchievedObjectives} {objectiveText} completed, {remainingRequiredObjectives} to go
        </div>
      </div>
    </div>
  );
  if (policyType === QUALIFICATION_PATHWAYS.FPER) {
    supportingText = () => (
      <div className="doughnut-supporting-text">
        <div className="doughnut-supporting-text-line-item">
          <div className="doughnut-supporting-text-line-item__title">
            {unitOfMeasurement} in relevant role
          </div>

          <div className="doughnut-supporting-text-line-item__description">
            {totalClaimed} {unitOfMeasurement} recorded, {totalAchieved} signed off
          </div>
        </div>

        <div className="doughnut-supporting-text-line-item">
          <div className="doughnut-supporting-text-line-item__title">Performance objectives</div>

          <div className="doughnut-supporting-text-line-item__description">
            {totalAchievedObjectives} {objectiveText} completed
          </div>
        </div>
      </div>
    );
  }

  const doughnut = () => {
    if (policyType === QUALIFICATION_PATHWAYS.FPER) {
      const progress = totalClaimed > 0 ? 100 : 0;

      return (
        <Doughnut progress={progress} size="medium" className="u-blue">
          <p>
            <span className="h400 u-font-heading">{totalClaimed}</span>
            <br />
            <span className="u-grey">{unitOfMeasurement.toUpperCase()}</span>
          </p>
        </Doughnut>
      );
    }
    const employmentProgressPercent = (totalClaimed / experienceRequired) * 100;
    const employmentProgress =
      employmentProgressPercent < 100 ? Math.round(employmentProgressPercent) : 100;

    const objectivesProgressPercent = (totalAchievedObjectives / totalRequiredObjectives) * 100;
    const objectivesProgress =
      objectivesProgressPercent < 100 ? Math.round(objectivesProgressPercent) : 100;

    return (
      <div className="doughnut-group">
        <Doughnut progress={employmentProgress} size="medium" className="outer-doughnut" />
        <Doughnut
          progress={objectivesProgress}
          size="medium"
          className="u-ocean"
          lineWidth={10}
          markerType="SOLID"
        />
      </div>
    );
  };

  return (
    <>
      {experienceContent && (
        <QualificationsCard
          sectionTitle={content.title}
          title={ReplaceTemplates(experienceContent.title, { exp: policyType })}
          links={experienceContent.links}
          doughnut={doughnut()}
        >
          {supportingText()}
        </QualificationsCard>
      )}
    </>
  );
};

ExperienceProgressCard.propTypes = {
  qualifications: PropTypes.shape({
    practicalExperience: PropTypes.shape({
      employment: PropTypes.shape({
        totalAchieved: PropTypes.number,
        totalClaimed: PropTypes.number,
        experienceRequired: PropTypes.number,
        unitOfMeasurement: PropTypes.string,
      }),
      objectives: PropTypes.shape({
        policyType: PropTypes.string,
        totalAchievedObjectives: PropTypes.number,
        totalRequiredObjectives: PropTypes.number,
        remainingRequiredObjectives: PropTypes.number,
      }),
    }),
  }),
  content: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default ExperienceProgressCard;
