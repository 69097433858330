import STATUS from "constants/cpdDeclarationStatus";
import get from "lodash.get";
import { createSelector } from "reselect";
import { formatDate } from "utilities/dateUtils";
import EXPERIENCES from "constants/portalExperiences";
import USER_TYPES from "constants/userTypes";
import addHours from "date-fns/addHours";
import format from "date-fns/format";
import DATES from "constants/dates";
import JES_COUNTRIES from "constants/jesCountries";

export const getIsUserLoaded = state => state.profile.isUserLoaded;
export const getErrorLoadingUser = state => state.profile.error;
export const getAccaId = state => state.profile.accaId;
export const getName = state => state.profile.name;
export const getFirstName = state => state.profile.firstName;
export const getUserType = state => state.profile.userType;
export const getShowNotificationModal = state => state.profile.showModal;

export const getAvatarProfile = state => {
  const { name, userType, initials } = state.profile;
  return { name, userType, initials };
};

export const getIsCpdDeclarationOverviewLoaded = state => !!state.profile.cpdDeclarationOverview;
export const getCpdDeclarationOverviewHasError = state =>
  !!state.profile.cpdDeclarationOverviewError;
export const getCpdDeclarationOverview = state => {
  if (!getIsCpdDeclarationOverviewLoaded(state)) {
    return null;
  }

  const { code, years, dueEndDate, remainingDays } = state.profile.cpdDeclarationOverview;
  const result = {
    remainingDays: remainingDays || 0,
    year: years[0],
    nextDueDate: formatDate(dueEndDate),
  };

  if (code === "DUE") {
    if (remainingDays) {
      return { ...result, status: STATUS.DUE };
    }

    return { ...result, status: STATUS.READY_TO_START };
  }

  if (code === "OVERDUE") {
    return { ...result, status: STATUS.OVERDUE };
  }

  return { ...result, status: STATUS.UP_TO_DATE };
};

export const getIsAccountBalanceLoaded = state => !!state.profile.accountBalance;
export const getAccountBalanceHasError = state => !!state.profile.accountBalanceError;
export const getAccountBalance = state => get(state, "profile.accountBalance.amount");
export const getIsNotificationsLoaded = state => !!state.profile.notifications;
export const getIsNotificationsLoading = state => !!state.profile.isNotificationsLoading;
export const getNotificationsHasError = state => !!state.profile.notificationsError;
export const getNotifications = state => state.profile.notifications;
export const getIsExamSuppressionPeriod = state => {
  const notifications = get(state, "profile.notifications", []);
  return notifications ? notifications.indexOf("EXAM_SUPPRESSION_PERIOD") > -1 : false;
};

export const getIsAfterExamSuppressionPeriod = state => {
  const notifications = get(state, "profile.notifications", []);
  return notifications ? notifications.indexOf("AFTER_EXAM_SUPPRESSION_PERIOD") > -1 : false;
};

export const getIsUserSuppressed = state => getUserType(state) === USER_TYPES.student;

export const getBadges = state => state.profile.badges;
export const getIsSupervisor = state => state.profile.perSupervisor;

export const getCountryOfResidence = state => state.profile.countryOfResidence;

export const getIsResidentOfChina = state => {
  const countryOfResidence = getCountryOfResidence(state);
  return countryOfResidence === "China, Peoples Rep of";
};

export const getIsResidentOfMalta = state => {
  const countryOfResidence = getCountryOfResidence(state);
  return countryOfResidence === "Malta";
};

export const getIsJesResident = state => {
  const countryOfResidence = getCountryOfResidence(state);
  return JES_COUNTRIES.includes(countryOfResidence);
};

export const getIsOnlineCountry = state => state.profile.onlineCountry;

export const getIsSuspended = state =>
  state.profile.isSuspended ? state.profile.isSuspended : false;
export const getIsSuspensionGracePeriod = state => state.profile.isSuspensionGracePeriod;
export const getSuspensionReasonCode = state => state.profile.suspensionReasonCode;
export const getGracePeriodEndDate = state => {
  const hours = state.profile.remainingGracePeriodDays * 24 - new Date().getTimezoneOffset() / 60;
  const result = addHours(new Date(Date.now()), hours < 0 ? 0 : hours);
  return format(result, DATES.FORMAT_DAY_MONTH_YEAR);
};

export const getPortalExperience = state => state.profile.portalExperience;

export const getIsPortalExperienceSupported = createSelector(getPortalExperience, experience => {
  if (!experience) {
    return true;
  }
  if (experience === EXPERIENCES.LEGACY || experience === EXPERIENCES.UNSUPPORTED) {
    return false;
  }
  return Object.values(EXPERIENCES).indexOf(experience) > -1;
});

export const getSessionId = state => get(state, "profile.sessionId", null);
export const getPathwayDescription = state => state.profile.pathwayDescription;
export const getPathway = state => state.profile.pathway;
export const getEncryptedUserId = state => state.profile.encryptedUserId;
export const getIsRetired = state => state.profile.isRetired;

export const getIsEligibleForReducedSubs = state => {
  const notifications = get(state, "profile.notifications", []);
  return notifications ? notifications.indexOf("LTA_REDUCED_SUBS") > -1 : false;
};

export const getHasAppliedForReducedSubs = state => {
  const notifications = get(state, "profile.notifications", []);
  return notifications ? notifications.indexOf("LTA_RS_SUBMITTED") > -1 : false;
};
