const content = {
  browserTitle: {
    message: "Access unavailable | ACCA Portal",
  },
  title: "Your account is no longer available to view online",
  message:
    "<p>If you have a query about the information held or need to change your existing information, please contact our customer services team for support</p>",
  buttonText: "Get in touch",
  buttonTo: "https://www.accaglobal.com/gb/en/footertoolbar/contact-us/connect.html",
};

export default content;
