import React from "react";
import Container from "components/Container/Container";
import { LinkList, LinkListItem } from "acca-design-system";
import { getLinkIcon } from "utilities/Helpers";

const AdditionalLinks = ({ heading, links }) => {
  return (
    <>
      {links && (
        <Container className="section">
          <div className="cell">
            <LinkList heading={heading}>
              {links.map(({ text, href }, j) => (
                <LinkListItem
                  key={j}
                  to={href}
                  text={text}
                  icon={getLinkIcon(href)}
                  iconPosition="right"
                />
              ))}
            </LinkList>
          </div>
        </Container>
      )}
    </>
  );
};

export default AdditionalLinks;
