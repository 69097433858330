import { take, put, spawn } from "redux-saga/effects";
import { ACTION_TYPES } from "./actions";
import { ACTION_TYPES as PROFILE_ACTION_TYPES } from "../profile/actions";
import { ACTION_TYPES as CONTACT_DETAILS_ACTION_TYPES } from "../contactDetails/actions";
import { ACTION_TYPES as QUALIFICATIONS_ACTION_TYPES } from "../qualifications/actions";
/**
 * catch all errors except from SUBMIT_ERROR or CONSENT_ERROR
 */
export const isErrorAction = ({ type }) => {
  if (
    !type.endsWith("_ERROR") ||
    type.endsWith("SUBMIT_ERROR") ||
    type.endsWith("CONSENT_ERROR") ||
    type.endsWith("DOWNLOAD_ERROR") ||
    type === PROFILE_ACTION_TYPES.FETCH_ACCOUNT_BALANCE_ERROR ||
    type === PROFILE_ACTION_TYPES.FETCH_CPD_DECLARATION_OVERVIEW_ERROR ||
    type === PROFILE_ACTION_TYPES.FETCH_NOTIFICATIONS_ERROR ||
    type === CONTACT_DETAILS_ACTION_TYPES.FETCH_ADDRESSES_ERROR ||
    type === QUALIFICATIONS_ACTION_TYPES.FETCH_QUALIFICATIONS_ERROR
  ) {
    return false;
  }

  return true;
};

export function* handleAppErrors() {
  while (true) {
    const action = yield take("*");
    if (isErrorAction(action)) {
      yield put({
        type: ACTION_TYPES.ERROR,
        error: action.error,
      });
    }
  }
}

export default function* errorSagas() {
  yield spawn(handleAppErrors);
}
