const QUALIFICATION_PATHWAYS = {
  ACCA_QUAL: "ACCA_QUAL",
  FIA: "FIA",
  TRAIL_L7: "TRAIL_L7",
  TRAIL_L4: "TRAIL_L4",
  FPER: "FPER",
  PER: "PER",
  NO_DATA: "NO_DATA",
};
export default QUALIFICATION_PATHWAYS;
