import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PageContentLoader from "components/PageContentLoader/PageContentLoader";
import { fetchSessionId } from "state/profile/actions";
import PropTypes from "prop-types";
import { getSessionId } from "state/profile/selectors";
import { getPreviousPath } from "state/content/selectors";

import BrowserTitle from "components/BrowserTitle/BrowserTitle";

const mapStateToProps = state => ({
  sessionId: getSessionId(state),
  previousPath: getPreviousPath(state),
});

const mapDispatchToProps = dispatch => ({
  loadSessionId: () => dispatch(fetchSessionId()),
});

const HandoffPage = ({ sessionId, loadSessionId, history, previousPath, outgoingUrl, message }) => {
  useEffect(() => {
    if (sessionId) {
      if (previousPath) {
        history.goBack();
        window.open(`${outgoingUrl}${sessionId}`, "_blank");
      } else {
        window.location = `${outgoingUrl}${sessionId}`;
      }
    } else {
      loadSessionId();
    }
  }, [sessionId]);

  return (
    <>
      <BrowserTitle content={{ browserTitle: { message } }} />
      <PageContentLoader />
    </>
  );
};

HandoffPage.propTypes = {
  loadSessionId: PropTypes.func.isRequired,
  sessionId: PropTypes.string,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  previousPath: PropTypes.string,
  outgoingUrl: PropTypes.string,
  message: PropTypes.string,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HandoffPage));
