import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PageContentLoader from "components/PageContentLoader/PageContentLoader";
import { fetchEncryptedUserId } from "state/profile/actions";
import PropTypes from "prop-types";
import { getEncryptedUserId } from "state/profile/selectors";
import { getPreviousPath } from "state/content/selectors";

import BrowserTitle from "components/BrowserTitle/BrowserTitle";

const mapStateToProps = state => ({
  encryptedUserId: getEncryptedUserId(state),
  previousPath: getPreviousPath(state),
});

const mapDispatchToProps = dispatch => ({
  loadEncryptedUserId: () => dispatch(fetchEncryptedUserId()),
});

const FipHandoffPage = ({ encryptedUserId, loadEncryptedUserId, history, previousPath }) => {
  useEffect(() => {
    if (encryptedUserId) {
      if (previousPath) {
        history.goBack();
        window.open(`${process.env.FIP_OUTGOING_URL}`, "_blank");
      } else {
        window.location = `${process.env.FIP_OUTGOING_URL}`;
      }
    } else {
      loadEncryptedUserId();
    }
  }, [encryptedUserId]);

  return (
    <>
      <BrowserTitle content={{ browserTitle: { message: "FIP handoff page" } }} />
      <PageContentLoader />
    </>
  );
};

FipHandoffPage.propTypes = {
  loadEncryptedUserId: PropTypes.func.isRequired,
  encryptedUserId: PropTypes.string,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  previousPath: PropTypes.string,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FipHandoffPage));
