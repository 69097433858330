import { spawn } from "redux-saga/effects";

import contentSagas from "state/content/sagas";
import profileSagas from "state/profile/sagas";
import cpdSagas from "state/cpd/sagas";
import cpdDeclarationSagas from "state/cpd-declaration/sagas";
import cpdWaiverRequestSagas from "state/cpd-waiver-request/sagas";
import accountDetailsSagas from "state/accountDetails/sagas";
import qualificationsSagas from "state/qualifications/sagas";
import errorSagas from "state/errors/sagas";
import communicationPreferencesSagas from "state/communicationPreferences/sagas";
import employmentSagas from "state/employment/sagas";
import lookupSagas from "state/lookup/sagas";
import docDownloadsSagas from "state/docDownloads/sagas";
import contactDetailsSagas from "state/contactDetails/sagas";
import applyForMembershipSagas from "state/applyForMembership/sagas";
import registerOfRetiredMembers from "state/registerOfRetiredMembers/sagas";

export default function* rootSaga() {
  yield spawn(contentSagas);
  yield spawn(profileSagas);
  yield spawn(cpdSagas);
  yield spawn(cpdWaiverRequestSagas);
  yield spawn(errorSagas);
  yield spawn(cpdDeclarationSagas);
  yield spawn(accountDetailsSagas);
  yield spawn(qualificationsSagas);
  yield spawn(communicationPreferencesSagas);
  yield spawn(employmentSagas);
  yield spawn(lookupSagas);
  yield spawn(docDownloadsSagas);
  yield spawn(contactDetailsSagas);
  yield spawn(applyForMembershipSagas);
  yield spawn(registerOfRetiredMembers);
}
