export const ACTION_TYPES = {
  FETCH_USER_PROFILE: "PROFILE/FETCH_USER_PROFILE",
  FETCH_USER_PROFILE_SUCCESS: "PROFILE/FETCH_USER_PROFILE_SUCCESS",
  FETCH_USER_PROFILE_ERROR: "PROFILE/FETCH_USER_PROFILE_ERROR",
  SET_ACCA_ID: "PROFILE/SET_ACCA_ID",
  FETCH_CPD_DECLARATION_OVERVIEW: "FETCH_CPD_DECLARATION_OVERVIEW",
  FETCH_CPD_DECLARATION_OVERVIEW_SUCCESS: "FETCH_CPD_DECLARATION_OVERVIEW_SUCCESS",
  FETCH_CPD_DECLARATION_OVERVIEW_ERROR: "FETCH_CPD_DECLARATION_OVERVIEW_ERROR",
  FETCH_ACCOUNT_BALANCE: "PROFILE/FETCH_ACCOUNT_BALANCE",
  FETCH_ACCOUNT_BALANCE_SUCCESS: "PROFILE/FETCH_ACCOUNT_BALANCE_SUCCESS",
  FETCH_ACCOUNT_BALANCE_ERROR: "PROFILE/FETCH_ACCOUNT_BALANCE_ERROR",
  FETCH_NOTIFICATIONS: "PROFILE/FETCH_NOTIFICATIONS",
  FETCH_NOTIFICATIONS_SUCCESS: "PROFILE/FETCH_NOTIFICATIONS_SUCCESS",
  FETCH_NOTIFICATIONS_ERROR: "PROFILE/FETCH_NOTIFICATIONS_ERROR",
  SHOW_MODAL: "PROFILE/SHOW_MODAL",
  CLOSE_MODAL: "PROFILE/CLOSE_MODAL",
  FETCH_SESSION_ID: "PROFILE/FETCH_SESSION_ID",
  FETCH_SESSION_ID_SUCCESS: "PROFILE/FETCH_SESSION_ID_SUCCESS",
  FETCH_ENCRYPTED_USERID: "PROFILE/FETCH_ENCRYPTED_USERID",
  FETCH_ENCRYPTED_USERID_SUCCESS: "PROFILE/FETCH_ENCRYPTED_USERID_SUCCESS",
};

export const setAccaId = accaId => ({
  type: ACTION_TYPES.SET_ACCA_ID,
  accaId,
});

export const fetchUserProfile = () => ({
  type: ACTION_TYPES.FETCH_USER_PROFILE,
});

export const fetchCpdDeclarationOverview = () => ({
  type: ACTION_TYPES.FETCH_CPD_DECLARATION_OVERVIEW,
});

export const fetchAccountBalance = () => ({
  type: ACTION_TYPES.FETCH_ACCOUNT_BALANCE,
});

export const fetchNotifications = () => ({
  type: ACTION_TYPES.FETCH_NOTIFICATIONS,
});

export const showNotificationModal = () => ({
  type: ACTION_TYPES.SHOW_MODAL,
});

export const closeNotificationModal = () => ({
  type: ACTION_TYPES.CLOSE_MODAL,
});

export const fetchSessionId = () => ({
  type: ACTION_TYPES.FETCH_SESSION_ID,
});

export const fetchEncryptedUserId = () => ({
  type: ACTION_TYPES.FETCH_ENCRYPTED_USERID,
});
