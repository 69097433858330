import React from "react";
import PropTypes from "prop-types";
import { DashboardCard, Button, IconOpenInANewWindow16 } from "acca-design-system";
import Parser from "html-react-parser";

const HelpCard = ({ content }) => {
  const { title, message, link } = content;

  return (
    <DashboardCard
      heading={Parser(title)}
      className="u-margin-bottom-2"
      button={
        link &&
        link.href !== "" && (
          <Button
            text={link.text}
            to={link.href}
            icon={IconOpenInANewWindow16}
            iconPosition="right"
          />
        )
      }
    >
      {Parser(message)}
    </DashboardCard>
  );
};

HelpCard.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string,
    link: PropTypes.shape({
      icon: PropTypes.string,
      text: PropTypes.string,
      href: PropTypes.string,
    }),
  }).isRequired,
};

export default HelpCard;
