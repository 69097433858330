export const ACTION_TYPES = {
  FETCH_PAGE_FRAMEWORK_CONTENT: "FETCH_PAGE_FRAMEWORK_CONTENT",
  FETCH_PAGE_FRAMEWORK_CONTENT_SUCCESS: "FETCH_PAGE_FRAMEWORK_CONTENT_SUCCESS",
  FETCH_PAGE_FRAMEWORK_CONTENT_ERROR: "FETCH_PAGE_FRAMEWORK_CONTENT_ERROR",

  FETCH_LANDING_PAGE_CONTENT: "FETCH_LANDING_PAGE_CONTENT",
  FETCH_LANDING_PAGE_CONTENT_SUCCESS: "FETCH_LANDING_PAGE_CONTENT_SUCCESS",
  FETCH_LANDING_PAGE_CONTENT_ERROR: "FETCH_LANDING_PAGE_CONTENT_ERROR",

  FETCH_PAGE_NOT_FOUND_CONTENT: "FETCH_PAGE_NOT_FOUND_CONTENT",
  FETCH_PAGE_NOT_FOUND_CONTENT_SUCCESS: "FETCH_PAGE_NOT_FOUND_CONTENT_SUCCESS",
  FETCH_PAGE_NOT_FOUND_CONTENT_ERROR: "FETCH_PAGE_NOT_FOUND_CONTENT_ERROR",

  FETCH_CPD_CONTENT: "FETCH_CPD_CONTENT",
  FETCH_CPD_CONTENT_SUCCESS: "FETCH_CPD_CONTENT_SUCCESS",
  FETCH_CPD_CONTENT_ERROR: "FETCH_CPD_CONTENT_ERROR",

  FETCH_PROFESSIONAL_DEVELOPMENT_CONTENT: "FETCH_PROFESSIONAL_DEVELOPMENT_CONTENT",
  FETCH_PROFESSIONAL_DEVELOPMENT_CONTENT_SUCCESS: "FETCH_PROFESSIONAL_DEVELOPMENT_CONTENT_SUCCESS",
  FETCH_PROFESSIONAL_DEVELOPMENT_CONTENT_ERROR: "FETCH_PROFESSIONAL_DEVELOPMENT_CONTENT_ERROR",

  FETCH_MY_DETAILS_CONTENT: "FETCH_MY_DETAILS_CONTENT",
  FETCH_MY_DETAILS_CONTENT_SUCCESS: "FETCH_MY_DETAILS_CONTENT_SUCCESS",
  FETCH_MY_DETAILS_CONTENT_ERROR: "FETCH_MY_DETAILS_CONTENT_ERROR",

  FETCH_LOGIN_DETAILS_CONTENT: "FETCH_LOGIN_DETAILS_CONTENT",
  FETCH_LOGIN_DETAILS_CONTENT_SUCCESS: "FETCH_LOGIN_DETAILS_CONTENT_SUCCESS",
  FETCH_LOGIN_DETAILS_CONTENT_ERROR: "FETCH_LOGIN_DETAILS_CONTENT_ERROR",

  FETCH_ACCOUNT_DETAILS_CONTENT: "FETCH_ACCOUNT_DETAILS_CONTENT",
  FETCH_ACCOUNT_DETAILS_CONTENT_SUCCESS: "FETCH_ACCOUNT_DETAILS_CONTENT_SUCCESS",
  FETCH_ACCOUNT_DETAILS_CONTENT_ERROR: "FETCH_ACCOUNT_DETAILS_CONTENT_ERROR",

  FETCH_QUALIFICATIONS_CONTENT: "FETCH_QUALIFICATIONS_CONTENT",
  FETCH_QUALIFICATIONS_CONTENT_SUCCESS: "FETCH_QUALIFICATIONS_CONTENT_SUCCESS",
  FETCH_QUALIFICATIONS_CONTENT_ERROR: "FETCH_QUALIFICATIONS_CONTENT_ERROR",

  FETCH_FEES_CONTENT: "FETCH_FEES_CONTENT",
  FETCH_FEES_CONTENT_SUCCESS: "FETCH_FEES_CONTENT_SUCCESS",
  FETCH_FEES_CONTENT_ERROR: "FETCH_FEES_CONTENT_ERROR",

  FETCH_DOCS_CONTENT: "FETCH_DOCS_CONTENT",
  FETCH_DOCS_CONTENT_SUCCESS: "FETCH_DOCS_CONTENT_SUCCESS",
  FETCH_DOCS_CONTENT_ERROR: "FETCH_DOCS_CONTENT_ERROR",

  FETCH_HELP_INFORMATION_CONTENT: "FETCH_HELP_INFORMATION_CONTENT",
  FETCH_HELP_INFORMATION_CONTENT_SUCCESS: "FETCH_HELP_INFORMATION_CONTENT_SUCCESS",
  FETCH_HELP_INFORMATION_CONTENT_ERROR: "FETCH_HELP_INFORMATION_CONTENT_ERROR",

  FETCH_EMPLOYMENT_CONTENT: "FETCH_EMPLOYMENT_CONTENT",
  FETCH_EMPLOYMENT_CONTENT_SUCCESS: "FETCH_EMPLOYMENT_CONTENT_SUCCESS",
  FETCH_EMPLOYMENT_CONTENT_ERROR: "FETCH_EMPLOYMENT_CONTENT_ERROR",

  FETCH_CONTACT_DETAILS_CONTENT: "FETCH_CONTACT_DETAILS_CONTENT",
  FETCH_CONTACT_DETAILS_CONTENT_SUCCESS: "FETCH_CONTACT_DETAILS_CONTENT_SUCCESS",
  FETCH_CONTACT_DETAILS_CONTENT_ERROR: "FETCH_CONTACT_DETAILS_CONTENT_ERROR",

  FETCH_ONBOARDING_CONTENT: "FETCH_ONBOARDING_CONTENT",
  FETCH_ONBOARDING_CONTENT_SUCCESS: "FETCH_ONBOARDING_CONTENT_SUCCESS",
  FETCH_ONBOARDING_CONTENT_ERROR: "FETCH_ONBOARDING_CONTENT_ERROR",

  FETCH_SUSPENSIONS_CONTENT: "FETCH_SUSPENSIONS_CONTENT",
  FETCH_SUSPENSIONS_CONTENT_SUCCESS: "FETCH_SUSPENSIONS_CONTENT_SUCCESS",

  LOG_PREVIOUS_PATH: "LOG_PREVIOUS_PATH",
};

export const logPreviousPath = previousPath => ({
  type: ACTION_TYPES.LOG_PREVIOUS_PATH,
  previousPath,
});

export const fetchPageFrameworkContent = () => ({
  type: ACTION_TYPES.FETCH_PAGE_FRAMEWORK_CONTENT,
});

export const fetchLandingPageContent = () => ({
  type: ACTION_TYPES.FETCH_LANDING_PAGE_CONTENT,
});

export const fetchPageNotFoundContent = () => ({
  type: ACTION_TYPES.FETCH_PAGE_NOT_FOUND_CONTENT,
});

export const fetchCpdContent = () => ({
  type: ACTION_TYPES.FETCH_CPD_CONTENT,
});

export const fetchProfessionalDevelopmentContent = () => ({
  type: ACTION_TYPES.FETCH_PROFESSIONAL_DEVELOPMENT_CONTENT,
});

export const fetchMyDetailsContent = () => ({
  type: ACTION_TYPES.FETCH_MY_DETAILS_CONTENT,
});

export const fetchLoginDetailsContent = () => ({
  type: ACTION_TYPES.FETCH_LOGIN_DETAILS_CONTENT,
});

export const fetchAccountDetailsContent = () => ({
  type: ACTION_TYPES.FETCH_ACCOUNT_DETAILS_CONTENT,
});

export const fetchQualificationsContent = () => ({
  type: ACTION_TYPES.FETCH_QUALIFICATIONS_CONTENT,
});

export const fetchFeesContent = () => ({
  type: ACTION_TYPES.FETCH_FEES_CONTENT,
});

export const fetchDocsContent = () => ({
  type: ACTION_TYPES.FETCH_DOCS_CONTENT,
});

export const fetchHelpInformationContent = () => ({
  type: ACTION_TYPES.FETCH_HELP_INFORMATION_CONTENT,
});

export const fetchEmploymentContent = () => ({
  type: ACTION_TYPES.FETCH_EMPLOYMENT_CONTENT,
});

export const fetchContactDetailsContent = () => ({
  type: ACTION_TYPES.FETCH_CONTACT_DETAILS_CONTENT,
});

export const fetchOnboardingContent = () => ({
  type: ACTION_TYPES.FETCH_ONBOARDING_CONTENT,
});

export const fetchSuspensionsContent = () => ({
  type: ACTION_TYPES.FETCH_SUSPENSIONS_CONTENT,
});
