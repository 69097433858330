import axios from "axios";
import apiConfig from "configs/apiConfig";
import AuthService from "./AuthService";

const request = (url, accessToken, method = "GET", headers = {}, requestBody, timeout, params) => {
  const config = {
    method,
    data: requestBody,
    params,
    url,
    timeout,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
      client_id: apiConfig.clientId,
      ...headers,
    },
  };

  return axios(config);
};

export class ApiService {
  callApi = (
    url,
    {
      method = "GET",
      headers = {},
      requestBody = {},
      timeout = process.env.API_TIMEOUT ? process.env.API_TIMEOUT : 30000,
      params = {},
    }
  ) => {
    const call = accessToken =>
      request(url, accessToken, method, headers, requestBody, timeout, params);
    return AuthService.getUser().then(user => call(user.access_token));
  };
}

export default new ApiService();
