import React from "react";
import PropTypes from "prop-types";
import Parser from "html-react-parser";
import classnames from "classnames";
import { Button, BodyCopy, Logo, ButtonGroup, ButtonGroupRow } from "acca-design-system";

import "./app-message.scss";
import Container from "components/Container/Container";

const AppMessage = ({
  preTitle,
  title,
  message,
  footer,
  illustrationSrc,
  icon,
  includeLogo,
  buttons,
  className = "",
}) => {
  const classes = classnames("app-message", {
    "app-message--with-logo": includeLogo,
  });
  return (
    <Container className={className} gridClasses={["grid-x", "grid-margin-x"]}>
      <div className="cell">
        <div className={classes}>
          {includeLogo && <Logo className="app-message__logo" />}
          <div className="app-message__inner">
            {illustrationSrc && (
              <img
                src={illustrationSrc}
                alt=""
                className="app-message__illustration u-margin-bottom-2"
              />
            )}
            {icon && icon({ className: "app-message__icon" })}
            {preTitle && <h3 className="h700 u-font-heading u-margin-bottom-1">{preTitle}</h3>}
            {title && <h1 className="h400 u-font-heading u-margin-bottom-2">{title}</h1>}
            {message && (
              <BodyCopy className="app-message__content u-grey-65 u-margin-bottom-2">
                {Parser(message)}
              </BodyCopy>
            )}
            {buttons && (
              <ButtonGroup>
                <ButtonGroupRow className="u-margin-bottom-4">
                  {buttons.map(({ text, href, onClick, buttonIcon, iconPosition }) => (
                    <Button
                      key={text}
                      text={text}
                      to={href}
                      onClick={onClick}
                      icon={buttonIcon}
                      iconPosition={iconPosition}
                    />
                  ))}
                </ButtonGroupRow>
              </ButtonGroup>
            )}
            {footer && (
              <div className="app-message__footer u-grey-65">
                <div className="app-message__footer-content">{footer}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

AppMessage.propTypes = {
  preTitle: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  illustrationSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  icon: PropTypes.node,

  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      onClick: PropTypes.func,
      text: PropTypes.string,
      buttonIcon: PropTypes.string,
      iconPosition: PropTypes.string,
    })
  ),
  footer: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),

  className: PropTypes.string,
  includeLogo: PropTypes.bool,
};

export default AppMessage;
