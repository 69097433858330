/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-syntax */
import { createSelector } from "reselect";
import _get from "lodash.get";
import ROUTES from "constants/routes";
import USER_TYPES from "constants/userTypes";
import AuthService from "services/AuthService";
import { getUserType, getBadges } from "../profile/selectors";

const linkText = (links = [], route, fallback) => {
  const result = links && links.find(({ href }) => href === route);
  return result && result.text ? result.text : fallback;
};

export const getLinks = state => _get(state.content, "pageFramework.navbar.links", null);

export const buildMasterNavigation = createSelector(getLinks, getBadges, (links, badges) => {
  const getLinkText = (route, fallback) => linkText(links, route, fallback);

  const getNotifications = key => {
    return badges && badges.find(b => b.key === key) ? badges.find(b => b.key === key).count : null;
  };

  return [
    {
      id: "dashboard",
      name: getLinkText(ROUTES.landingPage, "Dashboard"),
      icon: "IconDashboard16",
      to: ROUTES.landingPage,
    },
    {
      id: "professionalDevelopment",
      name: getLinkText(ROUTES.professionalDevelopmentPage, "Professional development"),
      icon: "IconProfessionalDevelopment16",
      to: ROUTES.professionalDevelopmentPage,
      notifications: getNotifications("CPD"),
      allowedUserTypes: [USER_TYPES.member],
      subLinks: [
        {
          id: "professionalDevelopment",
          name: getLinkText(ROUTES.professionalDevelopmentPage, "Professional development"),
          to: ROUTES.professionalDevelopmentPage,
        },
        {
          id: "annualDeclarations",
          name: getLinkText(ROUTES.annualDeclarationsPage, "Annual Declarations"),
          to: ROUTES.annualDeclarationsPage,
          notifications: getNotifications("CPD"),
        },
      ],
    },
    {
      id: "myQualifications",
      name: getLinkText(ROUTES.examsPage, "My Qualifications"),
      to: ROUTES.examsPage,
      notifications: getNotifications("EXA"),
      icon: "IconExamProgress16",
      disallowedUserTypes: [USER_TYPES.firm],
    },

    {
      id: "feesTransactions",
      name: getLinkText(ROUTES.feesAndTransactionsPage, "Fees & transactions"),
      icon: "IconPayment16",
      to: ROUTES.feesAndTransactionsPage,
      notifications: getNotifications("PAY"),
    },
    {
      id: "documentsAndResources",
      name: getLinkText(ROUTES.documentsResourcesPage, "Documents & downloads"),
      icon: "IconDocuments16",
      to: ROUTES.documentsResourcesPage,
    },
    {
      id: "account",
      name: getLinkText(ROUTES.accountLanding, "My details"),
      icon: "IconProfile16",
      to: ROUTES.accountLanding,
      disallowedUserTypes: [USER_TYPES.firm],
      subLinks: [
        {
          id: "account",
          name: getLinkText(ROUTES.accountLanding, "My details"),
          to: ROUTES.accountLanding,
        },
        {
          id: "personalDetails",
          name: getLinkText(ROUTES.accountContactDetailsPage, "Contact details"),
          to: ROUTES.accountContactDetailsPage,
        },
        {
          id: "employmentDetails",
          name: getLinkText(ROUTES.employmentDetailsPage, "Employment details"),
          to: ROUTES.employmentDetailsPage,
        },
        {
          id: "accountDetails",
          name: getLinkText(ROUTES.accountDetailsPage, "Account details"),
          to: ROUTES.accountDetailsPage,
        },
        {
          id: "communicationPreferencesPage",
          name: getLinkText(
            ROUTES.accountCommunicationPreferencesPage,
            "Communication preferences"
          ),
          to: ROUTES.accountCommunicationPreferencesPage,
        },
        {
          id: "loginDetailsPage",
          name: getLinkText(ROUTES.loginDetailsPage, "Login details"),
          to: ROUTES.loginDetailsPage,
        },
      ],
    },
  ];
});

export const shouldRenderItemForUserType = (item, userType) => {
  if (item.disallowedUserTypes && item.disallowedUserTypes.indexOf(userType) > -1) {
    return false;
  }
  if (item.allowedUserTypes && item.allowedUserTypes.indexOf(userType) === -1) {
    return false;
  }
  return true;
};

export const getCurrentUrl = state => state.router.location.pathname;

export const getMainNavigation = createSelector(
  buildMasterNavigation,
  getUserType,
  (mainNavigation, userType) => {
    const updatedMainNav = [];
    const menuItems = (array, newArray) => {
      array.forEach(item => {
        const shouldRender = shouldRenderItemForUserType(item, userType);
        if (shouldRender) {
          const subLinks = [];
          item.subLinks && menuItems(item.subLinks, subLinks);
          const updatedItem = {
            ...item,
            subLinks,
          };
          newArray.push(updatedItem);
        }
      });
    };
    menuItems(mainNavigation, updatedMainNav);

    return updatedMainNav;
  }
);

export const getCurrentMenu = createSelector(
  getMainNavigation,
  getCurrentUrl,
  (navigation, currentPage) => {
    let level = 0;
    let menuId;
    let hasActiveItem = false;

    const getActiveMenuItem = (items, currentLevel, parent) => {
      for (const item of items) {
        const { subLinks, to } = item;
        const hasSubLinks = subLinks && subLinks.length > 0;
        if (to === currentPage && !hasSubLinks) {
          level = currentLevel;
          hasActiveItem = true;
          menuId = parent.id;
          break;
        }
        if (hasSubLinks) {
          getActiveMenuItem(subLinks, currentLevel + 1, item);
        }
      }
    };

    getActiveMenuItem(navigation, level, navigation[0]);

    return hasActiveItem ? { level, menuId } : { level: 0, menuId: navigation[0].id };
  }
);

export const getControls = createSelector(getLinks, links => [
  {
    text: linkText(links, ROUTES.helpPage, "Help"),
    to: ROUTES.helpPage,
  },
  {
    text: "Logout",
    onClick: AuthService.logout,
  },
]);
