import { takeLeading, call, select } from "redux-saga/effects";
import ApiService from "services/ApiService";
import apiConfig from "configs/apiConfig";
import fetchSaga from "utilities/fetchSaga";
import { getAccaId, getUserType } from "state/profile/selectors";
import { ACTION_TYPES } from "./actions";

export function* handleFetchEmployment({ type }) {
  const accaId = yield select(getAccaId);
  const customerType = yield select(getUserType);
  yield call(
    fetchSaga(ApiService.callApi, apiConfig.endpoints.user.employment(accaId, customerType), type)
  );
}

export function* handleSubmitEmployment({ type, payload, promise }) {
  const { resolve, reject } = promise;
  const accaId = yield select(getAccaId);
  const customerType = yield select(getUserType);

  const requestBody = Object.assign(
    {
      companyName: payload.companyName ? payload.companyName : "",
      jobTitle: payload.jobTitle ? payload.jobTitle : "",
    },
    payload.businessCategory && { businessCategory: payload.businessCategory.value },
    payload.employmentCategory && { employmentCategory: payload.employmentCategory.value },
    payload.organisationSize && { organisationSize: payload.organisationSize.value },
    payload.jobCategory && { jobCategory: payload.jobCategory.value }
  );

  const { error } = yield call(
    fetchSaga(
      ApiService.callApi,
      apiConfig.endpoints.user.submitEmployment(accaId, customerType),
      type,
      {
        method: "PUT",
        requestBody,
      }
    )
  );

  if (error) {
    reject();
  }

  resolve();
}

export default function* employmentSagas() {
  yield takeLeading(ACTION_TYPES.FETCH_EMPLOYMENT, handleFetchEmployment);
  yield takeLeading(ACTION_TYPES.SUBMIT_EMPLOYMENT, handleSubmitEmployment);
}
