import { takeLatest, takeEvery, call, select, all, put } from "redux-saga/effects";
import ApiService from "services/ApiService";
import rollbar from "error-logger";
import apiConfig from "configs/apiConfig";
import fetchSaga from "utilities/fetchSaga";
import { ACTION_TYPES } from "./actions";
import { getAccaId } from "../profile/selectors";

function* handleFetchConsents({ type }) {
  const accaId = yield select(getAccaId);
  yield call(
    fetchSaga(ApiService.callApi, apiConfig.endpoints.user.communicationPreferences(accaId), type)
  );
}

function* handleFetchUserNetworks({ type }) {
  const accaId = yield select(getAccaId);
  yield call(fetchSaga(ApiService.callApi, apiConfig.endpoints.user.networks(accaId), type));
}

function* handleUpdateConsent({ type, consentId, value }) {
  const accaId = yield select(getAccaId);
  yield call(
    fetchSaga(
      ApiService.callApi,
      apiConfig.endpoints.user.submitConsentUpdate(accaId),
      type,
      {
        method: "PATCH",
        requestBody: {
          consents: {
            [consentId]: value,
          },
        },
        timeout: 0,
      },
      {
        consentId,
        value,
      }
    )
  );
}

function* handleUpdateNetworks({ type, payload }) {
  const accaId = yield select(getAccaId);

  const calls = [];

  if (payload.regional) {
    calls.push(
      call(ApiService.callApi, apiConfig.endpoints.user.submitRegionalNetwork(accaId), {
        method: "PUT",
        requestBody: {
          regionalNetworkCode: payload.regional,
        },
        timeout: 0,
      })
    );
  }
  if (payload.employment) {
    calls.push(
      call(ApiService.callApi, apiConfig.endpoints.user.submitEmploymentNetworks(accaId), {
        method: "PUT",
        requestBody: {
          employmentNetworkCode: payload.employment,
        },
        timeout: 0,
      })
    );
  }

  try {
    yield all(calls);
    yield put({
      type: ACTION_TYPES.UPDATE_NETWORKS_SUCCESS,
    });
    yield put({
      type: ACTION_TYPES.FETCH_USER_NETWORKS,
    });
  } catch (error) {
    rollbar.error(`${type}_ERROR`, error);
    yield put({
      type: `${type}_ERROR`,
      error:
        error.code === "ECONNABORTED"
          ? {
              ...error,
              response: { ...error.response, status: "timeout" },
            }
          : error,
    });
  }
}

export default function* sagas() {
  yield takeLatest(ACTION_TYPES.FETCH_CONSENTS, handleFetchConsents);
  yield takeEvery(ACTION_TYPES.UPDATE_CONSENT, handleUpdateConsent);
  yield takeLatest(ACTION_TYPES.FETCH_USER_NETWORKS, handleFetchUserNetworks);
  yield takeLatest(ACTION_TYPES.UPDATE_NETWORKS, handleUpdateNetworks);
}
