export const getIsAccountDetailsLoaded = state => !!state.accountDetails.accountDetails;
export const getAccountDetails = state => state.accountDetails.accountDetails;
export const getIsSubmitting = state => !!state.accountDetails.isSubmitting;
export const getFormError = state => state.accountDetails.error;
export const getIsTransferReadOnly = state => state.accountDetails.sections.transfer.isReadOnly;
export const getIsOxfordBrookesReadOnly = state =>
  state.accountDetails.sections.oxfordBrookes.isReadOnly;
export const getShouldShowSaveBar = state =>
  !state.accountDetails.sections.transfer.isReadOnly ||
  !state.accountDetails.sections.oxfordBrookes.isReadOnly;

export const getLMSUrl = state => {
  switch (state.accountDetails?.accountDetails?.nationality) {
    case "Chinese":
    case "China,Peoples Rep of":
      return {
        id: "LMS_CHINA_URL",
        href: process.env.LMS_CHINA_URL,
      };
    case "Russian":
    case "Russian Federation":
      return {
        id: "LMS_RUSSIA_URL",
        href: process.env.LMS_RUSSIA_URL,
      };
    default:
      return {
        id: "LMS_GLOBAL_URL",
        href: process.env.LMS_GLOBAL_URL,
      };
  }
};

export const getRPESUrl = state => {
  switch (state.accountDetails?.accountDetails?.nationality) {
    case "Chinese":
    case "China,Peoples Rep of":
      return process.env.RPES_CHINA_URL;
    case "Russian":
    case "Russian Federation":
      return process.env.RPES_RUSSIA_URL;
    default:
      return process.env.RPES_GLOBAL_URL;
  }
};
