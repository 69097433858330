import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Button, LoadingDots, Alert, BodyCopy } from "acca-design-system";

import "./save-bar.scss";
import Parser from "html-react-parser";

const SaveBar = ({ disabled, hasFocus, children, isSubmitting, errorMessage, showErrorPrompt }) => {
  const classes = classnames(
    "form-save-bar form-save-bar--is-sticky u-box-shadow-eiffel u-margin-top-2",
    {
      "form-save-bar--has-focus": hasFocus,
      "form-save-bar--with-error": errorMessage,
    }
  );

  const text = isSubmitting ? "Saving..." : children;

  return (
    <div className={classes}>
      <div className="form-save-bar__inner">
        <span className="form-save-bar__message u-white u-font-heading">{text}</span>

        {!!errorMessage && showErrorPrompt && !isSubmitting && (
          <Alert type="error" className="form-save-bar__error">
            <BodyCopy>{Parser(errorMessage)}</BodyCopy>
          </Alert>
        )}
        {!isSubmitting ? (
          <Button disabled={disabled} type="submit" text="Save changes" />
        ) : (
          <LoadingDots className="u-padding-1" />
        )}
      </div>
    </div>
  );
};

SaveBar.propTypes = {
  disabled: PropTypes.bool,
  hasFocus: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  isSubmitting: PropTypes.bool,
  errorMessage: PropTypes.string,
  showErrorPrompt: PropTypes.bool,
};

export default SaveBar;
