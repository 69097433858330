import React from "react";
import PropTypes from "prop-types";
import Parser from "html-react-parser";
import { Alert, Card, BodyCopy } from "acca-design-system";
import IconMap from "components/IconMap/IconMap";
import { isExternaLink } from "utilities/Helpers";

const DocLinkCard = ({ content, document }) => {
  const { heading, message, link, warning } = content;
  const icon = IconMap({ name: link.icon });

  const warningMessage = warning.find(w => w.key === document.warning);
  const showWarningMessage = !document.eligible && !!document.warning && !!warningMessage;
  const href = document.eligible && link?.href ? link.href : null;
  const target = link && isExternaLink(link.href) ? "_blank" : null;

  return (
    <Card
      icon={icon}
      iconSize="large"
      className="u-margin-bottom-2"
      href={href}
      target={target}
      backgroundColor={!document.eligible ? "grey" : null}
    >
      <h3 className="h700 u-margin-bottom-1">{heading}</h3>
      <p>{message}</p>
      {showWarningMessage && (
        <Alert type="warning" className="u-margin-top-1">
          <BodyCopy>{Parser(warningMessage.message)}</BodyCopy>
        </Alert>
      )}
    </Card>
  );
};

DocLinkCard.propTypes = {
  document: PropTypes.shape({
    eligible: PropTypes.bool.isRequired,
    warning: PropTypes.string,
  }),
  content: PropTypes.shape({
    heading: PropTypes.string,
    message: PropTypes.string,
    link: PropTypes.shape({
      icon: PropTypes.string,
      href: PropTypes.string,
    }),
    warning: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        message: PropTypes.string,
      })
    ),
  }).isRequired,
};

export default DocLinkCard;
