import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FieldList from "components/FieldList/FieldList";

const EmploymentDisplayList = ({ content, data, lookup }) => {
  const fields = [];
  const showField = (field, key) =>
    field !== undefined && !!content.fields.find(c => c.key === key);

  if (showField(data.companyName, "CompanyName")) {
    fields.push({
      label: content.fields.find(c => c.key === "CompanyName").text,
      value: data.companyName,
    });
  }

  if (showField(data.jobTitle, "JobTitle")) {
    fields.push({
      label: content.fields.find(c => c.key === "JobTitle").text,
      value: data.jobTitle,
    });
  }

  if (showField(data.businessCategory, "BusinessCategory")) {
    fields.push({
      label: content.fields.find(c => c.key === "BusinessCategory").text,
      value: data.businessCategory
        ? lookup.businessCategories.find(l => l.value === data.businessCategory).label
        : null,
    });
  }

  if (showField(data.employmentCategory, "EmploymentCategory")) {
    fields.push({
      label: content.fields.find(c => c.key === "EmploymentCategory").text,
      value: data.employmentCategory
        ? lookup.employmentCategories.find(l => l.value === data.employmentCategory).label
        : null,
    });
  }

  if (showField(data.organisationSize, "OrganisationSize")) {
    fields.push({
      label: content.fields.find(c => c.key === "OrganisationSize").text,
      value: data.organisationSize
        ? lookup.organisationSizes.find(l => l.value === data.organisationSize).label
        : null,
    });
  }

  if (showField(data.jobCategory, "JobCategory")) {
    fields.push({
      label: content.fields.find(c => c.key === "JobCategory").text,
      value: data.jobCategory
        ? lookup.jobCategories.find(l => l.value === data.jobCategory).label
        : null,
    });
  }

  return (
    <Fragment>
      <FieldList fields={fields} />
    </Fragment>
  );
};

EmploymentDisplayList.propTypes = {
  lookup: PropTypes.shape({
    jobTitles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
      })
    ),
    businessCategories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
      })
    ),
    employmentCategories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
      })
    ),
    organisationSizes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
      })
    ),
    jobCategories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
      })
    ),
  }),
  content: PropTypes.shape({
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        key: PropTypes.string,
      })
    ).isRequired,
    warning: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({
    companyName: PropTypes.string,
    jobTitle: PropTypes.string,
    fullname: PropTypes.string,
    businessCategory: PropTypes.string,
    employmentCategory: PropTypes.string,
    organisationSize: PropTypes.string,
    jobCategory: PropTypes.string,
  }),
};

export default EmploymentDisplayList;
