import React from "react";
import { Footer, FooterSection, FooterLink } from "acca-design-system";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getFooterContent } from "state/content/selectors";

const mapStateToProps = state => ({
  content: getFooterContent(state),
});

export const SiteFooter = ({ content }) => {
  const renderSection = ({ title, links }) => {
    return (
      <FooterSection className="cell medium-8" heading={title}>
        {links &&
          links.map((link, j) => {
            const { href, text } = link;
            return <FooterLink key={j} link={href} name={text} />;
          })}
      </FooterSection>
    );
  };
  return !content ? null : (
    <Footer>
      {content.sites && renderSection(content.sites)}
      {content.useful && renderSection(content.useful)}
      {content.popular && renderSection(content.popular)}
    </Footer>
  );
};

const footerSectionProps = PropTypes.shape({
  title: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
}).isRequired;

SiteFooter.propTypes = {
  content: PropTypes.shape({
    sites: footerSectionProps,
    useful: footerSectionProps,
    popular: footerSectionProps,
  }),
};

export default connect(mapStateToProps)(SiteFooter);
