import STEPS from "constants/registerOfRetiredMembersSteps";
import EXPERIENCES from "constants/portalExperiences";

import { getIsRetired, getPortalExperience, getIsResidentOfChina } from "state/profile/selectors";

export const getIsSupportingDocumentsRequired = state =>
  state.accountDetails?.accountDetails?.retirementCriteria === "NON-STANDARD";

export const getIsEligibleForRetirement = state => {
  const isEligibleUserType = getPortalExperience(state) === EXPERIENCES.MEMBER;
  const eligible = state.accountDetails?.accountDetails?.eligibleForRetirement;

  return !!isEligibleUserType && !!eligible;
};

export const getIsFellow = state => {
  const isEligibleUserType = getPortalExperience(state) === EXPERIENCES.MEMBER;
  const fellow = state.accountDetails?.accountDetails?.status?.startsWith("F");

  return !!isEligibleUserType && !!fellow;
};

export const getIsDeclarationAccepted = state =>
  state.registerOfRetiredMembers.values.declarationAccepted;

export const getIsChinaDataProtectionAccepted = state =>
  state.registerOfRetiredMembers.values.chinaDataProtectionAccepted;

export const getRetirementDate = state => state.registerOfRetiredMembers.values.retirementDate;

export const getIsPermanentRetirement = state =>
  state.registerOfRetiredMembers.values.isPermanentRetirement;

export const getIsPcOrInsolvencyLicenceHolder = state =>
  state.registerOfRetiredMembers.values.isPcOrInsolvencyLicenceHolder;

export const getCurrentStep = state => {
  const isRetired = getIsRetired(state);

  if (isRetired) {
    return STEPS.ALREADY_RETIRED;
  }
  return state.registerOfRetiredMembers.step;
};

export const getStepNumber = state => {
  const currentStep = getCurrentStep(state);
  switch (currentStep) {
    case STEPS.PERMANENT_RETIREMENT:
      return 1;
    case STEPS.INSOLVENCY_LICENCE:
      return 2;
    case STEPS.DECLARATION:
      return 3;
    case STEPS.DATA_PROTECTION:
      return 4;
    case STEPS.DATA_PROTECTION_CHINA:
      return 5;
    default:
      return null;
  }
};

export const getNextStep = state => {
  const currentStep = getCurrentStep(state);
  const isResidentOfChina = getIsResidentOfChina(state);

  switch (currentStep) {
    case STEPS.LANDING:
      return STEPS.PERMANENT_RETIREMENT;
    case STEPS.PERMANENT_RETIREMENT: {
      return STEPS.INSOLVENCY_LICENCE;
    }
    case STEPS.INSOLVENCY_LICENCE: {
      return STEPS.DECLARATION;
    }
    case STEPS.DECLARATION:
      return STEPS.DATA_PROTECTION;
    case STEPS.DATA_PROTECTION: {
      if (isResidentOfChina) {
        return STEPS.DATA_PROTECTION_CHINA;
      }
      return STEPS.SUBMITTING;
    }
    case STEPS.DATA_PROTECTION_CHINA:
      return STEPS.SUBMITTING;
    default:
      return null;
  }
};

export const getPrevStep = state => {
  const currentStep = getCurrentStep(state);
  switch (currentStep) {
    case STEPS.PERMANENT_RETIREMENT:
      return STEPS.LANDING;
    case STEPS.INSOLVENCY_LICENCE:
      return STEPS.PERMANENT_RETIREMENT;
    case STEPS.DECLARATION:
      return STEPS.INSOLVENCY_LICENCE;
    case STEPS.DATA_PROTECTION:
      return STEPS.DECLARATION;
    case STEPS.DATA_PROTECTION_CHINA:
      return STEPS.DATA_PROTECTION;
    default:
      return null;
  }
};

export const getIsStepValid = state => {
  const currentStep = getCurrentStep(state);
  const isFellow = getIsFellow(state);
  const declarationAccepted = getIsDeclarationAccepted(state);
  const isPermanentRetirement = getIsPermanentRetirement(state);
  const isPcOrInsolvencyLicenceHolder = getIsPcOrInsolvencyLicenceHolder(state);

  switch (currentStep) {
    case STEPS.LANDING:
      return !!isFellow;
    case STEPS.PERMANENT_RETIREMENT:
      return !!isPermanentRetirement;
    case STEPS.INSOLVENCY_LICENCE:
      return !isPcOrInsolvencyLicenceHolder;
    case STEPS.DECLARATION:
      return !!declarationAccepted;
    default:
      return true;
  }
};
