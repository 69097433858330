import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Parser from "html-react-parser";
import { connect } from "react-redux";
import { Alert, BodyCopy } from "acca-design-system";

import { getAccountDetailsContent } from "state/content/selectors";

import {
  fetchAccountDetails,
  submitAccountDetails,
  toggleReadOnly,
  updatedOxfordBrookes,
  updatedTransferOption,
} from "state/accountDetails/actions";

import {
  getIsSubmitting,
  getAccountDetails,
  getIsTransferReadOnly,
  getIsOxfordBrookesReadOnly,
  getShouldShowSaveBar,
  getFormError,
} from "state/accountDetails/selectors";

import { Form, FormSection } from "components/Form";
import TransferACCADisplay from "../TransferACCADisplay/TransferACCADisplay";
import TransferACCAInput from "../TransferACCAInput/TransferACCAInput";

const errorText = {
  prompt: "There are errors on the page",
  generic:
    "There was a problem saving your update. Please try again or <a href='/#/help'>contact us</a>.",
};

const mapStateToProps = state => ({
  isSubmitting: getIsSubmitting(state),
  details: getAccountDetails(state),
  content: getAccountDetailsContent(state),
  error: getFormError(state),
  isTransferReadOnly: getIsTransferReadOnly(state),
  isOxfordBrookesReadOnly: getIsOxfordBrookesReadOnly(state),
  shouldShowSaveBar: getShouldShowSaveBar(state),
});

const mapDispatchToProps = dispatch => ({
  loadAccountDetails: () => dispatch(fetchAccountDetails()),
  submit: (payload, promise) => dispatch(submitAccountDetails(payload, promise)),
  toggleIsReadOnly: (isReadOnly, sectionName) => dispatch(toggleReadOnly(isReadOnly, sectionName)),
  onTransferOptionChange: value => dispatch(updatedTransferOption(value)),
  onOxfordBrookesChange: value => dispatch(updatedOxfordBrookes(value)),
});

const AccountDetailsForm = ({
  details,
  content,
  isSubmitting,
  submit,
  error,
  isTransferReadOnly,
  toggleIsReadOnly,
  shouldShowSaveBar,
  onTransferOptionChange,
  loadAccountDetails,
}) => {
  const errorSummary = errorText.generic;

  useEffect(() => {
    window.scroll(0, 0);
  }, [error]);

  const { transfer } = content;

  const shouldRenderTransfer = !!(transfer && details.canTransfer && details.transferFIAToAcca);

  return (
    <>
      {error && (
        <div className="cell">
          <Alert type="error" className="u-margin-bottom-2">
            <BodyCopy>{Parser(errorSummary)}</BodyCopy>
          </Alert>
        </div>
      )}
      {shouldRenderTransfer && (
        <div className="cell">
          <Form
            errorMessage={error ? errorText.prompt : null}
            shouldShowSaveBar={shouldShowSaveBar}
            onSubmit={submit}
            isSubmitting={isSubmitting}
            onFormComplete={loadAccountDetails}
          >
            {shouldRenderTransfer && (
              <FormSection
                isReadOnly={isTransferReadOnly}
                className="u-margin-bottom-2"
                content={transfer}
                readOnly={
                  <TransferACCADisplay
                    content={transfer}
                    data={details.transferFIAToAcca.selectedOption}
                    available={details.transferFIAToAcca.availableOptions}
                  />
                }
                formFields={
                  <TransferACCAInput
                    content={transfer}
                    data={details.transferFIAToAcca.selectedOption}
                    available={details.transferFIAToAcca.availableOptions}
                    onRadioButtonChange={onTransferOptionChange}
                  />
                }
                handleOnClick={() => {
                  toggleIsReadOnly(!isTransferReadOnly, "transfer");
                }}
              />
            )}
          </Form>
        </div>
      )}
    </>
  );
};

AccountDetailsForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  loadAccountDetails: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  error: PropTypes.string,
  details: PropTypes.shape({
    canTransfer: PropTypes.bool,
    transferFIAToAcca: PropTypes.shape({
      availableOptions: PropTypes.arrayOf(PropTypes.shape({})),
      selectedOption: PropTypes.string,
    }),
    oxfordBrookes: PropTypes.shape({
      eligible: PropTypes.bool.isRequired,
      status: PropTypes.bool,
      blocked: PropTypes.bool.isRequired,
    }),
  }),
  content: PropTypes.shape({
    transfer: PropTypes.shape({}),
    oxfordBrookes: PropTypes.shape({}),
  }),
  isTransferReadOnly: PropTypes.bool,
  toggleIsReadOnly: PropTypes.func,
  shouldShowSaveBar: PropTypes.bool,
  onTransferOptionChange: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetailsForm);
