import { takeLatest, call, select } from "redux-saga/effects";
import ApiService from "services/ApiService";
import apiConfig from "configs/apiConfig";
import fetchSaga from "utilities/fetchSaga";
import { ACTION_TYPES } from "./actions";
import { getSubmitRequestBody } from "./selectors";
import { getAccaId } from "../profile/selectors";

function* handleSubmitCpdDeclaration({ type }) {
  const requestBody = yield select(getSubmitRequestBody);
  const accaId = yield select(getAccaId);
  yield call(
    fetchSaga(ApiService.callApi, apiConfig.endpoints.user.submitCpd(accaId), type, {
      method: "POST",
      requestBody,
    })
  );
}

export default function* cpdDeclarationSagas() {
  yield takeLatest(ACTION_TYPES.SUBMIT, handleSubmitCpdDeclaration);
}
