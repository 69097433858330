import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  getAppliedForMembership,
  getMembershipApplicationOnHold,
  getMembershipApplicationComplete,
} from "state/qualifications/selectors";

import PromotionCard from "components/PromotionCard/PromotionCard";

const mapStateToProps = state => ({
  applicationInProgress: getAppliedForMembership(state),
  applicationOnHold: getMembershipApplicationOnHold(state),
  applicationComplete: getMembershipApplicationComplete(state),
});

const MembershipApplicationProgressCard = ({
  applicationInProgress,
  applicationOnHold,
  applicationComplete,
  content,
}) => {
  const { inprogress, onhold, complete } = content;

  if (applicationInProgress) {
    return <PromotionCard content={inprogress} />;
  }
  if (applicationOnHold) {
    return <PromotionCard content={onhold} />;
  }
  if (applicationComplete) {
    return <PromotionCard content={complete} />;
  }
  return null;
};

MembershipApplicationProgressCard.propTypes = {
  applicationInProgress: PropTypes.bool.isRequired,
  applicationOnHold: PropTypes.bool.isRequired,
  applicationComplete: PropTypes.bool.isRequired,
  content: PropTypes.shape({
    onhold: PropTypes.shape({}).isRequired,
    inprogress: PropTypes.shape({}).isRequired,
    complete: PropTypes.shape({}).isRequired,
  }),
};

export default connect(mapStateToProps)(MembershipApplicationProgressCard);
