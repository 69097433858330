import _orderby from "lodash.orderby";
import { ACTION_TYPES } from "./actions";

export const initialState = {
  isLoaded: false,
  isSubmitting: false,
  error: null,
  form: {
    email: {},
    primaryPhoneNumber: {},
    mailingAddress: {},
    secondaryAddress: {
      purpose: [],
    },
  },
  sections: {
    email: { isReadOnly: true },
    phone: { isReadOnly: true },
    mailingAddress: { isReadOnly: true },
    secondaryAddress: { isReadOnly: true },
  },
};

const updateFormState = (state, updatedState) => {
  const { email, primaryPhoneNumber, mailingAddress, secondaryAddress } = state;
  return {
    email: {
      ...email,
      ...updatedState.email,
    },
    primaryPhoneNumber: {
      ...primaryPhoneNumber,
      ...updatedState.primaryPhoneNumber,
    },
    mailingAddress: {
      ...mailingAddress,
      ...updatedState.mailingAddress,
    },
    secondaryAddress: {
      ...secondaryAddress,
      ...updatedState.secondaryAddress,
    },
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_CONTACT_DETAILS:
      return {
        countries: state.countries,
        ...initialState,
      };
    case ACTION_TYPES.FETCH_CONTACT_DETAILS_SUCCESS:
      return {
        ...state,
        form: updateFormState(state.form, action.payload),
        defaultCountry: action.payload.mailingAddress && action.payload.mailingAddress.country,
        isLoaded: true,
      };
    case ACTION_TYPES.SUBMIT_CONTACT_DETAILS:
      return {
        ...state,
        isSubmitting: true,
      };
    case ACTION_TYPES.TOGGLE_READ_ONLY_SECTION:
      return {
        ...state,
        sections: {
          ...state.sections,
          [action.sectionName]: {
            isReadOnly: action.isReadOnly,
          },
        },
      };
    case ACTION_TYPES.SUBMIT_CONTACT_DETAILS_ERROR: {
      const errorMessage = action.error.response.data.description
        ? action.error.response.data.description
        : action.error.response.data.message;

      let error = "GENERIC";
      if (errorMessage && errorMessage.indexOf("provide a valid value for - email") > -1) {
        error = "INVALID_EMAIL";
      } else if (errorMessage && errorMessage.indexOf("Duplicate Contact Mechanism found") > -1) {
        error = "DUPLICATE_EMAIL";
      }

      return {
        ...state,
        error,
        isSubmitting: false,
      };
    }
    case ACTION_TYPES.FETCH_ADDRESS_INFO_ERROR:
      return {
        ...state,
        addressLookupFailed: true,
      };
    case ACTION_TYPES.FETCH_ADDRESSES_ERROR:
      return {
        ...state,
        addressLookupFailed: true,
      };
    case ACTION_TYPES.FETCH_COUNTRIES:
      return {
        ...state,
        countries: [],
      };
    case ACTION_TYPES.FETCH_COUNTRIES_SUCCESS: {
      return {
        ...state,
        countries: _orderby(
          action.payload.countries,
          [country => country.name.toLowerCase()],
          ["asc"]
        ),
      };
    }
    case ACTION_TYPES.TOGGLE_ADDRESS_TYPE: {
      const purpose = state.form[action.keyName].purpose.filter(
        item => !["HOME", "BUSINESS"].includes(item)
      );
      purpose.push(action.value);

      return {
        ...state,
        form: {
          ...state.form,
          [action.keyName]: {
            ...state.form[action.keyName],
            purpose,
          },
        },
      };
    }
    case ACTION_TYPES.REVERT_ADDRESS_TYPE: {
      const isBusinessAddress = state.form[action.keyName].purpose.includes("BUSINESS");
      const purpose = state.form[action.keyName].purpose.filter(
        item => !["HOME", "BUSINESS"].includes(item)
      );
      if (isBusinessAddress) {
        purpose.push("HOME");
      } else {
        purpose.push("BUSINESS");
      }

      return {
        ...state,
        form: {
          ...state.form,
          [action.keyName]: {
            ...state.form[action.keyName],
            purpose,
          },
        },
      };
    }
    case ACTION_TYPES.SET_DEFAULT_COUNTRY:
      return {
        ...state,
        defaultCountry: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
