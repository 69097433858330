import clockIllustration from "../../assets/images/illustrations/illustration-clock.svg";

const content = {
  clockSkew: {
    browserTitle: {
      message: "System error",
    },
    preTitle: "There’s a problem",
    title: "Your device’s date, time or time zone is incorrect",
    message:
      "Authentication for your account is time based. Incorrect date and time settings on your device are affecting your access. Please update your date, time or time zone; then <a href='/'>try again</a>.",
    illustration: clockIllustration,
    footer: {
      title: "Need help resolving this?",
      message: "Check out our",
      link: {
        text: "logging in tips",
        href: "https://www.accaglobal.com/gb/en/myacca/problems-logging-in.html#myaccaerror",
      },
    },
  },
  timeout: {
    browserTitle: {
      message: "Timeout error",
    },
    message: "Sorry, this page took too long to load",
    buttonText: "Reload",
    icon: "IconTimelineAlert32",
  },
  standard: {
    browserTitle: {
      message: "System error",
    },
    preTitle: "Failed to load content",
    title: "Sorry, we couldn't load your information",
    message: "This might be a temporary problem.<br>Try reloading the page now.",
    buttonText: "Reload",
    illustration: "illustration503",
    buttonIcon: "IconReload16",
    footer: {
      title: "Need help?",
      message: "Get 24/7 support from our customer services team.",
      link: {
        text: "Get help",
        href: "https://www.accaglobal.com/gb/en/footertoolbar/contact-us.html",
      },
    },
  },
};

export default content;
