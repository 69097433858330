import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./toast-notifications.scss";

const ToastNotifications = () => {
  return (
    <ToastContainer
      toastClassName="acca-toast-notification"
      position="bottom-center"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick={false}
      rtl={false}
      pauseOnVisibilityChange
      draggable
      pauseOnHover={false}
      closeButton={false}
    />
  );
};

export default ToastNotifications;
