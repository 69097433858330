import { ACTION_TYPES } from "./actions";

export const initialState = { isReadOnly: true, isSubmitting: false };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    case ACTION_TYPES.FETCH_EMPLOYMENT:
      return {
        ...initialState,
        employment: null,
      };
    case ACTION_TYPES.FETCH_EMPLOYMENT_SUCCESS: {
      return {
        ...state,
        employment: action.payload.employment,
      };
    }
    case ACTION_TYPES.SUBMIT_EMPLOYMENT: {
      return {
        ...state,
        isSubmitting: true,
      };
    }
    case ACTION_TYPES.SUBMIT_EMPLOYMENT_ERROR:
      return {
        ...state,
        error: action.error.response,
        isSubmitting: false,
      };
    case ACTION_TYPES.TOGGLE_IS_READ_ONLY:
      return {
        ...state,
        isReadOnly: action.isReadOnly,
        error: null,
      };
    default:
      return state;
  }
};

export default reducer;
