const JES_COUNTRIES = [
  "Barbados",
  "Belize",
  "Botswana",
  "Cyprus",
  "Eswatini",
  "Greece",
  "Guyana",
  "Jamaica",
  "Cambodia",
  "Lesotho",
  "Sierra Leone",
  "Trinidad and Tobago",
  "UAE",
  "Cayman Islands",
];

export default JES_COUNTRIES;
