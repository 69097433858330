import { takeLeading, takeLatest, call, put, select, throttle } from "redux-saga/effects";
import ApiService from "services/ApiService";
import apiConfig from "configs/apiConfig";
import fetchSaga from "utilities/fetchSaga";
import rollbar from "error-logger";
import { getAccaId } from "state/profile/selectors";
import { getContactDetails } from "./selectors";
import { ACTION_TYPES } from "./actions";

function* handleFetchContactDetails({ type }) {
  const accaId = yield select(getAccaId);
  yield call(fetchSaga(ApiService.callApi, apiConfig.endpoints.user.contactDetails(accaId), type));
}

const phoneAdapter = (initialValues, updatedValue) => {
  if (updatedValue.number !== "") {
    return Object.assign(
      {
        number: updatedValue.number,
        countryCode: updatedValue.countryCode.value.replace("+", ""),
      },
      initialValues.lineType && { lineType: initialValues.lineType },
      initialValues.contactId && { contactId: initialValues.contactId }
    );
  }

  return Object.assign({}, initialValues.contactId && { contactId: initialValues.contactId });
};

const addressAdapter = (initialValues = {}, updatedValues, isPrimary) => {
  const {
    country,
    city,
    province,
    state,
    addressee,
    addressLine1,
    addressLine2,
    addressLine3,
    addressLine4,
    postcode,
    addressType,
  } = updatedValues;

  const { purpose, contactId } = initialValues;

  const updatedPurpose = purpose
    ? purpose.filter(item => !["HOME", "BUSINESS"].includes(item))
    : [];
  updatedPurpose.push(addressType);

  return Object.assign(
    {
      country: country.label,
      purpose: updatedPurpose,
      isPrimary,
    },
    city && { city },
    province && { province },
    state && { state },
    addressee && { addressee },
    addressLine1 && { addressLine1 },
    addressLine2 && { addressLine2 },
    addressLine3 && { addressLine3 },
    addressLine4 && { addressLine4 },
    postcode && { postcode },
    contactId && { contactId }
  );
};

export const contactDetailsPayloadAdapter = (state, payload) => {
  const result = {};

  const { email, mailingAddress, secondaryAddress, primaryPhoneNumber } = state;

  if (Object.prototype.hasOwnProperty.call(payload, "email")) {
    const { contactId } = email;
    result.email = Object.assign(
      {
        contactId,
        address: payload.email.address,
      },
      contactId && { contactId }
    );
  }

  if (Object.prototype.hasOwnProperty.call(payload, "primaryPhoneNumber")) {
    result.primaryPhoneNumber = phoneAdapter(primaryPhoneNumber, payload.primaryPhoneNumber);
  }

  if (Object.prototype.hasOwnProperty.call(payload, "mailingAddress")) {
    result.mailingAddress = addressAdapter(mailingAddress, payload.mailingAddress, true);
  } else if (mailingAddress.contactId) {
    result.mailingAddress = Object.assign(
      {
        country: mailingAddress.country,
        purpose: mailingAddress.purpose,
        isPrimary: mailingAddress.isPrimary,
        contactId: mailingAddress.contactId,
      },
      mailingAddress.city && { city: mailingAddress.city },
      mailingAddress.province && { province: mailingAddress.province },
      mailingAddress.state && { state: mailingAddress.state },
      mailingAddress.addressee && { addressee: mailingAddress.addressee },
      mailingAddress.addressLine1 && { addressLine1: mailingAddress.addressLine1 },
      mailingAddress.addressLine2 && { addressLine2: mailingAddress.addressLine2 },
      mailingAddress.addressLine3 && { addressLine3: mailingAddress.addressLine3 },
      mailingAddress.addressLine4 && { addressLine4: mailingAddress.addressLine4 },
      mailingAddress.postcode && { postcode: mailingAddress.postcode }
    );
  }

  if (Object.prototype.hasOwnProperty.call(payload, "secondaryAddress")) {
    result.secondaryAddress = addressAdapter(secondaryAddress, payload.secondaryAddress, false);
  } else if (secondaryAddress.contactId) {
    result.secondaryAddress = Object.assign(
      {
        country: secondaryAddress.country,
        purpose: secondaryAddress.purpose,
        isPrimary: secondaryAddress.isPrimary,
        contactId: secondaryAddress.contactId,
      },
      secondaryAddress.city && { city: secondaryAddress.city },
      secondaryAddress.province && { province: secondaryAddress.province },
      secondaryAddress.state && { state: secondaryAddress.state },
      secondaryAddress.addressee && { addressee: secondaryAddress.addressee },
      secondaryAddress.addressLine1 && { addressLine1: secondaryAddress.addressLine1 },
      secondaryAddress.addressLine2 && { addressLine2: secondaryAddress.addressLine2 },
      secondaryAddress.addressLine3 && { addressLine3: secondaryAddress.addressLine3 },
      secondaryAddress.addressLine4 && { addressLine4: secondaryAddress.addressLine4 },
      secondaryAddress.postcode && { postcode: secondaryAddress.postcode }
    );
  }

  return result;
};

export function* handleSubmitContactDetails({ type, form, promise }) {
  const { resolve, reject } = promise;
  const accaId = yield select(getAccaId);
  const initialState = yield select(getContactDetails);

  const values = contactDetailsPayloadAdapter(initialState, form);

  const { error } = yield call(
    fetchSaga(
      ApiService.callApi,
      apiConfig.endpoints.user.contactDetails(accaId),
      type,
      {
        method: "PATCH",
        requestBody: values,
        timeout: 0,
      },
      { values }
    )
  );

  if (error) {
    reject();
  }

  resolve();
}

function* handleFetchAddresses({ type, params, callback }) {
  try {
    const response = yield call(ApiService.callApi, apiConfig.endpoints.validations.addresses, {
      params,
    });
    // eslint-disable-next-line no-shadow
    const addresses = response.data.results.map(({ id, text, description, type }) => {
      const label = description ? `${text}, ${description}` : text;
      return {
        label,
        value: id,
        type,
      };
    });
    yield call(callback, addresses);
    yield put({
      type: `${type}_SUCCESS`,
    });
  } catch (error) {
    rollbar.error(`${type}_ERROR`, error);
    yield put({
      type: `${type}_ERROR`,
      error,
    });
  }
}

function* handleFetchAddressInfo({ type, id, callback }) {
  try {
    const response = yield call(ApiService.callApi, apiConfig.endpoints.validations.addressInfo, {
      params: { id },
    });
    const {
      company: addressee = "",
      line1: addressLine1 = "",
      line2: addressLine2 = "",
      line3: addressLine3 = "",
      line4: addressLine4 = "",
      postalCode: postcode = "",
      province = "",
      adminAreaName: state = "",
      city = "",
    } = response.data.address;

    yield call(callback, {
      addressee,
      addressLine1,
      addressLine2,
      addressLine3,
      addressLine4,
      postcode,
      province,
      state,
      city,
    });
    yield put({
      type: `${type}_SUCCESS`,
    });
  } catch (error) {
    rollbar.error(`${type}_ERROR`, error);
    yield put({
      type: `${type}_ERROR`,
      error,
    });
  }
}

function* handleFetchCountries({ type }) {
  yield call(fetchSaga(ApiService.callApi, apiConfig.endpoints.validations.countries, type));
}

export default function* contactDetailsSagas() {
  yield takeLeading(ACTION_TYPES.FETCH_CONTACT_DETAILS, handleFetchContactDetails);
  yield takeLatest(ACTION_TYPES.SUBMIT_CONTACT_DETAILS, handleSubmitContactDetails);
  yield throttle("1000", ACTION_TYPES.FETCH_ADDRESSES, handleFetchAddresses);
  yield takeLatest(ACTION_TYPES.FETCH_ADDRESS_INFO, handleFetchAddressInfo);
  yield takeLeading(ACTION_TYPES.FETCH_COUNTRIES, handleFetchCountries);
}
