import { takeLeading, call, select } from "redux-saga/effects";
import ApiService from "services/ApiService";
import apiConfig from "configs/apiConfig";
import fetchSaga from "utilities/fetchSaga";
import { ACTION_TYPES } from "./actions";
import { getAccaId } from "./selectors";

function* handleFetchUserProfile({ type }) {
  const accaId = yield select(getAccaId);
  yield call(fetchSaga(ApiService.callApi, apiConfig.endpoints.user.profile(accaId), type));
}

function* handleFetchAccountBalance({ type }) {
  const accaId = yield select(getAccaId);
  yield call(
    fetchSaga(ApiService.callApi, apiConfig.endpoints.user.accountBalance(accaId), type, {
      params: { report: "full" },
    })
  );
}

function* handleFetchCpdDeclarationOverview({ type }) {
  const accaId = yield select(getAccaId);
  yield call(fetchSaga(ApiService.callApi, apiConfig.endpoints.user.cpdsOverview(accaId), type));
}

function* handleFetchNotifications({ type }) {
  const accaId = yield select(getAccaId);
  yield call(fetchSaga(ApiService.callApi, apiConfig.endpoints.user.notifications(accaId), type));
}

function* handleFetchSessionId({ type }) {
  const accaId = yield select(getAccaId);
  yield call(
    fetchSaga(ApiService.callApi, apiConfig.endpoints.user.session(accaId), type, {
      method: "POST",
      requestBody: {
        customerIP: "1.1.1.1",
      },
    })
  );
}

function* handleFetchEncryptedUserId({ type }) {
  const accaId = yield select(getAccaId);
  yield call(fetchSaga(ApiService.callApi, apiConfig.endpoints.user.encryptedUserId(accaId), type));
}

export default function* profileSagas() {
  yield takeLeading(ACTION_TYPES.FETCH_USER_PROFILE, handleFetchUserProfile);
  yield takeLeading(ACTION_TYPES.FETCH_ACCOUNT_BALANCE, handleFetchAccountBalance);
  yield takeLeading(ACTION_TYPES.FETCH_CPD_DECLARATION_OVERVIEW, handleFetchCpdDeclarationOverview);
  yield takeLeading(ACTION_TYPES.FETCH_NOTIFICATIONS, handleFetchNotifications);
  yield takeLeading(ACTION_TYPES.FETCH_SESSION_ID, handleFetchSessionId);
  yield takeLeading(ACTION_TYPES.FETCH_ENCRYPTED_USERID, handleFetchEncryptedUserId);
}
