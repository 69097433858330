import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const Container = ({ children, className, innerClassName, gridClasses }) => {
  const classes = classnames("grid-container", "fluid", className);
  const gridClass = classnames(gridClasses, innerClassName);

  return (
    <div className={classes}>
      <div className={gridClass}>{children}</div>
    </div>
  );
};

Container.defaultProps = {
  gridClasses: ["grid-x", "grid-margin-y", "grid-margin-x"],
};

Container.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  gridClasses: PropTypes.arrayOf(PropTypes.string),
};

export default Container;
