import APPLY_FOR_MEMBERSHIP_STEPS from "constants/applyForMembershipSteps";
import { ACTION_TYPES } from "./actions";

export const initialState = {
  step: APPLY_FOR_MEMBERSHIP_STEPS.LANDING,
  hasConvictions: null,
  publicPractice: null,
  includeInDirectoryOfMembers: null,
  declarationAccepted: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    case ACTION_TYPES.CLOSE_FORM:
      return { ...initialState };
    case ACTION_TYPES.SET_STEP:
      return {
        ...state,
        step: action.step,
      };
    case ACTION_TYPES.SUBMIT_SUCCESS:
      return {
        ...state,
        step: APPLY_FOR_MEMBERSHIP_STEPS.COMPLETE,
      };
    case ACTION_TYPES.SUBMIT_ERROR:
      return {
        ...state,
        step: APPLY_FOR_MEMBERSHIP_STEPS.ERROR,
      };
    case ACTION_TYPES.SET_CONVICTIONS:
      return {
        ...state,
        hasConvictions: action.value,
      };
    case ACTION_TYPES.SET_PUBLIC:
      return {
        ...state,
        publicPractice: action.value,
      };
    case ACTION_TYPES.SET_PUBLIC_PRACTICE_CONFIRMED:
      return {
        ...state,
        publicPracticeConfirmed: action.value,
      };
    case ACTION_TYPES.SET_CONFIRM_PERMISSION:
      return {
        ...state,
        confirmedPermission: action.value,
      };
    case ACTION_TYPES.SET_INCLUDE_IN_DIRECTORY_OF_MEMBERS:
      return {
        ...state,
        includeInDirectoryOfMembers: action.value,
      };
    case ACTION_TYPES.SET_EMPLOYMENT_NETWORKS:
      return {
        ...state,
        employmentNetworks: action.value,
      };
    case ACTION_TYPES.SET_REGIONAL_NETWORK:
      return {
        ...state,
        regionalNetwork: action.value,
      };
    case ACTION_TYPES.SET_DECLARATION_ACCEPTED:
      return {
        ...state,
        declarationAccepted: action.value,
      };
    case ACTION_TYPES.SET_EMPLOYMENT_CATEGORY:
      return {
        ...state,
        employmentCategory: action.value,
      };
    case ACTION_TYPES.SET_BUSINESS_CATEGORY:
      return {
        ...state,
        businessCategory: action.value,
      };
    case ACTION_TYPES.SET_ORGANISATION_SIZE:
      return {
        ...state,
        organisationSize: action.value,
      };
    case ACTION_TYPES.SET_COMPANY_NAME:
      return {
        ...state,
        companyName: action.value,
      };
    case ACTION_TYPES.SET_JOB_TITLE:
      return {
        ...state,
        jobTitle: action.value,
      };
    case ACTION_TYPES.SET_PROFESSIONAL_QUALIFICATIONS:
      return {
        ...state,
        professionalQualifications: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
