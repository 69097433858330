const APPLY_FOR_MEMBERSHIP_STEPS = {
  LANDING: "LANDING",
  CONVICTIONS: "CONVICTIONS",
  EMPLOYMENT: "EMPLOYMENT",
  CONTACT: "CONTACT",
  DECLARATION: "DECLARATION",
  SUBMITTING: "SUBMITTING",
  COMPLETE: "COMPLETE",
  ERROR: "ERROR",
  ALREADY_APPLIED: "ALREADY_APPLIED",
  APPLICATION_ONHOLD: "MEMBERSHIP_APPLICATION_ONHOLD",
  APPLICATION_COMPLETE: "MEMBERSHIP_APPLICATION_COMPLETE",
};
export default APPLY_FOR_MEMBERSHIP_STEPS;
