import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const Label = ({ classNames, id, text, required }) => {
  const classes = classnames("form-label", classNames);

  return (
    <label htmlFor={id} className={classes}>
      {text} {required && <span className="u-error">*</span>}
    </label>
  );
};

Label.propTypes = {
  classNames: PropTypes.string,
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export default Label;
