import get from "lodash.get";
import CPD_DECLARATION_STEPS from "constants/cpdDeclarationSteps";
import CPD_DECLARATION_ROUTES from "constants/cpdDeclarationRoutes";

export const getShowDeclarationForm = state => state.cpdDeclaration.showForm;
export const getIsRequirementsMet = state => state.cpdDeclaration.isRequirementsMet;
export const getRoute = state => state.cpdDeclaration.route;
export const getCurrentStep = state => state.cpdDeclaration.step;
export const getIsEngagementPartner = state => state.cpdDeclaration.isEngagementPartner;
export const getIsConfirmed = state => state.cpdDeclaration.isConfirmed;
export const getYear = state => state.cpdDeclaration.year;

export const getAdjustmentsForYear = state => {
  const year = getYear(state);
  const declaration = get(state.cpd, "history.annualDeclarations", []).find(
    submission => submission.year === year
  );
  return declaration
    ? declaration.adjustments.filter(({ status }) => status.code === "AWARDED")
    : [];
};

export const getBroughtForUnitsForYear = state => {
  const year = getYear(state);
  const declaration = get(state.cpd, "history.annualDeclarations", []).find(
    submission => submission.year === year
  );
  return declaration ? declaration.broughtForwardUnits : 0;
};

export const getStepNumber = state => {
  const currentStep = getCurrentStep(state);
  switch (currentStep) {
    case CPD_DECLARATION_STEPS.REQUIREMENTS_MET:
      return 1;
    case CPD_DECLARATION_STEPS.ROUTE:
      return 2;
    case CPD_DECLARATION_STEPS.ENGAGEMENT_PARTNER:
      return 3;
    case CPD_DECLARATION_STEPS.ACCURACY_CONFIRMATION:
      return 4;
    default:
      return null;
  }
};

export const getIsStepValid = state => {
  switch (state.cpdDeclaration.step) {
    case CPD_DECLARATION_STEPS.REQUIREMENTS_MET:
      return getIsRequirementsMet(state) !== null;
    case CPD_DECLARATION_STEPS.ROUTE:
      return getRoute(state) !== null;
    case CPD_DECLARATION_STEPS.ENGAGEMENT_PARTNER:
      return getIsEngagementPartner(state) !== null;
    case CPD_DECLARATION_STEPS.ACCURACY_CONFIRMATION:
      return getIsConfirmed(state);
    default:
      return true;
  }
};

export const getNextStep = state => {
  switch (state.cpdDeclaration.step) {
    case CPD_DECLARATION_STEPS.LANDING:
      return CPD_DECLARATION_STEPS.REQUIREMENTS_MET;
    case CPD_DECLARATION_STEPS.REQUIREMENTS_MET:
      if (getIsRequirementsMet(state)) {
        return CPD_DECLARATION_STEPS.ROUTE;
      }
      return CPD_DECLARATION_STEPS.ENGAGEMENT_PARTNER;
    case CPD_DECLARATION_STEPS.ROUTE:
      return CPD_DECLARATION_STEPS.ENGAGEMENT_PARTNER;
    case CPD_DECLARATION_STEPS.ENGAGEMENT_PARTNER:
      return CPD_DECLARATION_STEPS.ACCURACY_CONFIRMATION;
    case CPD_DECLARATION_STEPS.ACCURACY_CONFIRMATION:
      return CPD_DECLARATION_STEPS.SUBMITTING;
    default:
      return null;
  }
};

export const getPrevStep = state => {
  switch (state.cpdDeclaration.step) {
    case CPD_DECLARATION_STEPS.REQUIREMENTS_MET:
      return CPD_DECLARATION_STEPS.LANDING;
    case CPD_DECLARATION_STEPS.ROUTE:
      return CPD_DECLARATION_STEPS.REQUIREMENTS_MET;
    case CPD_DECLARATION_STEPS.ENGAGEMENT_PARTNER:
      if (getIsRequirementsMet(state)) {
        return CPD_DECLARATION_STEPS.ROUTE;
      }
      return CPD_DECLARATION_STEPS.REQUIREMENTS_MET;
    case CPD_DECLARATION_STEPS.ACCURACY_CONFIRMATION:
      return CPD_DECLARATION_STEPS.ENGAGEMENT_PARTNER;
    default:
      return null;
  }
};

export const getSubmitRequestBody = state => {
  const { route, year, isEngagementPartner, isRequirementsMet } = state.cpdDeclaration;
  return {
    route: isRequirementsMet && route ? route : CPD_DECLARATION_ROUTES.NOT_MET_REQUIREMENTS,
    year,
    engagementPartner: isEngagementPartner,
    requirementsMet: isRequirementsMet,
  };
};

export const getRequiredUnits = state => {
  return {
    verifiable: get(state.cpd, "history.verifiableUnits", null),
    required: get(state.cpd, "history.totalUnits", null),
  };
};
