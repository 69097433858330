import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Parser from "html-react-parser";
import { BodyCopy } from "acca-design-system";
import { showQuestion } from "state/communicationPreferences/selectors";
import Question from "../Question/Question";

const mapStateToProps = state => ({
  shouldShowQuestion: questionId => showQuestion(state, questionId),
});

const CommunicationPreferencesSection = ({ heading, questions, footnote, shouldShowQuestion }) => (
  <section className="section">
    <h3 className="section-title">{heading}</h3>
    {questions
      .filter(question => shouldShowQuestion(question.id))
      .map((question, idx) => (
        <Question key={question.id} {...question} className={idx > 0 ? "u-margin-top-2" : ""} />
      ))}
    {footnote && (
      <BodyCopy className="u-content-container-medium u-margin-top-2">{Parser(footnote)}</BodyCopy>
    )}
  </section>
);

CommunicationPreferencesSection.propTypes = {
  heading: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  footnote: PropTypes.string,
  shouldShowQuestion: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(CommunicationPreferencesSection);
