import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Card, Button, BodyCopy, IconClose16, IconEdit16 } from "acca-design-system";

import IconMap from "components/IconMap/IconMap";

import "./form-section.scss";
import Parser from "html-react-parser";

const FormSection = ({
  readOnly,
  formFields,
  content,
  className,
  handleOnClick,
  isReadOnly,
  isDisplay,
  isGrey,
  children,
}) => {
  const classes = classnames(
    "form-section",
    { "form-section--is-edit-mode": !isReadOnly },
    className
  );

  const renderSectionContent = () => {
    if (isReadOnly) {
      return <div>{readOnly}</div>;
    }
    return <div>{formFields}</div>;
  };

  return (
    <Card
      icon={IconMap({ name: content.icon })}
      iconSize="large"
      className={classes}
      backgroundColor={isGrey ? "grey" : null}
    >
      {!isDisplay ? (
        <Button
          text={isReadOnly ? "Edit" : "Cancel"}
          onClick={() => {
            handleOnClick(!isReadOnly);
          }}
          variant="link"
          icon={isReadOnly ? IconEdit16 : IconClose16}
          className="form-section__edit-button"
        />
      ) : null}
      <div className="u-content-container-medium">
        <h3 className="section-title">{content.heading}</h3>
        {content.message && (
          <BodyCopy className="u-margin-bottom-2">{Parser(content.message)}</BodyCopy>
        )}
        {children}
        {renderSectionContent()}
      </div>
    </Card>
  );
};

FormSection.propTypes = {
  content: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    message: PropTypes.string,
    icon: PropTypes.string,
  }),
  className: PropTypes.string,
  isReadOnly: PropTypes.bool,
  isDisplay: PropTypes.bool,
  isGrey: PropTypes.bool,
  handleOnClick: PropTypes.func.isRequired,
  readOnly: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  formFields: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default FormSection;
