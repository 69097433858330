import React from "react";
import PropTypes from "prop-types";
import { Card, Button } from "acca-design-system";
import illustration from "assets/images/reload-on-error.svg";
import "./async-error-panel.scss";

const AsyncErrorPanel = ({ heading }) => (
  <Card center>
    <img src={illustration} alt="" className="async-error-panel__graphic u-margin-bottom-1" />
    <h4 className="h600 u-font-heading">{heading}</h4>
    <Button
      onClick={() => window.location.reload()}
      text="Reload"
      variant="link"
      className="u-margin-top-1"
    />
  </Card>
);

AsyncErrorPanel.propTypes = {
  heading: PropTypes.string.isRequired,
};

export default AsyncErrorPanel;
