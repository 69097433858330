import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Parser from "html-react-parser";
import { BodyCopy, FormField } from "acca-design-system";
import RadioButtonGroup from "components/Form/RadioButtonGroup/RadioButtonGroup";

const TransferACCAInput = ({ content, data, available, onRadioButtonChange }) => {
  const { quote, warning, options } = content;

  const matched = [];

  options.forEach(option => {
    const match = available.find(c => c.contentKey === option.value);
    if (match) {
      const select = {
        value: match.value,
        label: option.label,
        text: option.text,
        key: option.value,
      };

      if (select.value === "disable") {
        matched.unshift({ ...select, warn: true });
      } else {
        matched.push(select);
      }
    }
  });

  return (
    <Fragment>
      <BodyCopy className="u-margin-bottom-2">{Parser(quote)}</BodyCopy>
      <FormField>
        <RadioButtonGroup
          id="transfer"
          name="transfer"
          options={matched.reverse()}
          value={data}
          boxed
          warning={warning}
          handleChange={onRadioButtonChange}
        />
      </FormField>
    </Fragment>
  );
};

TransferACCAInput.propTypes = {
  content: PropTypes.shape({
    heading: PropTypes.string,
    quote: PropTypes.string,
    warning: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string })),
  }).isRequired,
  data: PropTypes.bool,
  available: PropTypes.arrayOf(PropTypes.shape()),
  onRadioButtonChange: PropTypes.func,
};

export default TransferACCAInput;
